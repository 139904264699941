import {baseAxios} from '../helper/axiosHelper';

export const dashboarddataService = {
    getLastChangedOffers,
    getLastChangedOrders,
    getLastChangedContacts,
    getChartData2YearIncome,
    getLastChangedPartnerOrder
};

function getLastChangedOffers() {

    return baseAxios.get('dashboarddata/LastChangedOffer')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getLastChangedPartnerOrder() {

    return baseAxios.get('dashboarddata/LastChangedPartnerOrder')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getLastChangedContacts() {

    return baseAxios.get('dashboarddata/LastChangedContact')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getLastChangedOrders() {

    return baseAxios.get('dashboarddata/LastChangedOrder')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getChartData2YearIncome() {

    return baseAxios.get('dashboarddata/chartdata/twoyearincome')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}