
import { shipmentService } from '../services';
import baseMethods from './base';

const initialState = {  shipments: {data: null,status:{ loading: false,ready: false }}};

export const shipment = {
    namespaced: true,
    state: initialState,
    actions: {
        getShipments({ commit },data )
        {
            commit('getAllMyShipmentsRequest' );
            return new Promise((resolve, reject) => {

                shipmentService.getShipments(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getAllMyShipmentsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getAllMyShipmentsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteShipment({ commit }, id)
        {
            commit('deleteShipmentRequest' );
            return new Promise((resolve, reject) => {
                
                shipmentService.deleteShipment(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteShipmentSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteShipmentFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },  
        deleteShipmentMember({ commit }, id)
        {
            return new Promise((resolve, reject) => {
                
                shipmentService.deleteShipmentMember(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteShipmentMemberSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },  
        // eslint-disable-next-line no-unused-vars
        updateShipmentState({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                shipmentService.updateShipmentState(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },  
        // eslint-disable-next-line no-unused-vars
        mergeShipmentMember({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                shipmentService.mergeShipmentMember(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        saveShipment({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertShipmentRequest' );
                    shipmentService.insertShipment(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertShipmentSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertShipmentFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateShipmentRequest' );
                    shipmentService.updateShipment(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateShipmentSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateShipmentFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
    },
    mutations: {
        getAllMyShipmentsRequest(state)
        {
            state.shipments = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getAllMyShipmentsSuccess(state, data)
        {
            state.shipments = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getAllMyShipmentsFailure(state)
        {
            state.shipments = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteShipmentRequest(state)
        {
            state.shipments.status = { loading: true,ready: false };
        },
        deleteShipmentSuccess(state,id)
        {
            state.shipments.status = { loading: false,ready: true };
            for(var i =0; i < state.shipments.data.length;i++)
            {
                if(state.shipments.data[i].id == id)
                {
                    state.shipments.data.splice(i,1);
                }
            }

        },
        deleteShipmentFailure(state)
        {
            state.shipments.status = { loading: false,ready: false };
        },
        insertShipmentRequest(state)
        {
            state.shipments.status = { loading: true,ready: false };
        },
        insertShipmentSuccess(state)
        {
            state.shipments.status = { loading: false,ready: true };
        },
        insertShipmentFailure(state)
        {
            state.shipments.status = { loading: false,ready: false };
        },
        updateShipmentRequest(state)
        {
            state.shipments.status = { loading: true,ready: false };
        },
        updateShipmentSuccess(state,data)
        {
            state.shipments.status = { loading: false,ready: true };
            for(var i =0; i < state.shipments.data.length;i++)
            {
                if(state.shipments.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.shipments.data[i] = JSON.parse(tmp);
                }
            }
        },
        updateShipmentFailure(state)
        {
            state.shipments.status = { loading: false,ready: false };
        },        
        deleteShipmentMemberSuccess(state,id)
        {
            for(var i =0; i < state.shipments.data.length;i++)
            {
                for(var j =0; j < state.shipments.data[i].member.length;j++)
                {
                    if(state.shipments.data[i].member[j].id == id)
                    {
                        state.shipments.data[i].member.splice(j,1);
                    }
                }
            }
        }
    }
}
