import {baseAxios} from '../helper/axiosHelper';

export const downloadService = {
    insertDownload,
    getDownloads,
    updateDownload,
    deleteDownload,
    downloadFile
};

function insertDownload(data) {

    return baseAxios.post('downloads/download',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateDownload(data) {

    return baseAxios.put('downloads/download',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteDownload(id) {

    return baseAxios.delete('downloads/download',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getDownloads() {

    return baseAxios.get('downloads/download/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function downloadFile(id) {

    // `responseType` indicates the type of data that the server will respond with
    // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
    //   browser only: 'blob'
    //responseType: 'json', // default
    
        return baseAxios.get('downloads/download/file/',{params:{id:id},responseType: 'text'})
        .then((response) =>
        {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    }
