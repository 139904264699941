
import { fixtextService } from '../services';
import baseMethods from './base';

const initialState = {  fixtexts: {data: null,status:{ loading: false,ready: false }}};

export const fixtext = {
    namespaced: true,
    state: initialState,
    actions: {
        getFixtexts({ commit } )
        {
            commit('getFixtextsRequest' );
            return new Promise((resolve, reject) => {

                fixtextService.getFixtexts().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getFixtextsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getFixtextsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteFixtext({ commit }, id)
        {
            commit('deleteFixtextRequest' );
            return new Promise((resolve, reject) => {
                
                fixtextService.deleteFixtext(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteFixtextSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteFixtextFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveFixtext({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertFixtextRequest' );
                    fixtextService.insertFixtext(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertFixtextSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertFixtextFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateFixtextRequest' );
                    fixtextService.updateFixtext(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateFixtextSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateFixtextFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        }
    },
    mutations: {
        getFixtextsRequest(state)
        {
            state.fixtexts = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getFixtextsSuccess(state, data)
        {
            state.fixtexts = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getFixtextsFailure(state)
        {
            state.fixtexts = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteFixtextRequest(state)
        {
            state.fixtexts.status = { loading: true,ready: false };
        },
        deleteFixtextSuccess(state)
        {
            state.fixtexts.status = { loading: false,ready: true };
        },
        deleteFixtextFailure(state)
        {
            state.fixtexts.status = { loading: false,ready: false };
        },
        updateFixtextRequest(state)
        {
            state.fixtexts.status ={ loading: true,ready: false };
        },
        updateFixtextSuccess(state)
        {
            state.fixtexts.status ={ loading: false,ready: true };
        },
        updateFixtextFailure(state)
        {
            state.fixtexts.status ={ loading: false,ready: false };
        },
        insertFixtextRequest(state)
        {
            state.fixtexts.status ={ loading: true,ready: false };
        },
        insertFixtextSuccess(state)
        {
            state.fixtexts.status ={ loading: false,ready: true };
        },
        insertFixtextFailure(state)
        {
            state.fixtexts.status ={ loading: false,ready: false };
        }
    }
}
