import {baseAxios} from '../helper/axiosHelper';

export const messagesService = {
    getMessages,
    getUnreadMessages,
    markAsRead,
    markAsDeleted,
    getNrUnreadMessages
};

function getMessages() {

    return baseAxios.get('messages/all')
    .then((response) =>
    {
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
}
function getUnreadMessages(data) {

    return baseAxios.get('messages/unread',{params:{date:data}})
    .then((response) =>
    {
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
}

function getNrUnreadMessages() {

    return baseAxios.get('messages/unread/nr')
    .then((response) =>
    {
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
}

function markAsRead(data) {

    return baseAxios.put('messages/message/read',data)
    .then((response) =>
    {
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
}
function markAsDeleted(data) {

    return baseAxios.put('messages/message/deleted',data)
    .then((response) =>
    {
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
}