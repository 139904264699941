
import { totpService } from '../services';
import baseMethods from './base';

const initialState = {  twofactor: {enabled: false, active:false, status:{ loading: false,ready: false }}
};

export const totp = {
    namespaced: true,
    state: initialState,
    actions: {
        enableTotp({ commit } )
        {
            commit('enableTotpRequest' );
            return new Promise((resolve, reject) => {

                totpService.insertTotp().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('enableTotpSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('enableTotpFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        disableTotp({ commit } )
        {
            commit('enableTotpRequest' );
            return new Promise((resolve, reject) => {

                totpService.deleteTotp().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('enableTotpSuccess',!response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('enableTotpFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getTotpQrCode( )
        {
            return new Promise((resolve, reject) => {

                totpService.getQr().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        validateCode({ commit }, data)
        {
            return new Promise((resolve, reject) => {

                totpService.validate(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getTotp({ commit } )
        {
            commit('getTotpRequest' );
            return new Promise((resolve, reject) => {

                totpService.getTotp().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getTotpSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getTotpFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
    },
    mutations: {
        enableTotpRequest(state)
        {
            state.twofactor = {
                enabled: false,
                active: false,
                status:{ loading: true,ready: false }
            };
        },
        enableTotpSuccess(state,enable)
        {
            state.twofactor = {
                enabled: enable,
                active: false,
                status:{ loading: false,ready: true }
            };
        },
        enableTotpFailure(state)
        {
            state.twofactor = {
                enabled: false,
                active: false,
                status:{ loading: false,ready: false }
            };
        },
        getTotpRequest(state)
        {
            state.twofactor = {
                enabled: false,
                active: false,
                status:{ loading: true,ready: false }
            };
        },
        getTotpSuccess(state,data)
        {
            var enabled = data != null;
            var active = false;
            if(enabled)
            {
                active = data.active;
            }
            state.twofactor = {
                enabled: enabled,
                active: active,
                status:{ loading: false,ready: true }
            };
        },
        getTotpFailure(state)
        {
            state.twofactor = {
                enabled: false,
                active: false,
                status:{ loading: false,ready: false }
            };
        },
    }
}
