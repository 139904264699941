
import { productsService } from '../services';
import baseMethods from './base';

const initialState = { productAdminList: {data: null,status:{ loading: false,ready: false }},
                       productsByCategory: {data: null,status:{ loading: false,ready: false }}};

function SetProductVal(state,id,prop,val)
{
    if(state.productAdminList.data != null)
    {
        for (let i = 0; i < state.productAdminList.data.length; i++)
        {
            if(state.productAdminList.data[i].id == id)
            {
                state.productAdminList.data[i][prop] = val;
                break;
            }
        }
    }
}

export const products = {
    namespaced: true,
    state: initialState,
    actions: {
        getProductAdminList({ commit,dispatch } )
        {
            commit('getProductAdminListRequest' );
            return new Promise((resolve, reject) => {
                
                productsService.getProductAdminList().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getProductAdminListSucces',response.data);
                            dispatch('maincontainer/setLoadingStatus', {type:"product",loaded:true},{root: true},{root: true}); 
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getProductAdminListFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getProductsByCategory({ commit },data )
        {
            commit('getProductsByCategoryRequest' );
            return new Promise((resolve, reject) => {
                
                productsService.getProductsByCategory(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getProductsByCategorySuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getProductsByCategoryFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        
        deleteProduct({ commit }, id)
        {
            commit('deleteProductRequest' );
            return new Promise((resolve, reject) => {
                
                productsService.deleteProduct(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteProductSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteProductFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveProduct({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertProductRequest' );
                    productsService.insertProduct(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertProductSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertProductFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateProductRequest' );
                    productsService.updateProduct(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateProductSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateProductFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        updateProductListBez({ commit }, data)
        {
            commit('updateProductListBez',data);
        },
        updateProductListKbez({ commit }, data)
        {
            commit('updateProductListKbez',data);
        },
        updateProductListSurfaceFk({ commit }, data)
        {
            commit('updateProductListSurfaceFk',data);
        },
        updateProductListBestellbar({ commit }, data)
        {
            commit('updateProductListBestellbar',data);
        },
        updateProductListStuecklisteFk({ commit }, data)
        {
            commit('updateProductListStuecklisteFk',data);
        },
        updateProductListHeight({ commit }, data)
        {
            commit('updateProductListHeight',data);
        }, 
        updateProductListWidth({ commit }, data)
        {
            commit('updateProductListWidth',data);
        },
        updateProductListLength({ commit }, data)
        {
            commit('updateProductListLength',data);
        },
        updateProductListWeight({ commit }, data)
        {
            commit('updateProductListWeight',data);
        }, 
        updateProductListPower({ commit }, data)
        {
            commit('updateProductListPower',data);
        },        
        clearProductList({ commit })
        {
          commit('clearProductList');
        },               
    },
    mutations: {
        deleteProductRequest(state)
        {
            state.productAdminList.status = { loading: true,ready: false };
        },
        deleteProductSuccess(state)
        {
            state.productAdminList.status = { loading: false,ready: true };
        },
        deleteProductFailure(state)
        {
            state.productAdminList.status = { loading: false,ready: false };
        },                
        getProductAdminListRequest(state)
        {
            state.productAdminList = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getProductAdminListSucces(state, data)
        {
            state.productAdminList = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getProductAdminListFailure(state)
        {
            state.productAdminList = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getProductsByCategoryRequest(state)
        {
            state.productsByCategory = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getProductsByCategorySuccess(state, data)
        {
            state.productsByCategory = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getProductsByCategoryFailure(state)
        {
            state.productsByCategory = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        updateProductRequest(state)
        {
            state.productAdminList = {
                status:{ loading: true,ready: false }
            };
        },
        updateProductSuccess(state)
        {
            state.productAdminList = {
                status:{ loading: false,ready: true }
            };
        },
        updateProductFailure(state)
        {
            state.productAdminList = {
                status:{ loading: false,ready: false }
            };
        },
        insertProductRequest(state)
        {
            state.ProductListUser = {
                status:{ loading: true,ready: false }
            };
        },
        insertProductSuccess(state)
        {
            state.productAdminList = {
                status:{ loading: false,ready: true }
            };
        },
        insertProductFailure(state)
        {
            state.productAdminList = {
                status:{ loading: false,ready: false }
            };
        },
        updateProductListBez(state,data)
        {
            SetProductVal(state,data.id,'bez',data.value);
        },
        updateProductListKbez(state,data)
        {
            SetProductVal(state,data.id,'kbez',data.value);
        },
        updateProductListSurfaceFk(state,data)
        {
            SetProductVal(state,data.id,'surface_fk',data.value);
        },
        updateProductListBestellbar(state,data)
        {
            SetProductVal(state,data.id,'bestellbar',data.value);
        },
        updateProductListStuecklisteFk(state,data)
        {
            SetProductVal(state,data.id,'stueckliste_fk',data.value);
        },
        updateProductListHeight(state,data)
        {
            SetProductVal(state,data.id,'height',data.value);
        },   
        updateProductListWidth(state,data)
        {
            SetProductVal(state,data.id,'width',data.value);
        },   
        updateProductListLength(state,data)
        {
            SetProductVal(state,data.id,'length',data.value);
        },   
        updateProductListWeight(state,data)
        {
            SetProductVal(state,data.id,'weight',data.value);
        },
        updateProductListPower(state,data)
        {
            SetProductVal(state,data.id,'power',data.value);
        },        
        clearProductList(state)
        {
            state.productAdminList.data = null;
        }
    }
}
