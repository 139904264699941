
import { messagesService } from '../services';
import {i18n} from '../helper/i18nHelper';

const initialState = { messages: {data: [],status:{ loading: false,ready: false }},lastUpdate:null,oldUnreadMessages:0 };

export const messages = {
    namespaced: true,
    state: initialState,
    actions: {
        getMessages({ commit } )
        {
            commit('getMessagesRequest' );
            return new Promise((resolve, reject) => {

                messagesService.getMessages().then(
                    data => {
                        commit('getMessagesSucces',data);
                        resolve(data);
                    },
                    error => {
                        commit('getMessagesFailure');
                        reject(error);
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        getNrUnreadMessages({ commit } )
        {
            return new Promise((resolve, reject) => {

                messagesService.getNrUnreadMessages().then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );

            })
        },
        
        getUnreadMessages({ commit,dispatch,getters },data )
        {
            commit('getUnreadMessagesRequest' );
            return new Promise((resolve, reject) => {

                messagesService.getUnreadMessages(data).then(
                    data => {
                        commit('getUnreadMessagesSucces',data);
                        if(typeof(data.messages) != 'undefined')
                        {
                            if(data.messages.length > 0)
                            {
                                var unread = getters.getNrUnreadMessages;
                                if(unread != getters.getOldNrUnreadMessages)
                                {
                                    // Nachricht nur anzeigen wenn sich die Zahl der ungelesenen Nachrichten geändert hat
                                    commit('updateUnreadMessages',unread);
                                    dispatch('alertqueue/addInfo', {'titel':i18n.t('MESSAGES.TITLE.NEWMESSAGE'),'text':i18n.t('MESSAGES.TEXT.NEWMESSAGES').replace("*1*",data.messages.length),'icon':'mail'},{ root: true });
                                }
                            }
                        }
                        resolve(data);
                    },
                    error => {
                        commit('getUnreadMessagesFailure');
                        reject(error);
                    }
                );

            })
        },
        markAsRead({commit},guid){
            commit('markAsReadRequest' );
            return new Promise((resolve, reject) => {

                messagesService.markAsRead({guid:guid,read:true}).then(
                    data => {
                        commit('markAsReadSucces',guid);
                        resolve(data);
                    },
                    error => {
                        commit('markAsReadFailure');
                        reject(error);
                    }
                );

            })
        },
        markAsDeleted({commit},guid){
            commit('markAsDeletedRequest' );
            return new Promise((resolve, reject) => {

                messagesService.markAsDeleted({guid:guid,deleted:true}).then(
                    data => {
                        commit('markAsDeletedSucces',guid);
                        resolve(data);
                    },
                    error => {
                        commit('markAsDeletedFailure');
                        reject(error);
                    }
                );

            })
        }
    },
    getters: {
        getNrUnreadMessages: state => {
          return state.messages.data.filter(mes => !mes.read).length;
        },
        getOldNrUnreadMessages: state => {
            return state.oldUnreadMessages;
          }
      },
    mutations: {
        updateUnreadMessages(state,count)
        {
            state.oldUnreadMessages = count;
        },
        getMessagesRequest(state)
        {
            state.messages = {
                data: [],
                status:{ loading: true,ready: false }
            };
        },
        getMessagesSucces(state, data)
        {
            state.messages = {
                data: data.messages,
                status:{ loading: false,ready: true }
            };
            state.lastUpdate = data.lastUpdate;
        },
        getMessagesFailure(state)
        {
            state.messages = {
                data: [],
                status:{ loading: false,ready: false }
            };
        },
        getUnreadMessagesRequest(state)
        {
            state.messages.status = { loading: true,ready: false };
        },
        getUnreadMessagesSucces(state, data)
        {
            state.messages.status = { loading: false,ready: true };
            state.lastUpdate = data.lastUpdate;
            if(data.messages.length > 0)
            {
                for(var j=0;j<data.messages.length;j++)
                {
                    var found = false;
                    for(var i = 0; i < state.messages.data.length; i++)
                    {
                        if(state.messages.data[i].guid == data.messages[j].guid)
                        {
                            found = true;
                            break;
                        }
                    }
                    if(found == false)
                    {
                        // ganz vorn einfügen
                        state.messages.data.unshift(data.messages[j]);
                    }
                }
                
            }
        },
        getUnreadMessagesFailure(state)
        {
            state.messages.status = { loading: false,ready: false };
        },
        markAsReadRequest(state)
        {
            state.messages.status ={ loading: true,ready: false };
        },
        markAsReadSucces(state, guid)
        {
            state.messages.status ={ loading: false,ready: true };

            state.messages.data.forEach(message =>{
                if(message.guid == guid)
                {
                    message.read = 1;
                }
            });
        },
        markAsReadFailure(state)
        {
            state.messages.status ={ loading: false,ready: false };
        } ,
        markAsDeletedRequest(state)
        {
            state.messages.status ={ loading: true,ready: false };
        },
        markAsDeletedSucces(state, guid)
        {
            state.messages.status ={ loading: false,ready: true };

            for(var i = 0; i < state.messages.data.length; i++)
            {
                if(state.messages.data[i].guid == guid)
                {
                    state.messages.data.splice(i,1);
                    break;
                } 
            }
        },
        markAsDeletedFailure(state)
        {
            state.messages.status ={ loading: false,ready: false };
        }      
    }
}
