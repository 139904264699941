import {baseAxios} from '../helper/axiosHelper';

export const paymentconditionService = {
    insertPaymentCondition,
    getPaymentConditions,
    updatePaymentCondition,
    deletePaymentCondition
};

function insertPaymentCondition(d) {

    return baseAxios.post('paymentconditions/paymentcondition',d)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updatePaymentCondition(d) {

    return baseAxios.put('paymentconditions/paymentcondition',d)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deletePaymentCondition(id) {

    return baseAxios.delete('paymentconditions/paymentcondition',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getPaymentConditions() {

    return baseAxios.get('paymentconditions/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
