
import { pollingService } from '../services';

const initialState = { publicbanners: {data: [],status:{ loading: false,ready: false }},
                       privatebanners: {data: [],status:{ loading: false,ready: false }} };

export const polling = {
    namespaced: true,
    state: initialState,
    actions: {
        getPublicBanners({ commit } )
        {
            commit('getPublicBannersRequest' );
            return new Promise((resolve, reject) => {

                pollingService.getPublicBanners().then(
                    data => {
                        commit('getPublicBannersSucces',data);
                        resolve(data);
                    },
                    error => {
                        commit('getPublicBannersFailure');
                        reject(error);
                    }
                );

            })
        },
        getPrivateBanners({ commit } )
        {
            commit('getPrivateBannersRequest' );
            return new Promise((resolve, reject) => {

                pollingService.getPrivateBanners().then(
                    data => {
                        commit('getPrivateBannersSucces',data);
                        resolve(data);
                    },
                    error => {
                        commit('getPrivateBannersFailure');
                        reject(error);
                    }
                );

            })
        }
    },
    mutations: {
        getPublicBannersRequest(state)
        {
            state.publicbanners = {
                data: state.publicbanners.data,
                status:{ loading: true,ready: false }
            };
        },
        getPublicBannersSucces(state, data)
        {
            state.publicbanners = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getPublicBannersFailure(state)
        {
            state.publicbanners = {
                data: [],
                status:{ loading: false,ready: false }
            };
        },
        getPrivateBannersRequest(state)
        {
            state.privatebanners = {
                data: state.privatebanners.data,
                status:{ loading: true,ready: false }
            };
        },
        getPrivateBannersSucces(state, data)
        {
            state.privatebanners = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getPrivateBannersFailure(state)
        {
            state.privatebanners = {
                data: [],
                status:{ loading: false,ready: false }
            };
        }
    }
}
