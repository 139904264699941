import {baseAxios} from '../helper/axiosHelper';

export const userdefinedOptionsService = {
    getUserdefinedOptionsAll,
    getUserdefinedOptions,
    deleteUserdefinedOption,
    insertUserdefinedOption
};

function getUserdefinedOptionsAll() {

    return baseAxios.get('company/UserdefinedOptions/all/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getUserdefinedOptions(type) {

    return baseAxios.get('company/UserdefinedOptions/',{params: {type: type}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteUserdefinedOption(id) {

    return baseAxios.delete('company/UserdefinedOption/',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertUserdefinedOption(data) {

    return baseAxios.post('company/UserdefinedOption/',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

