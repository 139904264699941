import {baseAxios} from '../helper/axiosHelper';

export const productsService = {
    getProductAdminList,
    updateProduct,
    insertProduct,
    deleteProduct,
    getProductsByCategory
};

function getProductAdminList() {

    return baseAxios.get('products/all' )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getProductsByCategory(data) {

    return baseAxios.get('products/allbycat',{params: {
        lastYear: data.lastYear, 
        country_fk: data.country_fk, 
        currency_fk:data.currency_fk,
        withMotifpickerOnly:data.withMotifpickerOnly,
        headquateronly:data.headquateronly}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateProduct(data) {

    return baseAxios.put('products/product',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertProduct(data) {

    return baseAxios.post('products/product',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteProduct(id) {

    return baseAxios.delete('products/product',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}