
import { confirmationService } from '../services';
import baseMethods from './base';

const initialState = {  confirmations: {data: [], status:{ loading: false,ready: false }}
};

export const confirmation = {
    namespaced: true,
    state: initialState,
    actions: {
        getConfirmations({ commit } )
        {
            commit('getConfirmationsRequest' );
            return new Promise((resolve, reject) => {

                confirmationService.getConfirmations().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getConfirmationsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getConfirmationsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        updateConfirmation({ commit },data )
        {
            return new Promise((resolve, reject) => {

                confirmationService.updateConfirmation(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        
    },
    mutations: {
        getConfirmationsRequest(state)
        {
            state.confirmations = {
                data: [],
                status:{ loading: true,ready: false }
            };
        },
        getConfirmationsSuccess(state,data)
        {
            state.confirmations = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getConfirmationsFailure(state)
        {
            state.confirmations = {
                data: [],
                status:{ loading: false,ready: false }
            };
        }
    }
}
