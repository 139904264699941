
import { numbergroupService } from '../services';
import baseMethods from './base';

const initialState = {  numbergroups: {data: null,status:{ loading: false,ready: false }}};

export const numbergroup = {
    namespaced: true,
    state: initialState,
    actions: {
        getNumbergroupsByCompany({ commit }, company_fk )
        {
            commit('getNumbergroupsByCompanyRequest' );
            return new Promise((resolve, reject) => {

                numbergroupService.getNumbergroupsByCompany(company_fk).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getNumbergroupsByCompanySuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getNumbergroupsByCompanyFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveNumbergroup({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertNumbergroupRequest' );
                    numbergroupService.insertNumbergroup(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertNumbergroupSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertNumbergroupFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateNumbergroupRequest' );
                    numbergroupService.updateNumbergroup(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateNumbergroupSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateNumbergroupFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        deleteNumbergroup({ commit }, id)
        {
            commit('deleteNumbergroupRequest' );
            return new Promise((resolve, reject) => {
                
                numbergroupService.deleteNumbergroup(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteNumbergroupSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteNumbergroupFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
    },
    mutations: {
        getNumbergroupsByCompanyRequest(state)
        {
            state.numbergroups = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getNumbergroupsByCompanySuccess(state, data)
        {
            state.numbergroups = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getNumbergroupsByCompanyFailure(state)
        {
            state.numbergroups = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteNumbergroupRequest(state)
        {
            state.numbergroups = {
                status:{ loading: true,ready: false }
            };
        },
        deleteNumbergroupSuccess(state)
        {
            state.numbergroups = {
                status:{ loading: false,ready: true }
            };
        },
        deleteNumbergroupFailure(state)
        {
            state.numbergroups = {
                status:{ loading: false,ready: false }
            };
        },
        updateNumbergroupRequest(state)
        {
            state.numbergroups = {
                status:{ loading: true,ready: false }
            };
        },
        updateNumbergroupSuccess(state)
        {
            state.numbergroups = {
                status:{ loading: false,ready: true }
            };
        },
        updateNumbergroupFailure(state)
        {
            state.numbergroups = {
                status:{ loading: false,ready: false }
            };
        },
        insertNumbergroupRequest(state)
        {
            state.numbergroups = {
                status:{ loading: true,ready: false }
            };
        },
        insertNumbergroupSuccess(state)
        {
            state.numbergroups = {
                status:{ loading: false,ready: true }
            };
        },
        insertNumbergroupFailure(state)
        {
            state.numbergroups = {
                status:{ loading: false,ready: false }
            };
        },
    }
}
