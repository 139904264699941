import {baseAxios} from '../helper/axiosHelper';

export const currenciesService = {
    getCurrencies,
    updateCurrency,
    insertCurrency,
    deleteCurrency
};

function getCurrencies() {

    return baseAxios.get('currencies/currencies')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateCurrency(data) {

    return baseAxios.put('currencies/Currency',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertCurrency(data) {

    return baseAxios.post('currencies/Currency',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteCurrency(id) {

    return baseAxios.delete('currencies/Currency',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}