
import { timerecService } from '../services';
import baseMethods from './base';

const initialState = {  timerecs: {data: null,status:{ loading: false,ready: false }},
                        timerecsStd: {data: null,status:{ loading: false,ready: false }},
                        timemodels: {data: null,status:{ loading: false,ready: false }},
                        recsByYear: {data: null,status:{ loading: false,ready: false }}
};

export const timerec = {
    namespaced: true,
    state: initialState,
    actions: {
        getTimeRecs({ commit },data )
        {
            commit('getAllMyTimeRecsRequest' );
            return new Promise((resolve, reject) => {

                timerecService.getTimeRecs(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getAllMyTimeRecsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getAllMyTimeRecsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getAllMyHoursByYear({ commit } )
        {
            commit('getAllMyHoursByYearRequest' );
            return new Promise((resolve, reject) => {

                timerecService.getAllMyHoursByYear().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getAllMyHoursByYearSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getAllMyHoursByYearFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getTimeRecsStd({ commit },data )
        {
            commit('getTimeRecsStdRequest' );
            return new Promise((resolve, reject) => {

                timerecService.getTimeRecsStd(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getTimeRecsStdSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getTimeRecsStdFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getTimeModels({ commit },data )
        {
            commit('getTimeModelsRequest' );
            return new Promise((resolve, reject) => {

                timerecService.getTimeModels(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getTimeModelsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getTimeModelsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteTimeRec({ commit }, id)
        {
            commit('deleteTimeRecRequest' );
            return new Promise((resolve, reject) => {
                
                timerecService.deleteTimeRec(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteTimeRecSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteTimeRecFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },   
        deleteTimeRecStd({ commit }, id)
        {
            commit('deleteTimeRecStdRequest' );
            return new Promise((resolve, reject) => {
                
                timerecService.deleteTimeRecStd(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteTimeRecStdSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteTimeRecStdFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        deleteTimeModel({ commit }, id)
        {
            commit('deleteTimeModelRequest' );
            return new Promise((resolve, reject) => {
                
                timerecService.deleteTimeModel(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteTimeModelSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteTimeModelFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },  
        
        // eslint-disable-next-line no-unused-vars
        insertTimeRecsFromStd({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                timerecService.insertTimeRecsFromStd(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 

        // eslint-disable-next-line no-unused-vars
        addPause({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                timerecService.addPause(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        

        saveTimeRec({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertTimeRecRequest' );
                    timerecService.insertTimeRec(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertTimeRecSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertTimeRecFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateTimeRecRequest' );
                    timerecService.updateTimeRec(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateTimeRecSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateTimeRecFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        saveTimeRecStd({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertTimeRecStdRequest' );
                    timerecService.insertTimeRecStd(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertTimeRecStdSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertTimeRecStdFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateTimeRecStdRequest' );
                    timerecService.updateTimeRecStd(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateTimeRecStdSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateTimeRecStdFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        saveTimeModel({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertTimeModelRequest' );
                    timerecService.insertTimeModel(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertTimeModelSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertTimeModelFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateTimeModelRequest' );
                    timerecService.updateTimeModel(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateTimeModelSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateTimeModelFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
    },
    mutations: {
        getAllMyTimeRecsRequest(state)
        {
            state.timerecs = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getAllMyTimeRecsSuccess(state, data)
        {
            state.timerecs = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getAllMyTimeRecsFailure(state)
        {
            state.timerecs = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getAllMyHoursByYearRequest(state)
        {
            state.recsByYear = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getAllMyHoursByYearSuccess(state, data)
        {
            state.recsByYear = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getAllMyHoursByYearFailure(state)
        {
            state.recsByYear = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },        
        getTimeRecsStdRequest(state)
        {
            state.timerecsStd = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getTimeRecsStdSuccess(state, data)
        {
            state.timerecsStd = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getTimeRecsStdFailure(state)
        {
            state.timerecsStd = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getTimeModelsRequest(state)
        {
            state.timemodels = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getTimeModelsSuccess(state, data)
        {
            state.timemodels = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getTimeModelsFailure(state)
        {
            state.timemodels = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteTimeRecRequest(state)
        {
            state.timerecs.status = { loading: true,ready: false };
        },
        deleteTimeRecSuccess(state,id)
        {
            state.timerecs.status = { loading: false,ready: true };
            for(var i =0; i < state.timerecs.data.length;i++)
            {
                if(state.timerecs.data[i].id == id)
                {
                    state.timerecs.data.splice(i,1);
                }
            }

        },
        deleteTimeRecFailure(state)
        {
            state.timerecs.status = { loading: false,ready: false };
        },
        deleteTimeRecStdRequest(state)
        {
            state.timerecsStd.status = { loading: true,ready: false };
        },
        deleteTimeRecStdSuccess(state,id)
        {
            state.timerecsStd.status = { loading: false,ready: true };
            for(var i =0; i < state.timerecsStd.data.length;i++)
            {
                if(state.timerecsStd.data[i].id == id)
                {
                    state.timerecsStd.data.splice(i,1);
                }
            }

        },
        deleteTimeRecStdFailure(state)
        {
            state.timerecsStd.status = { loading: false,ready: false };
        },
        deleteTimeModelRequest(state)
        {
            state.timemodels.status = { loading: true,ready: false };
        },
        deleteTimeModelSuccess(state,id)
        {
            state.timemodels.status = { loading: false,ready: true };
            for(var i =0; i < state.timemodels.data.length;i++)
            {
                if(state.timemodels.data[i].id == id)
                {
                    state.timemodels.data.splice(i,1);
                }
            }

        },
        deleteTimeModelFailure(state)
        {
            state.timemodels.status = { loading: false,ready: false };
        },
        insertTimeRecRequest(state)
        {
            state.timerecs.status = { loading: true,ready: false };
        },
        insertTimeRecSuccess(state)
        {
            state.timerecs.status = { loading: false,ready: true };
        },
        insertTimeRecFailure(state)
        {
            state.timerecs.status = { loading: false,ready: false };
        },
        insertTimeRecStdRequest(state)
        {
            state.timerecsStd.status = { loading: true,ready: false };
        },
        insertTimeRecStdSuccess(state)
        {
            state.timerecsStd.status = { loading: false,ready: true };
        },
        insertTimeRecStdFailure(state)
        {
            state.timerecsStd.status = { loading: false,ready: false };
        },
        insertTimeModelRequest(state)
        {
            state.timemodels.status = { loading: true,ready: false };
        },
        insertTimeModelSuccess(state)
        {
            state.timemodels.status = { loading: false,ready: true };
        },
        insertTimeModelFailure(state)
        {
            state.timemodels.status = { loading: false,ready: false };
        },
        updateTimeRecRequest(state)
        {
            state.timerecs.status = { loading: true,ready: false };
        },
        updateTimeRecSuccess(state,data)
        {
            state.timerecs.status = { loading: false,ready: true };
            for(var i =0; i < state.timerecs.data.length;i++)
            {
                if(state.timerecs.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.timerecs.data[i] = JSON.parse(tmp);
                }
            }
        },
        updateTimeRecFailure(state)
        {
            state.timerecs.status = { loading: false,ready: false };
        },        
        updateTimeRecStdRequest(state)
        {
            state.timerecsStd.status = { loading: true,ready: false };
        },
        updateTimeRecStdSuccess(state,data)
        {
            state.timerecsStd.status = { loading: false,ready: true };
            for(var i =0; i < state.timerecsStd.data.length;i++)
            {
                if(state.timerecsStd.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.timerecsStd.data[i] = JSON.parse(tmp);
                }
            }
        },
        updateTimeRecStdFailure(state)
        {
            state.timerecs.status = { loading: false,ready: false };
        },
        updateTimeModelRequest(state)
        {
            state.timemodels.status = { loading: true,ready: false };
        },
        updateTimeModelSuccess(state,data)
        {
            state.timemodels.status = { loading: false,ready: true };
            for(var i =0; i < state.timemodels.data.length;i++)
            {
                if(state.timemodels.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.timemodels.data[i] = JSON.parse(tmp);
                }
            }
        },
        updateTimeModelFailure(state)
        {
            state.timemodels.status = { loading: false,ready: false };
        },
    }
}
