import {baseAxios} from '../helper/axiosHelper';

export const totpService = {
    deleteTotp,
    getCode,
    getQr,
    validate,
    getTotp,
    insertTotp
};

function getCode() {

    return baseAxios.get('Totp/code')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getQr() {

    return baseAxios.get('Totp/qrcode')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function validate(code) {

    return baseAxios.get('Totp/validate',{params: {code: code}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteTotp() {

    return baseAxios.delete('Auth/totp/' )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getTotp() {

    return baseAxios.get('Auth/totp/' )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertTotp() {

    return baseAxios.post('Auth/totp/' )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

