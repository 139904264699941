
import { userdefinedOptionsService } from '../services';
import baseMethods from './base';

const initialState = {  options: {data: [], status:{ loading: false,ready: false }},
                        optionsByGroup: { data: [], status:{ loading: false,ready: false }}
};

export const userdefinedOptions = {
    namespaced: true,
    state: initialState,
    actions: {
        getUserdefinedOptions({ commit },type )
        {
            commit('getUserdefinedOptionsRequest' );
            return new Promise((resolve, reject) => {

                userdefinedOptionsService.getUserdefinedOptions(type).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            var data = {options:response.data,type:type};
                            commit('getUserdefinedOptionsSuccess',data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getUserdefinedOptionsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getUserdefinedOptionsAll({ commit } )
        {
            commit('getUserdefinedOptionsAllRequest' );
            return new Promise((resolve, reject) => {

                userdefinedOptionsService.getUserdefinedOptionsAll().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getUserdefinedOptionsAllSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getUserdefinedOptionsAllFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        insertUserdefinedOption({ commit },data )
        {
            return new Promise((resolve, reject) => {

                userdefinedOptionsService.insertUserdefinedOption(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('insertUserdefinedOptionSuccess',data);
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteUserdefinedOption({ commit },id )
        {
            return new Promise((resolve, reject) => {

                userdefinedOptionsService.deleteUserdefinedOption(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteUserdefinedOptionSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
    },
    mutations: {
        getUserdefinedOptionsRequest(state)
        {
            state.options.status = { loading: true,ready: false };
        },
        getUserdefinedOptionsSuccess(state,data)
        {
            var grp = {type:data.type,options:data.options};
            
            var found = false;
            for(var i = 0; i < state.options.data.length; i++)
            {
                if(state.options.data[i].type == grp.type)
                {
                    state.options.data[i].options = grp.options;
                    found = true;
                    break;
                }
            }
            if(found == false)
            {
                state.options.data.push(grp);
            }

            state.options.status = {loading: false,ready: true };
        },
        getUserdefinedOptionsFailure(state)
        {
            state.options.status = { loading: false,ready: false };
        },
        getUserdefinedOptionsAllRequest(state)
        {
            state.optionsByGroup = {
                data:[],
                status:{ loading: true,ready: false }
            };
        },
        getUserdefinedOptionsAllSuccess(state,data)
        {
            state.optionsByGroup = {
                data:data,
                status:{ loading: false,ready: true }
            };
        },
        getUserdefinedOptionsAllFailure(state)
        {
            state.optionsByGroup = {
                data:[],
                status:{ loading: false,ready: false }
            };
        },
        insertUserdefinedOptionSuccess(state,data)
        {
            state.options.data.push(data);
        },
        deleteUserdefinedOptionSuccess(state,id)
        {
            for(var i =0; i < state.options.data.length;i++)
            {
                if(state.options.data[i].id == id)
                {
                    state.options.data.splice(i,1);
                    break;
                }
            }
        }
    }
}
