import {baseAxios} from '../helper/axiosHelper';

export const motifsService = {
    insertMotif,
    getMotifs,
    updateMotif,
    deleteMotif,
    getMotifCategories,
    insertMotifCategory,
    insertMotifCategoryMembers,
    updateMotifCategory,
    deleteMotifCategory,
    getPrevDataForMotif,
    getOrientationDataForMotif,
    updateJoomlaMotifArticle,
    getJoomlaTags,
    getMotifsFromWebsite

};

function updateJoomlaMotifArticle(id) {

    return baseAxios.get('motifs/JoomlaArticleState', {params: {id: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getMotifsFromWebsite() {

    return baseAxios.get('motifs/MotifsFromWebsite')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertMotif(data) {

    return baseAxios.post('motifs/motif',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateMotif(data) {

    return baseAxios.put('motifs/motif',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteMotif(id) {

    return baseAxios.delete('motifs/motif',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getMotifs() {

    return baseAxios.get('motifs/motifs')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getJoomlaTags() {

    return baseAxios.get('motifs/joomla_tags')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getPrevDataForMotif(id) {

    return baseAxios.get('motifs/motif/img_prev_data',{params: {id: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getOrientationDataForMotif(id) {

    return baseAxios.get('motifs/motif/img_orientation_data',{params: {id: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getMotifCategories() {

    return baseAxios.get('motifs/categories')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertMotifCategory(data) {

    return baseAxios.post('motifs/category',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertMotifCategoryMembers(data) {

    return baseAxios.post('motifs/categorymembers',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateMotifCategory(data) {

    return baseAxios.put('motifs/category',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteMotifCategory(id) {

    return baseAxios.delete('motifs/category',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}


