

const initialState = {  loadingstatus: {all_loaded:false,
                                        stati:[ {type:"language", loaded:false},
                                                {type:"country", loaded:false},
                                                {type:"section", loaded:false},
                                                {type:"currency", loaded:false},
                                                {type:"mwst", loaded:false},
                                                {type:"product", loaded:false},
                                                {type:"debitor", loaded:false},
                                                {type:"ral", loaded:false},
                                                {type:"usermin", loaded:false},
                                                {type:"translation", loaded:false},
                                                {type:"motifcategories", loaded:false},
                                                {type:"motifs", loaded:false}
                                             ]},
                        signalRConnected:false
                                            };

export const maincontainer = {
    namespaced: true,
    state: initialState,
    actions: {
        setLoadingStatus({ commit },data )
        {
            commit('setStatus',data );
        },
        setSignalRConnectionStatus({ commit },data )
        {
            commit('setConnectionStatus',data );
        },
    },
    mutations: {
        setStatus(state,data)
        {
            var all = true;
            for(var i =0; i < state.loadingstatus.stati.length; i++)
            {
                if(state.loadingstatus.stati[i].type == data.type)
                {
                    state.loadingstatus.stati[i].loaded = data.loaded;
                }

                if(state.loadingstatus.stati[i].loaded == false)
                {
                    all = false;
                }
            }
            state.loadingstatus.all_loaded = all;
        },
        setConnectionStatus(state,data)
        {
            state.signalRConnected = data;
        }

    }
}
