
import { languageService } from '../services';
import baseMethods from './base';

const initialState = { languages: {data: null,status:{ loading: false,ready: false }},
                     translations:{data:[],status:{ loading: false,ready: false }},
                     sections:{data:[],status:{ loading: false,ready: false }},
                     translationsForSection:{data:[],status:{ loading: false,ready: false }},
                     tempTranslations:{data:[],status:{ loading: false,ready: false }}
                    };

export const language = {
    namespaced: true,
    state: initialState,
    actions: {
        getLanguages({commit,dispatch } )
        {
            commit('getLanguagesRequest' );
            return new Promise((resolve, reject) => {

                languageService.getLanguages().then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getLanguagesSucces',response.data);

                            dispatch('maincontainer/setLoadingStatus', {type:"language",loaded:true},{root: true},{root: true}); 
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getLanguagesFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getTranslations({ commit,dispatch },data )
        {
            commit('getTranslationsRequest' );
            return new Promise((resolve, reject) => {

                languageService.getTranslations(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getTranslationsSucces',response.data);
                            dispatch('maincontainer/setLoadingStatus', {type:"translation",loaded:true},{root: true},{root: true}); 
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getTranslationsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        exportTranslations({commit},data)
        { 
            return new Promise((resolve, reject) => {

                languageService.exportTranslations(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            });
        },
        getTranslationsForSection({ commit },data )
        {
            commit('getTranslationsForSectionRequest' );
            return new Promise((resolve, reject) => {

                languageService.getTranslations(data).then(
                    response => {
 
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getTranslationsForSectionSucces',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getTranslationsForSectionFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        updateTranslations({ commit },data )
        {
            commit('updateTranslationsRequest' );
            return new Promise((resolve, reject) => {

                languageService.updateTranslations(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('updateTranslationsSucces',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('updateTranslationsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        deleteTranslation({ commit },data )
        {
            return new Promise((resolve, reject) => {

                languageService.deleteTranslation(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getSections({ commit,dispatch } )
        {
            commit('getSectionsRequest' );
            return new Promise((resolve, reject) => {

                languageService.getSections().then(
                    response => {
                       
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getSectionsSucces',response.data);
                            resolve(response.data);
                            dispatch('maincontainer/setLoadingStatus', {type:"section",loaded:true},{root: true},{root: true}); 
                        }
                        else
                        {
                            commit('getSectionsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        insertSection({ commit },data )
        {
            commit('insertSectionRequest' );
            return new Promise((resolve, reject) => {

                languageService.insertSection(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('insertSectionSucces',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('insertSectionFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        insertTranslation({ commit },data )
        {
            commit('insertTranslationRequest' );
            return new Promise((resolve, reject) => {

                languageService.insertTranslation(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('insertTranslationSucces',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('insertTranslationFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }  ,
        getTempTranslations({ commit } )
        {
            commit('getTempTranslationsRequest' );
            return new Promise((resolve, reject) => {

                languageService.getTempTranslations().then(
                    response => {
                       
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getTempTranslationsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getTempTranslationsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        AcceptTempTranslations({ commit },data )
        {
            return new Promise((resolve, reject) => {

                languageService.AcceptTempTranslations(data).then(
                    response => {
                       
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('acceptTempTranslationsSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        DropTempTranslations({ commit } )
        {
            return new Promise((resolve, reject) => {

                languageService.DropTempTranslations().then(
                    response => {
                       
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('dropTempTranslationsSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },                       
    },
    mutations: {
        getLanguagesRequest(state)
        {
            state.languages = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getLanguagesSucces(state, data)
        {
            state.languages = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getLanguagesFailure(state)
        {
            state.languages = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getTranslationsRequest(state)
        {
            state.translations = {
                data: [],
                status:{ loading: true,ready: false }
            };
        },
        getTranslationsSucces(state, data)
        {
            state.translations = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getTranslationsFailure(state)
        {
            state.translations = {
                data: [],
                status:{ loading: false,ready: false }
            };
        } ,
        getSectionsRequest(state)
        {
            state.sections = {
                data: [],
                status:{ loading: true,ready: false }
            };
        },
        getSectionsSucces(state, data)
        {
            state.sections = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getSectionsFailure(state)
        {
            state.sections = {
                data: [],
                status:{ loading: false,ready: false }
            };
        },
        insertSectionRequest(state)
        {
            state.sections.status={ loading: true,ready: false };
        },
        insertSectionSucces(state)
        {
            state.sections.status={ loading: false,ready: true };
            
        },
        insertSectionFailure(state)
        {
            state.sections.status={ loading: false,ready: false };
        } ,
        getTranslationsForSectionRequest(state)
        {
            state.translationsForSection = {
                data: [],
                status:{ loading: true,ready: false }
            };
        },
        getTranslationsForSectionSucces(state, data)
        {
            state.translationsForSection = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getTranslationsForSectionFailure(state)
        {
            state.translationsForSection = {
                data: [],
                status:{ loading: false,ready: false }
            };
        },
        insertTranslationRequest(state)
        {
            state.translationsForSection.status={ loading: true,ready: false };
        },
        insertTranslationSucces(state)
        {
            state.translationsForSection.status={ loading: false,ready: true };
            
        },
        insertTranslationFailure(state)
        {
            state.translationsForSection.status={ loading: false,ready: false };
        } ,
        updateTranslationsRequest(state)
        {
            state.translationsForSection.status={ loading: true,ready: false };
        },
        updateTranslationsSucces(state)
        {
            state.translationsForSection.status={ loading: false,ready: true };
            
        },
        updateTranslationsFailure(state)
        {
            state.translationsForSection.status={ loading: false,ready: false };
        },
        getTempTranslationsRequest(state)
        {
            state.tempTranslations = {
                data: [],
                status:{ loading: true,ready: false }
            };
        },
        getTempTranslationsSuccess(state, data)
        {
            state.tempTranslations = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getTempTranslationsFailure(state)
        {
            state.tempTranslations = {
                data: [],
                status:{ loading: false,ready: false }
            };
        },
        acceptTempTranslationsSuccess(state)
        {
            state.tempTranslations = {
                data: [],
                status:{ loading: false,ready: false }
            };
        },
        dropTempTranslationsSuccess(state)
        {
            state.tempTranslations = {
                data: [],
                status:{ loading: false,ready: false }
            };
        }           
    }
}
