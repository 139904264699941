
import { downloadService } from '../services';
import baseMethods from './base';

const initialState = {  downloads: {data: null,status:{ loading: false,ready: false }}};

export const download = {
    namespaced: true,
    state: initialState,
    actions: {
        getDownloads({ commit },data )
        {
            commit('getAllDownloadsRequest' );
            return new Promise((resolve, reject) => {

                downloadService.getDownloads(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getAllDownloadsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getAllDownloadsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteDownload({ commit }, id)
        {
            commit('deleteDownloadRequest' );
            return new Promise((resolve, reject) => {
                
                downloadService.deleteDownload(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteDownloadSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteDownloadFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },      
        saveDownload({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertDownloadRequest' );
                    downloadService.insertDownload(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertDownloadSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertDownloadFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateDownloadRequest' );
                    downloadService.updateDownload(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateDownloadSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateDownloadFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        // eslint-disable-next-line no-unused-vars
        downloadFile({ commit },id )
        {
            return new Promise((resolve, reject) => {

                downloadService.downloadFile(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
    },
    mutations: {
        getAllDownloadsRequest(state)
        {
            state.downloads = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getAllDownloadsSuccess(state, data)
        {
            state.downloads = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getAllDownloadsFailure(state)
        {
            state.downloads = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteDownloadRequest(state)
        {
            state.downloads.status = { loading: true,ready: false };
        },
        deleteDownloadSuccess(state,id)
        {
            state.downloads.status = { loading: false,ready: true };
            for(var i =0; i < state.downloads.data.length;i++)
            {
                if(state.downloads.data[i].id == id)
                {
                    state.downloads.data.splice(i,1);
                }
            }

        },
        deleteDownloadFailure(state)
        {
            state.downloads.status = { loading: false,ready: false };
        },
        insertDownloadRequest(state)
        {
            state.downloads.status = { loading: true,ready: false };
        },
        insertDownloadSuccess(state)
        {
            state.downloads.status = { loading: false,ready: true };
        },
        insertDownloadFailure(state)
        {
            state.downloads.status = { loading: false,ready: false };
        },
        updateDownloadRequest(state)
        {
            state.downloads.status = { loading: true,ready: false };
        },
        updateDownloadSuccess(state,data)
        {
            state.downloads.status = { loading: false,ready: true };
            for(var i =0; i < state.downloads.data.length;i++)
            {
                if(state.downloads.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.downloads.data[i] = JSON.parse(tmp);
                }
            }
        },
        updateDownloadFailure(state)
        {
            state.downloads.status = { loading: false,ready: false };
        },        

    }
}
