import {baseAxios} from '../helper/axiosHelper';

export const paymentService = {
    insertPayment,
    getPayments,
    updatePayment,
    deletePayment
};

function insertPayment(data) {

    return baseAxios.post('payments/payment',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updatePayment(data) {

    return baseAxios.put('payments/payment',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deletePayment(id) {

    return baseAxios.delete('payments/payment',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getPayments() {

    return baseAxios.get('payments/my')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
