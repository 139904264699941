import {baseAxios} from '../helper/axiosHelper';

export const languageService = {
    getLanguages,
    getTranslations,
    getSections,
    insertSection,
    insertTranslation,
    updateTranslations,
    exportTranslations,
    getTempTranslations,
    AcceptTempTranslations,
    DropTempTranslations,
    deleteTranslation
};

function getLanguages() {

    return baseAxios.get('language/Languages/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getTranslations(data) {

    return baseAxios.get('language/Translations/',{params:{language_fk: data.language_fk, section_fk:data.section_fk}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteTranslation(data) {

    return baseAxios.delete('language/Translation/',{params:{id:data}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getSections() {

    return baseAxios.get('language/Sections/' )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getTempTranslations() {

    return baseAxios.get('language/Translations/temp/' )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function AcceptTempTranslations(data) {

    return baseAxios.post('language/Translations/temp/import/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function DropTempTranslations() {

    return baseAxios.get('language/Translations/temp/drop/' )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertSection(section) {

    return baseAxios.post('language/Section/',{section:section} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertTranslation(trans) {

    return baseAxios.post('language/Translation/',trans )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateTranslations(trans) {

    return baseAxios.post('language/Translations/',trans )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function exportTranslations(data) {

    return baseAxios.get('language/Translations/export/all/',{params:{"type":data.type} })
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}