import {baseAxios} from '../helper/axiosHelper';

export const debitorcontactService = {
    getMyDebitorContacts,
    insertDebitorContact,
    getDebitorContactMember,
    insertDebitorContactMember,
    updateDebitorContactMember,
    deleteDebitorContactMember,
    getDebitorContact,
    getDebitorContactId
};

function getMyDebitorContacts() {

    return baseAxios.get('debitorcontact/my/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getDebitorContactId(data) {

    return baseAxios.get('debitorcontact/debitorcontact/id/',{params:{debitor_fk:data.debitor_fk,debitorobject_fk:data.debitorobject_fk}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getDebitorContact(id) {

    return baseAxios.get('debitorcontact/debitorcontact/',{params:{debitorcontact_fk:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getDebitorContactMember(id) {

    return baseAxios.get('debitorcontact/member/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertDebitorContact(data) {

    return baseAxios.post('debitorcontact/DebitorContact/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertDebitorContactMember(data) {

    return baseAxios.post('debitorcontact/member/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateDebitorContactMember(data) {

    return baseAxios.put('debitorcontact/member/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteDebitorContactMember(id) {

    return baseAxios.delete('debitorcontact/member/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
