
import { countryService } from '../services';
import baseMethods from './base';

const initialState = {  countries: {data: null,status:{ loading: false,ready: false }},
                        mwst: {data: null,status:{ loading: false,ready: false }},
                        mwst_all: {data: null,status:{ loading: false,ready: false }}
                    };

export const country = {
    namespaced: true,
    state: initialState,
    actions: {
        getCountries({ commit,dispatch } )
        {
            commit('getCountriesRequest' );
            return new Promise((resolve, reject) => {

                countryService.getCountries().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getCountriesSuccess',response.data);
                            dispatch('maincontainer/setLoadingStatus', {type:"country",loaded:true},{root: true},{root: true}); 
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getCountriesFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getMwstForCountry({ commit },country_fk )
        {
            commit('getMwstRequest' );
            return new Promise((resolve, reject) => {

                countryService.getMwStForCountry(country_fk).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getMwstSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getMwstFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getMwstAll({ commit,dispatch } )
        {
            commit('getMwstAllRequest' );
            return new Promise((resolve, reject) => {

                countryService.getMwstAll().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getMwstAllSuccess',response.data);
                            dispatch('maincontainer/setLoadingStatus', {type:"mwst",loaded:true},{root: true},{root: true}); 
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getMwstAllFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },        
        deleteCountry({ commit }, id)
        {
            commit('deleteCountryRequest' );
            return new Promise((resolve, reject) => {
                
                countryService.deleteCountry(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteCountrySuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteCountryFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteMwst({ commit }, id)
        {
            commit('deleteMwstRequest' );
            return new Promise((resolve, reject) => {
                
                countryService.deleteMwst(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteMwstSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteMwstFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveCountry({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertCountryRequest' );
                    countryService.insertCountry(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertCountrySuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertCountryFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateCountryRequest' );
                    countryService.updateCountry(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateCountrySuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateCountryFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        saveMwst({ commit }, data)
        {
            if(data.valid_from =='')
            {
                data.valid_from = "1900-01-01T00:00:00.0Z";
            }
            if(data.valid_to == '')
            {
                data.valid_to = "1900-01-01T00:00:00.0Z";
            }

            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertMwstRequest' );
                    countryService.insertMwst(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertMwstSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertMwstFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateMwstRequest' );
                    countryService.updateMwst(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateMwstSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateMwstFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        }
    },
    mutations: {
        getCountriesRequest(state)
        {
            state.countries = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getCountriesSuccess(state, data)
        {
            state.countries = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getCountriesFailure(state)
        {
            state.countries = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getMwstAllRequest(state)
        {
            state.mwst_all = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getMwstAllSuccess(state, data)
        {
            state.mwst_all = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getMwstAllFailure(state)
        {
            state.mwst_all = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getMwstRequest(state)
        {
            state.mwst = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getMwstSuccess(state, data)
        {
            state.mwst = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getMwstFailure(state)
        {
            state.mwst = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteCountryRequest(state)
        {
            state.countries.status = { loading: true,ready: false };
        },
        deleteCountrySuccess(state)
        {
            state.countries.status = { loading: false,ready: true };
        },
        deleteCountryFailure(state)
        {
            state.countries.status = { loading: false,ready: false };
        },
        deleteMwstRequest(state)
        {
            state.mwst.status = { loading: true,ready: false };
        },
        deleteMwstSuccess(state)
        {
            state.mwst.status = { loading: false,ready: true };
        },
        deleteMwstFailure(state)
        {
            state.mwst.status = { loading: false,ready: false };
        },
        updateCountryRequest(state)
        {
            state.countries.status = { loading: true,ready: false };
        },
        updateCountrySuccess(state)
        {
            state.countries.status={ loading: false,ready: true };
        },
        updateCountryFailure(state)
        {
            state.countries.status = { loading: false,ready: false };
        },
        insertCountryRequest(state)
        {
            state.countries.status = { loading: true,ready: false };
        },
        insertCountrySuccess(state)
        {
            state.countries.status = { loading: false,ready: true };
        },
        insertCountryFailure(state)
        {
            state.countries.status = { loading: false,ready: false };
        },
        updateMwstRequest(state)
        {
            state.mwst.status = { loading: true,ready: false };
        },
        updateMwstSuccess(state)
        {
            state.mwst.status = {loading: false,ready: true };
        },
        updateMwstFailure(state)
        {
            state.mwst.status = { loading: false,ready: false };
        },
        insertMwstRequest(state)
        {
            state.mwst.status = { loading: true,ready: false };
        },
        insertMwstSuccess(state)
        {
            state.mwst.status= { loading: false,ready: true };
        },
        insertMwstFailure(state)
        {
            state.mwst.status = { loading: false,ready: false };
        }
    }
}
