
import { mainSettingsService } from '../services';
import baseMethods from './base';

const initialState = { mainsettings: {data: null,status:{ loading: false,ready: false }}};

export const mainsettings = {
    namespaced: true,
    state: initialState,
    actions: {
        getMainSettings({ commit } )
        {
            commit('getMainSettingsRequest' );
            return new Promise((resolve, reject) => {

                mainSettingsService.getMainSettings().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getMainSettingsSucces',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getMainSettingsFailure');
                            reject(response.statusText);
                        }

                    }
                );

            })
        },
        updateMainSettings({ commit },data )
        {
            commit('updateMainSettingsRequest' );
            return new Promise((resolve, reject) => {

                mainSettingsService.updateMainSettings(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('updateMainSettingsSucces',data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('updateMainSettingsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }        
    },
    mutations: {
        getMainSettingsRequest(state)
        {
            state.mainsettings = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getMainSettingsSucces(state, data)
        {
            state.mainsettings = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getMainSettingsFailure(state)
        {
            state.mainsettings = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        updateMainSettingsRequest(state)
        {
            state.mainsettings = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        updateMainSettingsSucces(state, data)
        {
            state.mainsettings = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        updateMainSettingsFailure(state)
        {
            state.mainsettings = {
                data: null,
                status:{ loading: false,ready: false }
            };
        }        
    }
}
