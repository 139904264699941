import Vue from 'vue'
import Vuex from 'vuex'

import { sidebar } from './sidebar.module';
import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { users } from './users.module';
import { adminpages } from './adminpages.module';
import { address } from './address.module';
import { country } from './country.module';
import { language } from './language.module';
import { alertqueue } from './alertqueue.module';
import { group } from './group.module';
import { right } from './right.module';
import { polling } from './polling.module';
import { banner } from './banner.module';
import { mainsettings } from './mainsettings.module';
import { messages } from './messages.module';
import { bankaccount } from './bankaccount.module';
import { company } from './company.module';
import { unsavedData } from './unsavedData.module';
import { products } from './products.module';
import { pricelists } from './pricelists.module';
import { currencies } from './currencies.module';
import { document } from './document.module';
import { ralcolor } from './ralcolor.module';
import { debitor } from './debitor.module';
import { debitorcontact } from './debitorcontact.module';
import { debitorobject } from './debitorobject.module';
import { numbergroup} from './numbergroup.module';
import { accounting } from './accounting.module';
import { fixtext } from './fixtext.module';
import { productgroup } from './productgroup.module';
import { productcategory } from './productcategory.module';
import { payment } from './payment.module';
import { deliverycondition } from './deliverycondition.module';
import { paymentcondition } from './paymentcondition.module';
import { shipment } from './shipment.module';
import { dashboarddata } from './dashboarddata.module';
import { maincontainer } from './maincontainer.module';
import { companygroup } from './companygroup.module';
import { productvariant } from './productvariant.module';
import { motif } from './motifs.module';
import { partnerorder } from './partnerorder.module';
import { partnerdiscount } from './partnerdiscount.module';
import { testcall } from './testcall.module';
import { timerec } from './timerec.module';
import { search } from './search.module';
import { totp } from './totp.module';
import { userdefinedOptions } from './userdefinedOptions.module';
import { confirmation } from  './confirmation.module';
import { specialdiscount } from './specialdiscount.module';
import { download } from './download.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        sidebar,
        alert,
        authentication,
        users,
        adminpages,
        address,
        country,
        language,
        alertqueue,
        group,
        right,
        polling,
        banner,
        mainsettings,
        messages,
        bankaccount,
        company,
        unsavedData,
        products,
        pricelists,
        currencies,
        document,
        ralcolor,
        debitor,
        debitorcontact,
        debitorobject,
        numbergroup,
        accounting,
        fixtext,
        productgroup,
        productcategory,
        payment,
        deliverycondition,
        paymentcondition,
        shipment,
        dashboarddata,
        maincontainer,
        companygroup,
        productvariant,
        motif,
        partnerorder,
        partnerdiscount,
        testcall,
        timerec,
        search,
        totp,
        userdefinedOptions,
        confirmation,
        specialdiscount,
        download
    }
});
