import {baseAxios} from '../helper/axiosHelper';

export const shipmentService = {
    insertShipment,
    getShipments,
    updateShipment,
    updateShipmentState,
    deleteShipment,
    deleteShipmentMember,
    mergeShipmentMember
};

function insertShipment(data) {

    return baseAxios.post('shipments/shipment',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateShipment(data) {

    return baseAxios.put('shipments/shipment',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateShipmentState(data) {

    return baseAxios.put('shipments/shipment/state',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function mergeShipmentMember(data) {

    return baseAxios.put('shipments/shipment/member/merge',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteShipment(id) {

    return baseAxios.delete('shipments/shipment',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteShipmentMember(id) {

    return baseAxios.delete('shipments/shipment/member',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getShipments(id) {

    return baseAxios.get('shipments/order',{params: {order_fk: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
