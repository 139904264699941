export const pagemode = {
    MIN:-1,
    VIEW: 0,
    EDIT: 1,
    NEW: 2,
    MAX:3
  };
const initialState = {  userAdminPageMode: pagemode.VIEW,
                        companyAdminPageMode:pagemode.VIEW
                    };
// Hier werden die Pagemodes gespeichert und behandelt (welches Status hat die Seite - edit? view?)
// Wird benötigt um die Ansicht der Seiten über ein Module (dispatch) beeinflussen zu können
export const adminpages = {
    namespaced: true,
    state: initialState,
    actions: {
        changePageModeUserAdmin({dispatch, commit }, newPageMode )
        {
            if(newPageMode >= pagemode.MIN && newPageMode < pagemode.MAX)
            {
                commit('setUserAdminPageMode',  newPageMode );
                if(newPageMode == pagemode.NEW || newPageMode == pagemode.VIEW)
                {
                    dispatch('users/clearUserFullData', { root: true },{ root: true });
                    dispatch('address/clearAddressList', { root: true },{ root: true });
                    dispatch('bankaccount/clearBankAccountList', { root: true },{ root: true });
                    dispatch('unsavedData/setUserAdmin', false,{ root: true });
                }
            }
        },
        changePageModeCompanyAdmin({dispatch, commit }, newPageMode )
        {
            if(newPageMode >= pagemode.MIN && newPageMode < pagemode.MAX)
            {
                commit('setCompanyAdminPageMode',  newPageMode );
                if(newPageMode == pagemode.NEW || newPageMode == pagemode.VIEW)
                {
                    dispatch('company/clearCompanyFullData', { root: true },{ root: true });
                    dispatch('address/clearAddressList', { root: true },{ root: true });
                    dispatch('bankaccount/clearBankAccountList', { root: true },{ root: true });
                    dispatch('unsavedData/setCompanyAdmin', false,{ root: true });
                }
            }
        }
    },
    mutations: {
        setUserAdminPageMode(state, newPageMode) {
            state.userAdminPageMode = newPageMode;
        },
        setCompanyAdminPageMode(state, newPageMode) {
            state.companyAdminPageMode = newPageMode;
        }
    }
}
