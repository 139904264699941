import {baseAxios} from '../helper/axiosHelper';

export const productvariantService = {
    getProductvariants,
    updateProductvariant,
    insertProductvariant,
    deleteProductvariant,
    getProductvariantGroups,
    updateProductvariantGroup,
    insertProductvariantGroup,
    deleteProductvariantGroup,
    insertProductvariantGroupMembers
};

function getProductvariants() {

    return baseAxios.get('productvariants/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getProductvariantGroups() {

    return baseAxios.get('productvariants/groups')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateProductvariant(data) {

    return baseAxios.put('productvariants/productvariant',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateProductvariantGroup(data) {

    return baseAxios.put('productvariants/ProductVariantGroup',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertProductvariant(data) {

    return baseAxios.post('productvariants/productvariant',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertProductvariantGroup(data) {

    return baseAxios.post('productvariants/ProductVariantGroup',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}


function insertProductvariantGroupMembers(data) {

    return baseAxios.post('productvariants/members',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}


function deleteProductvariant(id) {

    return baseAxios.delete('productvariants/productvariant',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteProductvariantGroup(id) {

    return baseAxios.delete('productvariants/ProductVariantGroup',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}