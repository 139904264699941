
import { addressService } from '../services';
import baseMethods from './base';

const initialState = { addressListUser: {data: null,status:{ loading: false,ready: false }}};


function SetAddrVal(state,id,prop,val){
    if(state.addressListUser.data != null)
    {
        for (let i = 0; i < state.addressListUser.data.length; i++)
        {
            if(state.addressListUser.data[i].id == id)
            {
                state.addressListUser.data[i][prop] = val;
                break;
            }
        }
    }
}

export const address = {
    namespaced: true,
    state: initialState,
    actions: {
        getAddrListOfUser({ commit }, data )
        {
            commit('getAddrListOfUserRequest' );
            return new Promise((resolve, reject) => {
                
                addressService.getAddrListOfUser(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getAddrListOfUserSucces',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getAddrListOfUserFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getAddressListForCompanyAndUser({ commit }, data )
        {
            commit('getAddrListOfUserRequest' );
            return new Promise((resolve, reject) => {
                
                addressService.getAddressListForCompanyAndUser(data.id,data.forTable).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getAddrListOfUserSucces',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getAddrListOfUserFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteAddr({ commit }, id)
        {
            commit('deleteAddrRequest' );
            return new Promise((resolve, reject) => {
                
                addressService.deleteAddr(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteAddrSuccess',response);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteAddrFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveAddr({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertAddrRequest' );
                    addressService.insertAddr(data).then(
                        response => {
                         
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertAddrSuccess',response);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertAddrFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateAddrRequest' );
                    addressService.updateAddr(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateAddrSuccess',response);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateAddrFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        clearAddressList({ commit })
        {
          commit('clearAddressList');
        },
        updateAddrListBez({ commit }, data )
        {
          commit('updateAddrListBez',data);
        },
        updateAddrListStreet1({ commit }, data )
        {
          commit('updateAddrListStreet1',data);
        },
        updateAddrListStreet2({ commit }, data )
        {
          commit('updateAddrListStreet2',data);
        },
        updateAddrListPlz({ commit }, data )
        {
          commit('updateAddrListPlz',data);
        },
        updateAddrListCity({ commit }, data )
        {
          commit('updateAddrListCity',data);
        },
        updateAddrListNotes({ commit }, data )
        {
          commit('updateAddrListNotes',data);
        },
        updateAddrListCountry_fk({ commit }, data )
        {
          commit('updateAddrListCountry_fk',data);
        },
        updateAddrListAddressType({ commit }, data )
        {
          commit('updateAddrListAddressType',data);
        }
    },
    mutations: {
        deleteAddrRequest(state)
        {
            state.addressListUser.status = { loading: true,ready: false };
        },
        deleteAddrSuccess(state)
        {
            state.addressListUser.status = { loading: false,ready: true };
        },
        deleteAddrFailure(state)
        {
            state.addressListUser.status = { loading: false,ready: false };
        },                
        getAddrListOfUserRequest(state)
        {
            state.addressListUser = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getAddrListOfUserSucces(state, data)
        {
            state.addressListUser = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getAddrListOfUserFailure(state)
        {
            state.addressListUser = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        updateAddrRequest(state)
        {
            state.addressListUser = {
                status:{ loading: true,ready: false }
            };
        },
        updateAddrSuccess(state)
        {
            state.addressListUser = {
                status:{ loading: false,ready: true }
            };
        },
        updateAddrFailure(state)
        {
            state.addressListUser = {
                status:{ loading: false,ready: false }
            };
        },
        insertAddrRequest(state)
        {
            state.addressListUser = {
                status:{ loading: true,ready: false }
            };
        },
        insertAddrSuccess(state)
        {
            state.addressListUser = {
                status:{ loading: false,ready: true }
            };
        },
        insertAddrFailure(state)
        {
            state.addressListUser = {
                status:{ loading: false,ready: false }
            };
        },
        updateAddrListBez(state, data)
        {
            SetAddrVal(state,data.id,'bez',data.value);
        },        
        updateAddrListStreet1(state, data)
        {
            SetAddrVal(state,data.id,'street1',data.value);
        },
        updateAddrListStreet2(state, data)
        {
            SetAddrVal(state,data.id,'street2',data.value);
        },
        updateAddrListPlz(state, data)
        {
            SetAddrVal(state,data.id,'plz',data.value);
        },
        updateAddrListCity(state, data)
        {
            SetAddrVal(state,data.id,'city',data.value);
        },
        updateAddrListNotes(state, data)
        {
            SetAddrVal(state,data.id,'notes',data.value);
        },
        updateAddrListCountry_fk(state, data)
        {
            SetAddrVal(state,data.id,'country_fk',data.value);
        },
        updateAddrListAddressType(state, data)
        {
            SetAddrVal(state,data.id,'addressType',data.value);
        },
        clearAddressList(state)
        {
            state.addressListUser.data = null;
        }
    }
}
