import {baseAxios} from '../helper/axiosHelper';

export const bankaccountService = {
    getBankAccountListOfGuid,
    getBankAccountListForCompanyAndUser,
    updateBankAccount,
    insertBankAccount,
    deleteBankAccount
};

function getBankAccountListOfGuid(data) {

    return baseAxios.get('bankaccount/bankaccount/id',{params: {id: data.id, type:data.ownertype, fortable: data.forTable}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getBankAccountListForCompanyAndUser(data) {

    return baseAxios.get('bankaccount/bankaccount/id/compuser',{params: {id: data.id, fortable: data.forTable}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateBankAccount(data) {

    return baseAxios.put('bankaccount/bankaccount',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertBankAccount(data) {

    return baseAxios.post('bankaccount/bankaccount',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteBankAccount(id) {

    return baseAxios.delete('bankaccount/bankaccount',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}