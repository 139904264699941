
import { searchService } from '../services';
import baseMethods from './base';

const initialState = {  cred_deb: {data: null,status:{ loading: false,ready: false }}};

export const search = {
    namespaced: true,
    state: initialState,
    actions: {
        searchCredDeb({ commit },data )
        {
            commit('searchCredDebRequest' );
            return new Promise((resolve, reject) => {

                searchService.searchCredDeb(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('searchCredDebSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('searchCredDebFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }
    },
    mutations: {
        searchCredDebRequest(state)
        {
            state.cred_deb = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        searchCredDebSuccess(state, data)
        {
            state.cred_deb = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        searchCredDebFailure(state)
        {
            state.cred_deb = {
                data: null,
                status:{ loading: false,ready: false }
            };
        }

    }
}
