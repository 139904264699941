import {baseAxios} from '../helper/axiosHelper';

export const bannerService = {
    getBanner,
    deleteBanner,
    updateBanner,
    insertBanner
};

function getBanner() {

    return baseAxios.get('banner/banner/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteBanner(id) {

    return baseAxios.delete('banner/banner',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateBanner(data) {

    return baseAxios.put('banner/banner',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertBanner(data) {

    return baseAxios.post('banner/banner',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}