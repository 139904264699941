import {baseAxios} from '../helper/axiosHelper';

export const productcategoryService = {
    getProductcategories,
    updateProductcategory,
    insertProductcategory,
    deleteProductcategory,
    updateProductcategoryMember,
    insertProductcategoryMember,
    deleteProductcategoryMember,
    insertProductcategoryMembers
};

function getProductcategories() {

    return baseAxios.get('productcategory/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateProductcategory(data) {

    return baseAxios.put('productcategory/productcategory',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertProductcategory(data) {

    return baseAxios.post('productcategory/productcategory',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteProductcategory(id) {

    return baseAxios.delete('productcategory/productcategory',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateProductcategoryMember(data) {

    return baseAxios.put('productcategory/member',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertProductcategoryMember(data) {

    return baseAxios.post('productcategory/member',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertProductcategoryMembers(data) {

    return baseAxios.post('productcategory/members',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteProductcategoryMember(id) {

    return baseAxios.delete('productcategory/member',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}