import {baseAxios} from '../helper/axiosHelper';

export const hubService = {
    testHub,
    testHubUser
};

function testHub(text) {

    return baseAxios.get('hub/test',{params: {text: text.text}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function testHubUser() {

    return baseAxios.get('hub/test/user')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

