import {baseAxios} from '../helper/axiosHelper';

export const companygroupService = {
    getCompanyGroups,
    insertCompanyGroup,
    updateCompanyGroup,
    deleteCompanyGroup,
    getPossibleMember,
    updateCompanyGroupMember
};

function getCompanyGroups() {

    return baseAxios.get('CompanyGroup/CompanyGroups')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getPossibleMember(id) {

    return baseAxios.get('CompanyGroup/CompanyGroupMember/possible',{params: {group_fk: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertCompanyGroup(data) {

    return baseAxios.post('CompanyGroup/CompanyGroup',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateCompanyGroup(data) {

    return baseAxios.put('CompanyGroup/CompanyGroup',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateCompanyGroupMember(data) {

    return baseAxios.put('CompanyGroup/CompanyGroup/member',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteCompanyGroup(id) {

    return baseAxios.delete('CompanyGroup/CompanyGroup',{params: {id: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

deleteCompanyGroup