import {baseAxios} from '../helper/axiosHelper';

export const rightService = {
    getRightsForGroup,
    getRightsForCompanyGroup,
    updateRightsForCompanyGroup,
    updateRightsForGroup
};

function getRightsForGroup(id) {

    return baseAxios.get('right/GroupRights',{params: {id: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getRightsForCompanyGroup(id) {

    return baseAxios.get('right/CompanyGroupRights',{params: {id: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateRightsForCompanyGroup(data) {

    return baseAxios.put('right/CompanyGroupRights',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateRightsForGroup(data) {

    return baseAxios.put('right/GroupRights',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}