export const sidebar = {
    namespaced: true,
    state: {
    
        isSidebarActive: false,
        themeColor: "#296244"
    },
    mutations: {
        //This is for Sidbar trigger in mobile
        IS_SIDEBAR_ACTIVE(state, value) {
          state.isSidebarActive = value;
      },
      
    },  
    actions: {
        
    },
    getters:{
        
    }
}
