import {baseAxios} from '../helper/axiosHelper';

export const searchService = {
    searchCredDeb
};

function searchCredDeb(text) {

    return baseAxios.get('search/fulltext',{params: {text: text.text}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
