import axios from 'axios'
import LocalStorageService from '../services/localstorage.service';
import {router} from '../router';
import {i18n} from '../helper/i18nHelper';
import {config} from '../config';

const localStorageService = LocalStorageService.getService();

export const baseAxios = axios.create({
    baseURL: config.apiBaseUrl
  });
  
  baseAxios.interceptors.request.use(
    config => {
        const token = localStorageService.getAuthToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

    baseAxios.interceptors.response.use(
        (response) => {
        return response;  
    }, 
    function (error) {
        const originalRequest = error.config;

        // wenn auch der refresh aufruf 401 liefert wird zum login umgeleitet -> refresh auch abgelaufen
        if (error.response.status === 401 && originalRequest.url.includes('/auth/refresh')) {
            localStorageService.clearToken();
            router.push('/login');
            return Promise.reject(error);
        }

        switch(error.response.status)
        {
            case 403: // forbidden
            {
                error.response.statusText = i18n.t('AXIOS.ERROR.FORBIDDEN');
                break;
            }
            case 404:// Not Found
            {
                error.response.statusText = i18n.t('AXIOS.ERROR.NOTFOUND');
                break;     
            }
            case 400:// Bad Request
            {
                error.response.statusText = i18n.t('AXIOS.ERROR.BADREQUEST');
                if(typeof (error.response.data.message) != 'undefined')
                {
                    error.response.statusText += error.response.data.message;
                }
                
                break;   
            } 
            case 500:// Internal Server eRror
            {
                error.response.statusText = i18n.t('AXIOS.ERROR.INTERNAL_ERROR');
                if(typeof (error.response.data.message) != 'undefined')
                {
                    error.response.statusText += error.response.data.message;
                }
                break;   
            }                        
        }

        // wenn 401 und es handelt sich noch nicht um den zweiten versuch -> refresh aufrufen und erneut die url aufrufen
        if (error.response.status === 401 && !originalRequest._retry) {
    
            originalRequest._retry = true;
            const refreshToken = localStorageService.getRefreshToken();
            const authToken = localStorageService.getAuthToken();
            return baseAxios.post('/auth/refresh/',
                {
                    "authToken": authToken,
                    "refreshToken": refreshToken
                })
                .then(res => {
                    if (res.status === 200) {
                        localStorageService.setToken(res.data);
                        baseAxios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAuthToken();
                        return baseAxios(originalRequest);
                    }
                })
        }
        return Promise.reject(error);
  });

  export default baseAxios;