import {baseAxios} from '../helper/axiosHelper';

export const confirmationService = {
    getConfirmations,
    updateConfirmation
};

function getConfirmations() {

    return baseAxios.get('confirmation/confirmations/my')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateConfirmation(data) {

    
    return baseAxios.put('confirmation/confirm',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}