import {baseAxios} from '../helper/axiosHelper';

export const numbergroupService = {
    getNumbergroupsByCompany,
    getNumbergroup,
    updateNumbergroup,
    insertNumbergroup,
    deleteNumbergroup
};

function getNumbergroupsByCompany(company_fk) {

    return baseAxios.get('company/Numbergroups/',{params: {company_fk: company_fk}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getNumbergroup(id) {

    return baseAxios.get('company/Numbergroup/',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateNumbergroup(data) {

    return baseAxios.put('company/Numbergroup',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertNumbergroup(data) {

    return baseAxios.post('company/Numbergroup',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteNumbergroup(id) {

    return baseAxios.delete('company/Numbergroup',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
