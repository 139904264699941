
import { groupService } from '../services';
import baseMethods from './base';

const initialState = { groups: {data:[],status:{ loading: false,ready: false }},
                       possiblemember:{data:[],status:{ loading: false,ready: false }}
                    };

export const group = {
    namespaced: true,
    state: initialState,
    actions: {
        getGroups({ commit } )
        {
            commit('getGroupsRequest' );
            return new Promise((resolve, reject) => {
                groupService.getGroups().then(

                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getGroupsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getGroupsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },   

        getPossibleMember({ commit } )
        {
            commit('getPossibleMemberRequest' );
            return new Promise((resolve, reject) => {
                groupService.getPossibleMember().then(

                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getPossibleMemberSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getPossibleMemberFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        // eslint-disable-next-line no-unused-vars
        updateGroupMember({commit},data){
            return new Promise((resolve, reject) => {

                groupService.updateGroupMember(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        saveGroup({commit,dispatch},data){
            
            if(data.id == 0)
            {
                commit('newGroupRequest');
                return new Promise((resolve, reject) => {

                    groupService.newGroup(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('newGroupSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('newGroupFailure');
                                reject(response.statusText);
                            }
                        }
                    );

                })
            }
            else
            {
                commit('saveGroupRequest');
                groupService.saveGroup(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            if(response.data.success == true)
                            {
                                dispatch('alertqueue/addSuccess', {'titel':'Erfolgreich!','text':'Benutzergruppe '+data.name+' erfolgreich gespeichert.'},{root:true});
                                dispatch('getGroups');
                                commit('saveGroupSuccess');
                            }
                            else
                            {
                                dispatch('alertqueue/addError', {'titel':'Fehler','text':'Benutzergruppe '+data.name+' konnten nicht gespeichert werden!'},{root:true});
                                commit('saveGroupFailure');
                            }
                        }
                        else
                        {
                            dispatch('alertqueue/addError', {'titel':'Fehler','text':'Benutzergruppe '+data.name+' konnten nicht gespeichert werden: '+response.statusText},{root:true});
                            commit('saveGroupFailure');
                        }
                    }
                );
            }
        },
        deleteGroup({commit,dispatch},parameters){
            commit('deleteGroupRequest');
            groupService.deleteGroup(parameters.id).then(
                response => {

                    if(baseMethods.IsSuccess(response))
                    {
                        if(response.data.success == true)
                        {
                            dispatch('alertqueue/addSuccess', {'titel':'Erfolgreich!','text':'Benutzergruppe '+parameters.name+' erfolgreich gelöscht.'},{root:true});
                            dispatch('getGroups');
                            commit('deleteGroupSuccess');
                        }
                        else
                        {
                            dispatch('alertqueue/addError', {'titel':'Fehler','text':'Benutzergruppe '+parameters.name+' konnten nicht gelöscht werden!'},{root:true});
                            commit('deleteGroupFailure');
                        }
                    }
                    else
                    {
                        dispatch('alertqueue/addError', {'titel':'Fehler','text':'Benutzergruppe '+parameters.name+' konnten nicht gelöscht werden: '+response.statusText},{root:true});
                        commit('deleteGroupFailure');
                    }
                }
            );
        },        
        removeGroupMember({commit,dispatch},id){

            commit('removeGroupMemberRequest');
            groupService.removeGroupMember(id).then(
                response => {

                    if(baseMethods.IsSuccess(response))
                    {
                        if(response.data.success == true)
                        {
                            dispatch('alertqueue/addSuccess', {'titel':'Enfernt!','text':'Benutzer wurde aus der Gruppe entfernt.'},{root:true});
                            dispatch('getGroups');
                            commit('removeGroupMemberSuccess');
                        }
                        else
                        {
                            dispatch('alertqueue/addError', {'titel':'Fehler','text':'Benutzer konnte nicht aus der Gruppe entfernt werden!'},{root:true});
                            commit('removeGroupMemberFailure');
                        }
                    }
                    else
                    {
                        dispatch('alertqueue/addError', {'titel':'Fehler','text':'Benutzer konnte nicht aus der Gruppe entfernt werden: '+response.statusText},{root:true});
                        commit('removeGroupMemberFailure');
                    }
                }
            ); 
        },
        updateGroupRights({commit},data)
        {
            commit('updateGroupRights',data);
        }
    },
    mutations: {
        getGroupsRequest(state)
        {
            state.groups = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getGroupsSuccess(state, data)
        {
            state.groups = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getGroupsFailure(state)
        {
            state.groups = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getPossibleMemberRequest(state)
        {
            state.possiblemember = {
                data: [],
                status:{ loading: true,ready: false }
            };
        },
        getPossibleMemberSuccess(state, data)
        {
            state.possiblemember = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getPossibleMemberFailure(state)
        {
            state.possiblemember = {
                data: [],
                status:{ loading: false,ready: false }
            };
        },        
        newGroupRequest(state)
        {
            state.groups.status={ loading: true,ready: false };
        },
        newGroupSuccess(state)
        {
            state.groups.status={ loading: false,ready: true };
        },
        newGroupFailure(state)
        {
            state.groups.status={ loading: false,ready: false };
        },
        removeGroupMemberRequest(state)
        {
            state.groups.status={ loading: true,ready: false };        
        },
        removeGroupMemberSuccess(state)
        {
            state.groups.status={ loading: false,ready: true };    
        },
        removeGroupMemberFailure(state)
        {
            state.groups.status={ loading: false,ready: false };    
        },
        saveGroupRequest(state)
        {
            state.groups.status={ loading: true,ready: false };        
        },
        saveGroupSuccess(state)
        {
            state.groups.status={ loading: false,ready: true };    
        },
        saveGroupFailure(state)
        {
            state.groups.status={ loading: false,ready: false };    
        },
        deleteGroupRequest(state)
        {
            state.groups.status={ loading: true,ready: false };        
        },
        deleteGroupSuccess(state)
        {
            state.groups.status={ loading: false,ready: true };    
        },
        deleteGroupFailure(state)
        {
            state.groups.status={ loading: false,ready: false };    
        },
        updateGroupRights(state,data)
        {
            state.groups.data.forEach(group=>{

                if(group.id == data.group_fk)
                {
                    group.groupRights = data.rights;
                }
            });
        }
    }
}
