
import { specialdiscountService } from '../services';
import baseMethods from './base';

const initialState = {  specialdiscounts: {data: null,status:{ loading: false,ready: false }}};

export const specialdiscount = {
    namespaced: true,
    state: initialState,
    actions: {
        getSpecialDiscounts({ commit },data )
        {
            commit('getAllMySpecialDiscountsRequest' );
            return new Promise((resolve, reject) => {

                specialdiscountService.getSpecialDiscounts(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getAllMySpecialDiscountsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getAllMySpecialDiscountsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteSpecialDiscount({ commit }, id)
        {
            commit('deleteSpecialDiscountRequest' );
            return new Promise((resolve, reject) => {
                
                specialdiscountService.deleteSpecialDiscount(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteSpecialDiscountSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteSpecialDiscountFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },      
        saveSpecialDiscount({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertSpecialDiscountRequest' );
                    specialdiscountService.insertSpecialDiscount(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertSpecialDiscountSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertSpecialDiscountFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateSpecialDiscountRequest' );
                    specialdiscountService.updateSpecialDiscount(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateSpecialDiscountSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateSpecialDiscountFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
    },
    mutations: {
        getAllMySpecialDiscountsRequest(state)
        {
            state.specialdiscounts = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getAllMySpecialDiscountsSuccess(state, data)
        {
            state.specialdiscounts = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getAllMySpecialDiscountsFailure(state)
        {
            state.specialdiscounts = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteSpecialDiscountRequest(state)
        {
            state.specialdiscounts.status = { loading: true,ready: false };
        },
        deleteSpecialDiscountSuccess(state,id)
        {
            state.specialdiscounts.status = { loading: false,ready: true };
            for(var i =0; i < state.specialdiscounts.data.length;i++)
            {
                if(state.specialdiscounts.data[i].id == id)
                {
                    state.specialdiscounts.data.splice(i,1);
                }
            }

        },
        deleteSpecialDiscountFailure(state)
        {
            state.specialdiscounts.status = { loading: false,ready: false };
        },
        insertSpecialDiscountRequest(state)
        {
            state.specialdiscounts.status = { loading: true,ready: false };
        },
        insertSpecialDiscountSuccess(state)
        {
            state.specialdiscounts.status = { loading: false,ready: true };
        },
        insertSpecialDiscountFailure(state)
        {
            state.specialdiscounts.status = { loading: false,ready: false };
        },
        updateSpecialDiscountRequest(state)
        {
            state.specialdiscounts.status = { loading: true,ready: false };
        },
        updateSpecialDiscountSuccess(state,data)
        {
            state.specialdiscounts.status = { loading: false,ready: true };
            for(var i =0; i < state.specialdiscounts.data.length;i++)
            {
                if(state.specialdiscounts.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.specialdiscounts.data[i] = JSON.parse(tmp);
                }
            }
        },
        updateSpecialDiscountFailure(state)
        {
            state.specialdiscounts.status = { loading: false,ready: false };
        },        

    }
}
