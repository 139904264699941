import {baseAxios} from '../helper/axiosHelper';

export const ralcolorService = {
    getAllRalColors
};

function getAllRalColors() {

    return baseAxios.get('ralcolor/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}