import {baseAxios} from '../helper/axiosHelper';

export const productgroupService = {
    getProductgroups,
    updateProductgroup,
    insertProductgroup,
    deleteProductgroup
};

function getProductgroups() {

    return baseAxios.get('productgroups/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateProductgroup(data) {

    return baseAxios.put('productgroups/productgroup',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertProductgroup(data) {

    return baseAxios.post('productgroups/productgroup',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteProductgroup(id) {

    return baseAxios.delete('productgroups/productgroup',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}