export const ownertype = {
    UNKNOWN:0,
    USER: 1,
    COMPANY: 2,
    DEBITOR: 3,
    CREDITOR: 4
  };

export default {

        DateToString(datetimestring, dateonly)
        {
            var retVal = "";
            if(datetimestring != null)
            {
                if(datetimestring.length > 0)
                {
                    var tmp = Date.parse(datetimestring);
                    if(tmp > 0)
                    {
                        var datetime = new Date(datetimestring);

                        var offset = new Date().getTimezoneOffset();
                        datetime.setMinutes(datetime.getMinutes() - offset);
                        
                        if(dateonly == true)
                        {
                            
                            retVal = datetime.toLocaleDateString();
                        }
                        else
                        {
                            retVal = datetime.toLocaleString();
                        }
                    }
                }
            }
            return retVal;
        },
        DateToTimeString(datetimestring)
        {
            var retVal = "";
            if(datetimestring != null)
            {
                if(datetimestring.length > 0)
                {
                    var tmp = Date.parse(datetimestring);
                    if(tmp > 0)
                    {
                        var datetime = new Date(datetimestring);

                        var offset = new Date().getTimezoneOffset();
                        datetime.setMinutes(datetime.getMinutes() - offset);
                        
                        retVal = datetime.toLocaleTimeString();
                        
                    }
                }
            }
            return retVal;
        },
        DateTimeStringToDate(datetimestring){
            var retVal = null;
            if(datetimestring != null)
            {
                if(datetimestring.length > 0)
                {
                    var tmp = Date.parse(datetimestring);
                    if(tmp > 0)
                    {
                        var datetime = new Date(datetimestring);

                        var offset = new Date().getTimezoneOffset();
                        datetime.setMinutes(datetime.getMinutes() - offset);
                        
                        retVal = datetime;
                    }
                }
            }
            return retVal;
        },
        NumberGroupSchemaToString(schema,next_nr,platzhalter){
            var idx_nr = schema.indexOf("#N");
            var anz_leading = Number(schema.substr(idx_nr+2,1));
            var ret = "";
            if(anz_leading > 0)
            {
              ret = schema.replace("#N"+anz_leading,Number(next_nr).toFixed(0).padStart(anz_leading, '0'));
            }
            else
            {
              ret = schema.replace("#N",Number(next_nr).toFixed(0));
            }
            var d = new Date();
            ret = ret.replace("#Y",d.getFullYear());
            
            if(typeof(platzhalter) != 'undefined' && platzhalter != null)
                ret = ret.replace("#G",platzhalter);

            return ret;
        },
        GetNameOfGroup(group_fk)
        {
            var retVal = "";    
            if(this.$store.state.group.groups.data != null)
            {
              for (let i = 0; i < this.$store.state.group.groups.data.length; i++)
              {
                  if(this.$store.state.group.groups.data[i].id == group_fk )
                  {
                    retVal = this.$store.state.group.groups.data[i].name;
                    break;
                  }
              }        
            }
            return retVal;
        },
        GetUserNameForGuidFromUserOverview(guid)
        {

            var retVal = "";    
            if(this.$store.state.users.useroverview != null)
            {
                for(var i=0; i < this.$store.state.users.useroverview.length; i++)
                {
                    if(this.$store.state.users.useroverview[i].userGuid == guid)
                    {
                        retVal = this.GenerateTextForUserSelect(this.$store.state.users.useroverview[i]);
                        break;
                    }
                }        
            }
            return retVal;           
        },
        GenerateTextForUserSelect(member){
            return member.firstName +' '+ member.lastName + ' ('+ member.partnerID +')';
        },
        GetListOfNumbergroupsForSelect(grptype){
            var ret = {numbergroups:[],default:-1};
            ret.numbergroups.push({"text": "- - - - -", "value":-1});
            
            if(this.$store.state.numbergroup.numbergroups.data != null)
            {
              for (let i = 0; i < this.$store.state.numbergroup.numbergroups.data.length; i++)
              {     
                  if(this.$store.state.numbergroup.numbergroups.data[i].grp_type == grptype || grptype == -1)
                  {
                    var val = {"text": this.$store.state.numbergroup.numbergroups.data[i].bez, "value":this.$store.state.numbergroup.numbergroups.data[i].id};
                    ret.numbergroups.push(val);
                    if(this.$store.state.numbergroup.numbergroups.data[i].isDefault)
                    {
                        ret.default = this.$store.state.numbergroup.numbergroups.data[i].id;
                    }
                  }
              }        
            }
            return ret;
        },
        GetListOfProductgroupsForSelect(){
            var ret = [];
            ret.push({"text": "- - - - -", "value":-1});
            
            if(this.$store.state.productgroup.productgroups.data != null)
            {
              for (let i = 0; i < this.$store.state.productgroup.productgroups.data.length; i++)
              {     
                var val = {"text": this.$store.state.productgroup.productgroups.data[i].groupname, "value":this.$store.state.productgroup.productgroups.data[i].id};
                ret.push(val);
              }        
            }
            return ret;
        }, 
        GetTimeModelsForSelect(){
            var ret = [];
            ret.push({"text": "- - - - -", "value":0});
            
            if(this.$store.state.timerec.timemodels.data != null)
            {
              for (let i = 0; i < this.$store.state.timerec.timemodels.data.length; i++)
              {     
                var val = {"text": this.$store.state.timerec.timemodels.data[i].name, "value":this.$store.state.timerec.timemodels.data[i].id};
                ret.push(val);
              }        
            }
            return ret;
        },
        GetCurrencyCountryByOrderFk(order_fk)
        {
            var ret = {currency_fk:-1,country_fk:-1};

            if(this.$store.state.accounting.orders.data != null && this.$store.state.accounting.offers.data != null)
            {
                for(var i = 0; i < this.$store.state.accounting.orders.data.length; i++)
                {
                    var order = this.$store.state.accounting.orders.data[i];
                    if(order.id == order_fk)
                    {
                        for(var j = 0; j < this.$store.state.accounting.offers.data.length; j++)
                        {
                            var offer = this.$store.state.accounting.offers.data[j];
                            if(offer.id == order.offer_fk)
                            {
                                ret.currency_fk = offer.currency_fk;
                                ret.country_fk = offer.country_fk;
                                break;
                            }
                        }
                        break;
                    }
                }                
            }
            return ret;
        },

        GetListOfDeliveryConditionsForSelect(country_fk,currency_fk)
        {
            var ret = [];
            ret.push({"text": "- - - - -", "value":-1});
            
            if(this.$store.state.deliverycondition.deliveryconditions.data != null)
            {
                var conditions = this.$store.state.deliverycondition.deliveryconditions.data;
              for (let i = 0; i < conditions.length; i++)
              {     
                  if(conditions[i].country_fk == country_fk && conditions[i].currency_fk == currency_fk)
                  {
                    var val = {"text": conditions[i].name, "value":conditions[i].id};
                    ret.push(val);
                  }
              }        
            }
            return ret;
        },  
        GetNameOfDeliveryCondition(deliverycondition_fk)
        {
            let ret = "";
            if(this.$store.state.deliverycondition.deliveryconditions.data != null)
            {
                let conditions = this.$store.state.deliverycondition.deliveryconditions.data;
                for (let i = 0; i < conditions.length; i++)
                {     
                    if(conditions[i].id == deliverycondition_fk)
                    {
                        ret = conditions[i].name;
                        break;
                    }
                }        
            }
            return ret;
        },        
        GetListOfPaymentConditionsForSelect()
        {
            let ret = [];
            ret.push({"text": "- - - - -", "value":-1});
            
            if(this.$store.state.paymentcondition.paymentconditions.data != null)
            {
                let conditions = this.$store.state.paymentcondition.paymentconditions.data;
                for (let i = 0; i < conditions.length; i++)
                {     
                    let val = {"text": conditions[i].name, "value":conditions[i].id};
                    ret.push(val);
                }        
            }
            return ret;
        },
        GetNameOfPaymentConditions(paymentcondition_fk)
        {
            let ret = "";
            if(this.$store.state.paymentcondition.paymentconditions.data != null)
            {
                let conditions = this.$store.state.paymentcondition.paymentconditions.data;
                for (let i = 0; i < conditions.length; i++)
                {     
                    if(conditions[i].id == paymentcondition_fk)
                    {
                        ret = conditions[i].name;
                        break;
                    }
                }        
            }
            return ret;
        },
        GetListOfSpecialDiscountsForSelect()
        {
            let ret = [];
            ret.push({"text": "- - - - -", "value":0});
            
            if(this.$store.state.specialdiscount.specialdiscounts.data != null)
            {
                let conditions = this.$store.state.specialdiscount.specialdiscounts.data;
                for (let i = 0; i < conditions.length; i++)
                {     
                    let val = {"text": conditions[i].name, "value":conditions[i].id};
                    ret.push(val);
                }        
            }
            return ret;
        },
        GetNameOfSpecialDiscount(fk)
        {
            let ret = "- - - - -";
            if(this.$store.state.specialdiscount.specialdiscounts.data != null)
            {
                let conditions = this.$store.state.specialdiscount.specialdiscounts.data;
                for (let i = 0; i < conditions.length; i++)
                {     
                    if(conditions[i].id == fk)
                    {
                        ret = conditions[i].name;
                        break;
                    }
                }        
            }
            return ret;
        },        
        GetNameOfNumbergroup(id)
        {
            var ret = "";
            if(this.$store.state.numbergroup.numbergroups.data != null)
            {
              for (let i = 0; i < this.$store.state.numbergroup.numbergroups.data.length; i++)
              {     
                  if(this.$store.state.numbergroup.numbergroups.data[i].id == id)
                  {
                    ret = this.$store.state.numbergroup.numbergroups.data[i].bez;
                    break;
                  }
              }        
            }
            return ret;
        },
        GetNameOfProductgroup(id)
        {
            var ret = "";
            if(this.$store.state.productgroup.productgroups.data != null)
            {
              for (let i = 0; i < this.$store.state.productgroup.productgroups.data.length; i++)
              {     
                  if(this.$store.state.productgroup.productgroups.data[i].id == id)
                  {
                    ret = this.$store.state.productgroup.productgroups.data[i].groupname;
                    break;
                  }
              }        
            }
            return ret;
        },
        GetListOfLanguagesForSelect(){
            var ret = [];
            ret.push({"text": "- - - - -", "value":-1});
            if(this.$store.state.language.languages.data != null)
            {
                for (let i = 0; i < this.$store.state.language.languages.data.length; i++)
                {
                var txt = this.$store.state.language.languages.data[i].name_full + " ("+this.$store.state.language.languages.data[i].name_short+")";
                    var val = {"text": txt, "value":this.$store.state.language.languages.data[i].id};
                    ret.push(val);
                }        
            }
            return ret;
        },
        GetListOfDebitorsForSelect(){
            var ret = [];
            if(this.$store.state.debitor.debdropdown.data != null)
            {
              for (let i = 0; i < this.$store.state.debitor.debdropdown.data.length; i++)
              {
                  var val = {"text": this.$store.state.debitor.debdropdown.data[i].name, "value":this.$store.state.debitor.debdropdown.data[i].id};
                  ret.push(val);
              }        
            }
            return ret;
        },
        GetListOfDebitorObjectsForSelect(){
            var ret = [];
            if(this.$store.state.debitorobject.debitorobjectsMin.data != null)
            {
              for (let i = 0; i < this.$store.state.debitorobject.debitorobjectsMin.data.length; i++)
              {
                  var val = {"text": this.$store.state.debitorobject.debitorobjectsMin.data[i].name, "value":this.$store.state.debitorobject.debitorobjectsMin.data[i].id};
                  ret.push(val);
              }        
            }
            return ret;
        },
        GetListOfCountriesForSelect(){
            var ret = [];
            ret.push({"text": "- - - - -", "value":-1});
            
            if(this.$store.state.country.countries.data != null)
            {
              for (let i = 0; i < this.$store.state.country.countries.data.length; i++)
              {
                  var val = {"text": this.$store.state.country.countries.data[i].name + " ("+this.$store.state.country.countries.data[i].kuerzel+")", "value":this.$store.state.country.countries.data[i].id};
                  ret.push(val);
              }        
            }
            return ret;
        },
        
        GetListOfCurrenciesForSelect(){
            var ret = [];
            ret.push({"text": "- - - - -", "value":-1});
            
            if(this.$store.state.currencies.currencies.data != null)
            {
              for (let i = 0; i < this.$store.state.currencies.currencies.data.length; i++)
              {
                  var val = {"text": this.$store.state.currencies.currencies.data[i].name + " ("+this.$store.state.currencies.currencies.data[i].kuerzel+" | "+this.$store.state.currencies.currencies.data[i].symbol+")", "value":this.$store.state.currencies.currencies.data[i].id};
                  ret.push(val);
              }        
            }
            return ret;
        },
        GetListOfProductVariantsForSelect(){
            var ret = [];
            ret.push({"text": "- - - - -", "value":-1});
            
            if(this.$store.state.productvariant.productvariants.data != null)
            {
              for (let i = 0; i < this.$store.state.productvariant.productvariants.data.length; i++)
              {
                  var val = {"text": this.$store.state.productvariant.productvariants.data[i].bez + " ("+this.$store.state.productvariant.productvariants.data[i].label_short+")", "value":this.$store.state.productvariant.productvariants.data[i].id};
                  ret.push(val);
              }        
            }
            return ret;
          },
          GetMwStById(mwst_fk){
            var ret = null;
            if(mwst_fk > 0)
            {
                if(this.$store.state.country.mwst_all.data != null)
                {
                    for (let i = 0; i < this.$store.state.country.mwst_all.data.length; i++)
                    {
                        if(this.$store.state.country.mwst_all.data[i].id == mwst_fk)
                        {
                            ret = this.$store.state.country.mwst_all.data[i];
                            break;
                        }
                    }        
                }
            }
            return ret;
        },
        GetListOfMwstForSelect(country_fk){
            var ret = [];
            ret.push({"text": "- - - - -", "value":-1});

            if(this.$store.state.country.mwst_all.data != null)
            {
              for (let i = 0; i < this.$store.state.country.mwst_all.data.length; i++)
              {
                  if(this.$store.state.country.mwst_all.data[i].country_fk == country_fk)
                  {
                    var val = {"text": this.$store.state.country.mwst_all.data[i].bez, "value":this.$store.state.country.mwst_all.data[i].id};
                    ret.push(val);
                  }
              }        
            }
            return ret;
        },
        GetListOfProductsForSelect(){
            var ret = [];
            ret.push({"text": "- - - - -", "value":-1});

            if(this.$store.state.products.productAdminList.data != null)
            {
              for (let i = 0; i < this.$store.state.products.productAdminList.data.length; i++)
              {
                var val = {"text": this.$store.state.products.productAdminList.data[i].bez, "value":this.$store.state.products.productAdminList.data[i].id};
                ret.push(val);
                  
              }        
            }
            return ret;
        },
        GetListOfCompaniesOrderPossibleForSelect(){
            var ret = [];
            ret.push({"text": "- - - - -", "value":-1});

            if(this.$store.state.company.companiesOrderPossible.data != null)
            {
              for (let i = 0; i < this.$store.state.company.companiesOrderPossible.data.length; i++)
              {
                var val = {"text": this.$store.state.company.companiesOrderPossible.data[i].name, "value":this.$store.state.company.companiesOrderPossible.data[i].id};
                ret.push(val);
              }        
            }
            return ret;
        },
        GetNameOfCompanyFromOrderPossible(id){
            var ret = "";
            if(this.$store.state.company.companiesOrderPossible.data != null)
            {
              for (let i = 0; i < this.$store.state.company.companiesOrderPossible.data.length; i++)
              {
                if(this.$store.state.company.companiesOrderPossible.data[i].id == id)
                {
                    ret = this.$store.state.company.companiesOrderPossible.data[i].name;
                    break;
                }
              }        
            }
            return ret;
        },
        GetProductVariant(id)
        {
            var ret = null;

            if(this.$store.state.productvariant.productvariantsWithHeadquater.data != null)
            {
              for (let i = 0; i < this.$store.state.productvariant.productvariantsWithHeadquater.data.length; i++)
              {
                  if(this.$store.state.productvariant.productvariantsWithHeadquater.data[i].id == id)
                  {
                    ret = this.$store.state.productvariant.productvariantsWithHeadquater.data[i];
                    break;
                  }
              }        
            }
            return ret;
        },
        GetProductVariantGroup(id)
        {
            var ret = null;

            if(this.$store.state.productvariant.productvariantgroupsWithHeadquater.data != null)
            {
              for (let i = 0; i < this.$store.state.productvariant.productvariantgroupsWithHeadquater.data.length; i++)
              {
                  if(this.$store.state.productvariant.productvariantgroupsWithHeadquater.data[i].id == id)
                  {
                    ret = this.$store.state.productvariant.productvariantgroupsWithHeadquater.data[i];
                    break;
                  }
              }        
            }
            return ret;
        },
        GetMyOwnUserGuid()
        {
          return this.$store.state.users.usermin.userGuid;
        },
        GetBezOfMwst(id)
        {
            var retVal = "";
            if(this.$store.state.country.mwst_all.data != null)
            {
                for (let i = 0; i < this.$store.state.country.mwst_all.data.length; i++)
                {
                    if(this.$store.state.country.mwst_all.data[i].id == id)
                    {
                        retVal = this.$store.state.country.mwst_all.data[i].bez;
                        break;
                    }
                } 
            }
            return retVal;
        },
        GetBezOfProduct(id)
        {
            var retVal = "";
            if(this.$store.state.products.productAdminList.data != null)
            {
                for (let i = 0; i < this.$store.state.products.productAdminList.data.length; i++)
                {
                    if(this.$store.state.products.productAdminList.data[i].id == id)
                    {
                        retVal = this.$store.state.products.productAdminList.data[i].bez;
                        break;
                    }
                } 
            }
            return retVal;
        },
        GetNameOfLanguage(id)
        {
            var retVal = "";
            if(this.$store.state.language.languages.data != null)
            {
                for (let i = 0; i < this.$store.state.language.languages.data.length; i++)
                {
                    if(this.$store.state.language.languages.data[i].id == id)
                    {
                        retVal = this.$store.state.language.languages.data[i].name_full + " ("+this.$store.state.language.languages.data[i].name_short+")";
                        break;
                    }
                }  
            }
            return retVal;
        },
        GetNameShortOfLanguage(id)
        {
            var retVal = "";
            if(this.$store.state.language.languages.data != null)
            {
                for (let i = 0; i < this.$store.state.language.languages.data.length; i++)
                {
                    if(this.$store.state.language.languages.data[i].id == id)
                    {
                        retVal = this.$store.state.language.languages.data[i].name_short;
                        break;
                    }
                }  
            }
            return retVal;
        },        
        GetLocalFormatOfLanguage(id)
        {
            var retVal = "";
            if(this.$store.state.language.languages.data != null)
            {
                for (let i = 0; i < this.$store.state.language.languages.data.length; i++)
                {
                    if(this.$store.state.language.languages.data[i].id == id)
                    {
                        retVal = this.$store.state.language.languages.data[i].language_format;
                        break;
                    }
                }  
            }
            return retVal;
        },
        GetNameOfCountry(id)
        {
            var retVal = "";
            if(this.$store.state.country.countries.data != null)
            {
                for (let i = 0; i < this.$store.state.country.countries.data.length; i++)
                {
                    if(this.$store.state.country.countries.data[i].id == id)
                    {
                        retVal = this.$store.state.country.countries.data[i].name;
                        break;
                    }
                } 
            }
            return retVal;
        },
        GetNameOfCurrency(id)
        {
            var retVal = "";

            var cur = this.GetCurrencyById(id);

            if(typeof(cur) != 'undefined')
            {
              retVal = cur.name + " ("+cur.kuerzel+" | "+cur.symbol+")"; 
            }
            
            return retVal;
        }, 
        GetCurrencyById(id)
        {
            var retVal = "";
            if(this.$store.state.currencies.currencies.data != null)
            {
                for (let i = 0; i < this.$store.state.currencies.currencies.data.length; i++)
                {
                    if(this.$store.state.currencies.currencies.data[i].id == id)
                    {
                        retVal = this.$store.state.currencies.currencies.data[i];
                        break;
                    }
                } 
            }
            return retVal;
        }, 
        GetCurrencyString(currency_fk,value)
        {
            var retVal = "";
            var cur = this.GetCurrencyById(currency_fk);
            if(typeof(cur) != 'undefined')
            {
                retVal = cur.symbol+" "+value.toFixed(2);
            }
            return retVal;
        },  
        GetAddressAsString(id)
        {
            var ret = "";
            if(this.$store.state.address.addressListUser.data != null)
            {
                for (let i = 0; i < this.$store.state.address.addressListUser.data.length; i++)
                {
                    if(this.$store.state.address.addressListUser.data[i].id == id)
                    {
                        ret = this.$store.state.address.addressListUser.data[i].street1+", "+this.$store.state.address.addressListUser.data[i].plz+" - "+this.$store.state.address.addressListUser.data[i].city;
                        break;
                    }
                }
            }

            return ret;
        },
        OwnerTypeToIconName(type)
        {
            var retVal = "";
            switch(type)
            {
                case ownertype.USER:
                    // user
                    retVal = "person";
                    break;
                case ownertype.COMPANY:
                    // company
                    retVal = "apartment";
                    break;
                case ownertype.DEBITOR:
                    // debitor (kunde)
                    retVal = "account_box";
                    break;
                case ownertype.CREDITOR:
                    // creditor (lieferant)
                    retVal = "local_shipping";
                    break;                   
                default:
                    retVal = "help";
                    break;
            }
            return retVal;
        },
        CalcBrutto(mwst_proz, netto)
        {
            var mwst_val = Number(mwst_proz) * Number(netto) / 100;
            return this.Round(netto + mwst_val);
        },
        DecimalToString(val,cur,lang)
        {
            var curObj = {
                style: "currency",
                currency: cur
              }
            var num = Number(val);
            return num.toLocaleString(lang,curObj);
        },
        Round(num)
        {
            return Math.round((num + Number.EPSILON) * 100) / 100;
        },
        ValidateEmail(mail)
        {
                  // eslint-disable-next-line 
            var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
            if(mail.match(reg))
                return true;
            else
                return false;
        },
        ValidatePhone(phone)
        {
                  // eslint-disable-next-line 
            var reg = /^[0][0-9]{2}[\s][0-9]{1,6}[\s][0-9]{4,9}[-]?[0-9]{0,3}$/
            if(phone.match(reg))
                return true;
            else
                return false;
        },
        ContainNoNumbers(text)
        {
            if(text.match("^[^0-9]*$"))
                return true;
            else
                return false;
        },
        ContainNumbersOnly(text)
        {
            if(text.match("^[0-9]*$"))
                return true;
            else
                return false;
        },
        setFocusOnInput(inputName)
        {
            let inputEl = this.$refs[inputName].$el.querySelector('input');
            if(typeof(inputEl) != 'undefined')
            {
              inputEl.focus();
            }
        },
        GetNameFromData(debitor)
        {
          var text = "";
          if(typeof(debitor) != 'undefined' && debitor != null)
          {
            if(typeof(debitor.titel_pre) != 'undefined')
            {
              text = debitor.titel_pre + ' ';
            }
            text = text + debitor.firstname;
            
            if(typeof(debitor.secondname) != 'undefined')
            {
              text = text + ' ' +debitor.secondname + ' ';
            }
            text = text + debitor.lastname;
            if(typeof(debitor.titel_post) != 'undefined' && debitor.titel_post.length > 0)
            {
              text = text + ', '+debitor.titel_post;
            }
          }
          return text;
        },
        GetFirmaFromData(debitor)
        {
          var text = "";
          if(typeof(debitor) != 'undefined' && debitor != null)
          {
            if(typeof(debitor.company) != 'undefined' && debitor.company.length > 0)
            {
              text = this.$t('COM.GENDER.COMPANY') +' '+ debitor.company + ', ' + debitor.uid;
            }
          }
          return text;
        },
        GetNameOrCompanyFromDebitor(debitor)
        {
            var text = "";
            if(typeof(debitor) != 'undefined' && debitor != null)
            {
                text = this.GetFirmaFromData(debitor);

                if(text.length == 0)
                {
                    text = this.GetNameFromData(debitor);
                }
            }
            return text;
        },
        GetRalById(id)
        {
            var retVal = null;
            if(typeof(this.$store.state.ralcolor) != 'undefined')
            {
                if(this.$store.state.ralcolor.ralcolors.data != null)
                {
                    var ralCol = this.$store.state.ralcolor.ralcolors.data;
                    for(var i = 0; i < ralCol.length; i++)
                    {
                        if(ralCol[i].id == id)                     
                        {
                            retVal = ralCol[i];
                            break;
                        }
                    }
                }
            }
            return retVal;
        },
        GetRalDefault(){
            var retVal = {hex:'#000000',ral:"not defined"};
            if(typeof(this.$store.state.ralcolor) != 'undefined')
            {
                if(this.$store.state.ralcolor.ralcolors.data != null)
                {
                    var ralCol = this.$store.state.ralcolor.ralcolors.data;
                    for(var i = 0; i < ralCol.length; i++)
                    {
                        if(ralCol[i].isStd == true)
                        {
                            retVal = ralCol[i];
                            break;
                        }
                    }
                }
            }
            return retVal;
        },
        GetMotifById(id,fallback)
        {
            var retVal = null;
            var first = null;
            if(typeof(this.$store.state.motif) != 'undefined')
            {
                if(this.$store.state.motif.motifs.data != null)
                {
                    var motifs = this.$store.state.motif.motifs.data;
                    for(var i =0; i < motifs.length; i++)
                    {
                        if(!this.IsNotNull())
                        {
                            first = motifs[i];
                        }
                        if(motifs[i].id == id)
                        {
                            retVal = motifs[i];
                            break;
                        }
                    }
    
                    if(!this.IsNotNull(retVal) && fallback == true)
                    {
                        retVal = first;
                    }
                }
            }
            return retVal;
        },
        GetEigenmotif(){
            var retVal = null;
            if(typeof(this.$store.state.motif) != 'undefined')
            {
                if(this.$store.state.motif.motifs.data != null)
                {
                    var motifs = this.$store.state.motif.motifs.data;
                    for(var i =0; i < motifs.length; i++)
                    {
                        if(motifs[i].isEigen == true)
                        {
                            retVal = motifs[i];
                            break;
                        }
                    }
                }
            }
            return retVal;
        },
        GetCompanyGroupsForSelect(){
            var ret = [];
            ret.push({"text": "- - - - -", "value":-1});

            if(this.$store.state.companygroup.companygroups.data != null)
            {
              for (let i = 0; i < this.$store.state.companygroup.companygroups.data.length; i++)
              {
                var val = {"text": this.$store.state.companygroup.companygroups.data[i].name, "value":this.$store.state.companygroup.companygroups.data[i].id};
                ret.push(val);
              }        
            }
            return ret;
        },
        GetCompanyGroupValue(id,prop){
            var ret = "";
            if(this.$store.state.companygroup.companygroups.data != null)
            {
                for (let i = 0; i < this.$store.state.companygroup.companygroups.data.length; i++)
                {
                    if(this.$store.state.companygroup.companygroups.data[i].id == id)
                    {
                        ret = this.$store.state.companygroup.companygroups.data[i][prop];
                        break;
                    }
                }
            }
            return ret;
        },
        GetMonthName(month){
            var retVal = "FAIL";
            switch(month)
            {
                case 1:
                    retVal = this.$t('COM.JAENNER');
                    break;
                case 2:
                    retVal = this.$t('COM.FEBRUAR');
                    break;
                case 3:
                    retVal = this.$t('COM.MAERZ');
                    break;
                case 4:
                    retVal = this.$t('COM.APRIL');
                    break;
                case 5:
                    retVal = this.$t('COM.MAI');
                    break;
                case 6:
                    retVal = this.$t('COM.JUNI');
                    break;
                case 7:
                    retVal = this.$t('COM.JULI');
                    break;
                case 8:
                    retVal = this.$t('COM.AUGUST');
                    break; 
                case 9:
                    retVal = this.$t('COM.SEPTEMBER');
                    break;
                case 10:
                    retVal = this.$t('COM.OKTOBER');
                    break;
                case 11:
                    retVal = this.$t('COM.NOVEMBER');
                    break;
                case 12:
                    retVal = this.$t('COM.DEZEMBER');
                    break;                                                                                  
            }
            return retVal;
        }
  }