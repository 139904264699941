
import { rightService } from '../services';
import baseMethods from './base';

const initialState = { grouprights: {data: null,status:{ loading: false,ready: false }},
                       companygrouprights: {data: null,status:{ loading: false,ready: false }}};

export const right = {
    namespaced: true,
    state: initialState,
    actions: {
        getRightsForGroup({ commit },id )
        {
            commit('getRightsForGroupRequest' );
            return new Promise((resolve, reject) => {

                rightService.getRightsForGroup(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getRightsForGroupSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getRightsForGroupFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getRightsForCompanyGroup({ commit },id )
        {
            commit('getRightsForCompanyGroupRequest' );
            return new Promise((resolve, reject) => {

                rightService.getRightsForCompanyGroup(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getRightsForCompanyGroupSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getRightsForCompanyGroupFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        updateRightsForCompanyGroup({ commit },data )
        {
            commit('updateRightsForCompanyGroupRequest' );
            return new Promise((resolve, reject) => {

                rightService.updateRightsForCompanyGroup(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('updateRightsForCompanyGroupSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('updateRightsForCompanyGroupFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        updateRightsForGroup({ commit },data )
        {
            commit('updateRightsForGroupRequest' );
            return new Promise((resolve, reject) => {

                rightService.updateRightsForGroup(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('updateRightsForGroupSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('updateRightsForGroupFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }
        
    },
    mutations: {
        getRightsForGroupRequest(state)
        {
            state.grouprights = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getRightsForGroupSuccess(state, data)
        {
            state.grouprights = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getRightsForGroupFailure(state)
        {
            state.grouprights = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getRightsForCompanyGroupRequest(state)
        {
            state.companygrouprights = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getRightsForCompanyGroupSuccess(state, data)
        {
            state.companygrouprights = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getRightsForCompanyGroupFailure(state)
        {
            state.companygrouprights = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        updateRightsForCompanyGroupRequest(state)
        {
            state.companygrouprights.status = { loading: true,ready: false };
        },
        updateRightsForCompanyGroupSuccess(state)
        {
            state.companygrouprights.status = { loading: false,ready: true };
        },
        updateRightsForCompanyGroupFailure(state)
        {
            state.companygrouprights.status = { loading: false,ready: false };
        },
        updateRightsForGroupRequest(state)
        {
            state.grouprights.status = { loading: true,ready: false };
        },
        updateRightsForGroupSuccess(state)
        {
            state.grouprights.status = { loading: false,ready: true };
        },
        updateRightsForGroupFailure(state)
        {
            state.grouprights.status = { loading: false,ready: false };
        }
        
    }
}
