
import { debitorobjectService } from '../services';
import baseMethods from './base';

const initialState = {  debitorobjects: {data: null,status:{ loading: false,ready: false }},
                        debitorobjectsMin: {data: null,status:{ loading: false,ready: false }},
                        singleDebitorobject: {data: null,status:{ loading: false,ready: false }},
                        rooms: {data: null,status:{ loading: false,ready: false }},
                        attachments: {data: null,status:{ loading: false,ready: false }}
                     };

export const debitorobject = {
    namespaced: true,
    state: initialState,
    actions: {
        getDebitorObjects({ commit }, id )
        {
            commit('getDebitorObjectsRequest' );
            return new Promise((resolve, reject) => {

                debitorobjectService.getDebitorObjects(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDebitorObjectsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDebitorObjectsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getDebitorObject({ commit }, id )
        {
            commit('getDebitorObjectRequest' );
            return new Promise((resolve, reject) => {

                debitorobjectService.getDebitorObject(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDebitorObjectSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDebitorObjectFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getDebitorObjectsMin({ commit }, id )
        {
            commit('getDebitorObjectsMinRequest' );
            return new Promise((resolve, reject) => {

                debitorobjectService.getDebitorObjectsMin(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDebitorObjectsMinSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDebitorObjectsMinFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveDebitorObject({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.debitorobject.id <= 0)
                {
                    commit('insertDebitorObjectRequest' );
                    debitorobjectService.insertDebitorObject(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertDebitorObjectSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertDebitorObjectFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateDebitorObjectRequest' );
                    debitorobjectService.updateDebitorObject(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateDebitorObjectSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateDebitorObjectFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                
            })
        },
        deleteDebitorObject({ commit }, id )
        {
            commit('deleteDebitorObjectRequest' );
            return new Promise((resolve, reject) => {

                debitorobjectService.deleteDebitorObject(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteDebitorObjectSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteDebitorObjectFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteLieferAdr({ commit },id ){
            commit('deleteLieferAdrRequest' );
            return new Promise((resolve, reject) => {

                debitorobjectService.deleteLieferAdr(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteLieferAdrSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteLieferAdrFailure');
                            reject(response.statusText);
                        }
                    }
                );

            }) 
        },
        saveDebitorObjectRoom({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertDebitorObjectRoomRequest' );
                    debitorobjectService.insertDebitorObjectRoom(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertDebitorObjectRoomSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertDebitorObjectRoomFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateDebitorObjectRoomRequest' );
                    debitorobjectService.updateDebitorObjectRoom(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateDebitorObjectRoomSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateDebitorObjectRoomFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                
            })
        },
        deleteDebitorObjectRoom({ commit }, id )
        {
            commit('deleteDebitorObjectRoomRequest' );
            return new Promise((resolve, reject) => {

                debitorobjectService.deleteDebitorObjectRoom(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteDebitorObjectRoomSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteDebitorObjectRoomFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getDebitorObjectRooms({ commit }, id )
        {
            commit('getDebitorObjectRoomsRequest' );
            return new Promise((resolve, reject) => {

                debitorobjectService.getDebitorObjectRooms(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDebitorObjectRoomsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDebitorObjectRoomsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteDebitorObjectAttachment({ commit }, id )
        {
            commit('deleteDebitorObjectAttachmentRequest' );
            return new Promise((resolve, reject) => {

                debitorobjectService.deleteDebitorObjectAttachment(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteDebitorObjectAttachmentSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteDebitorObjectAttachmentFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getDebitorObjectAttachments({ commit }, id )
        {
            commit('getDebitorObjectAttachmentsRequest' );
            return new Promise((resolve, reject) => {

                debitorobjectService.getDebitorObjectAttachments(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDebitorObjectAttachmentsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDebitorObjectAttachmentsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        updateDebitorObjectAttachment({ commit }, data )
        {
            commit('updateDebitorObjectAttachmentRequest' );
            return new Promise((resolve, reject) => {

                debitorobjectService.updateDebitorObjectAttachment(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('updateDebitorObjectAttachmentSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('updateDebitorObjectAttachmentFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        downloadPdfAttachment({ commit },id )
        {
            return new Promise((resolve, reject) => {

                debitorobjectService.downloadPdfAttachment(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
    },
    mutations: {
        getDebitorObjectsRequest(state)
        {
            state.debitorobjects = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDebitorObjectsSuccess(state, data)
        {
            state.debitorobjects = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDebitorObjectsFailure(state)
        {
            state.debitorobjects = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getDebitorObjectRequest(state)
        {
            state.singleDebitorobject = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDebitorObjectSuccess(state, data)
        {
            state.singleDebitorobject = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDebitorObjectFailure(state)
        {
            state.singleDebitorobject = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getDebitorObjectsMinRequest(state)
        {
            state.debitorobjectsMin = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDebitorObjectsMinSuccess(state, data)
        {
            state.debitorobjectsMin = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDebitorObjectsMinFailure(state)
        {
            state.debitorobjectsMin = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        insertDebitorObjectRequest(state)
        {
            state.debitorobjects.status = { loading: true,ready: false };
        },
        insertDebitorObjectSuccess(state)
        {
            state.debitorobjects.status = { loading: false,ready: true };
        },
        insertDebitorObjectFailure(state)
        {
            state.debitorobjects.status = { loading: false,ready: false };
        },
        updateDebitorObjectRequest(state)
        {
            state.debitorobjects.status = { loading: true,ready: false };
        },
        updateDebitorObjectSuccess(state)
        {
            state.debitorobjects.status = { loading: false,ready: true };
        },
        updateDebitorObjectFailure(state)
        {
            state.debitorobjects.status = { loading: false,ready: false };
        },
        deleteDebitorObjectRequest(state)
        {
            state.debitorobjects.status = { loading: true,ready: false };
        },
        deleteDebitorObjectSuccess(state)
        {
            state.debitorobjects.status = { loading: false,ready: true };
        },
        deleteDebitorObjectFailure(state)
        {
            state.debitorobjects.status = { loading: false,ready: false };
        },
        deleteLieferAdrRequest(state)
        {
            state.debitorobjects.status = { loading: true,ready: false };
        },
        deleteLieferAdrSuccess(state)
        {
            state.debitorobjects.status = { loading: false,ready: true };
        },
        deleteLieferAdrFailure(state)
        {
            state.debitorobjects.status = { loading: false,ready: false };
        },
        getDebitorObjectRoomsRequest(state)
        {
            state.rooms = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDebitorObjectRoomsSuccess(state, data)
        {
            state.rooms = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDebitorObjectRoomsFailure(state)
        {
            state.rooms = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        insertDebitorObjectRoomRequest(state)
        {
            state.rooms.status = { loading: true,ready: false };
        },
        insertDebitorObjectRoomSuccess(state)
        {
            state.rooms.status = { loading: false,ready: true };
        },
        insertDebitorObjectRoomFailure(state)
        {
            state.rooms.status = { loading: false,ready: false };
        },
        updateDebitorObjectRoomRequest(state)
        {
            state.rooms.status = { loading: true,ready: false };
        },
        updateDebitorObjectRoomSuccess(state)
        {
            state.rooms.status = { loading: false,ready: true };
        },
        updateDebitorObjectRoomFailure(state)
        {
            state.rooms.status = { loading: false,ready: false };
        },
        deleteDebitorObjectRoomRequest(state)
        {
            state.rooms.status = { loading: true,ready: false };
        },
        deleteDebitorObjectRoomSuccess(state)
        {
            state.rooms.status = { loading: false,ready: true };
        },
        deleteDebitorObjectRoomFailure(state)
        {
            state.rooms.status = { loading: false,ready: false };
        },
        getDebitorObjectAttachmentsRequest(state)
        {
            state.attachments = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDebitorObjectAttachmentsSuccess(state, data)
        {
            state.attachments = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDebitorObjectAttachmentsFailure(state)
        {
            state.attachments = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        updateDebitorObjectAttachmentRequest(state)
        {
            state.attachments.status = { loading: true,ready: false };
        },
        updateDebitorObjectAttachmentSuccess(state,data)
        {
            state.attachments.status = { loading: false,ready: true };
            for(var i = 0; i < state.attachments.data.length; i++)
            {
                if(state.attachments.data[i].id == data.id)
                {
                    state.attachments.data[i].description = data.description;
                    break;
                }
            }
        },
        updateDebitorObjectAttachmentFailure(state)
        {
            state.attachments.status = { loading: false,ready: false };
        },
        deleteDebitorObjectAttachmentRequest(state)
        {
            state.attachments.status = { loading: true,ready: false };
        },
        deleteDebitorObjectAttachmentSuccess(state,id)
        {
            state.attachments.status = { loading: false,ready: true };
            for(var i = 0; i < state.attachments.data.length; i++)
            {
                if(state.attachments.data[i].id == id)
                {
                    state.attachments.data.splice(i,1);
                    break;
                }
            }
        },
        deleteDebitorObjectAttachmentFailure(state)
        {
            state.attachments.status = { loading: false,ready: false };
        }
    }
}
