import {baseAxios} from '../helper/axiosHelper';

export const mainSettingsService = {
    getMainSettings,
    updateMainSettings
};

function getMainSettings() {

    return baseAxios.get('mainsettings/mainsettings/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateMainSettings(settings) {

    return baseAxios.put('mainsettings/mainsettings/',settings)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}