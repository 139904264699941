
import { ralcolorService } from '../services';
import baseMethods from './base';

const initialState = {  ralcolors: {data: null,status:{ loading: false,ready: false }} };

export const ralcolor = {
    namespaced: true,
    state: initialState,
    actions: {
        getAllRalColors({ commit,dispatch } )
        {
            commit('getAllRalColorsRequest' );
            return new Promise((resolve, reject) => {

                ralcolorService.getAllRalColors().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getAllRalColorsSuccess',response.data);
                            dispatch('maincontainer/setLoadingStatus', {type:"ral",loaded:true},{root: true},{root: true}); 
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getAllRalColorsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }
    },
    mutations: {
        getAllRalColorsRequest(state)
        {
            state.ralcolors = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getAllRalColorsSuccess(state, data)
        {
            state.ralcolors = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getAllRalColorsFailure(state)
        {
            state.ralcolors = {
                data: null,
                status:{ loading: false,ready: false }
            };
        }
    }
}
