import {baseAxios} from '../helper/axiosHelper';

export const pollingService = {
    getPublicBanners,
    getPrivateBanners
};

function getPublicBanners() {

    return baseAxios.get('polling/Banners/public')
    .then((response) =>
    {
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
}

function getPrivateBanners() {

    return baseAxios.get('polling/Banners/private')
    .then((response) =>
    {
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
}