
import { pricelistsService } from '../services';
import baseMethods from './base';

const initialState = {  pricelists: {data: null,status:{ loading: false,ready: false }},
                        pricelistMember: {data: null,status:{ loading: false,ready: false }},
                        valid_pricelists: {data: null,status:{ loading: false,ready: false }}};

export const pricelists = {
    namespaced: true,
    state: initialState,
    actions: {
        getPricelists({ commit } )
        {
            commit('getPricelistsRequest' );
            return new Promise((resolve, reject) => {

                pricelistsService.getPricelists().then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getPricelistsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getPricelistsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deletePricelist({ commit }, id)
        {
            commit('PricelistRequest' );
            return new Promise((resolve, reject) => {
                
                pricelistsService.deletePricelist(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('PricelistSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('PricelistFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        copyPricelist({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                commit('PricelistRequest' );
                pricelistsService.copyPricelist(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('PricelistSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('PricelistFailure');
                            reject(response.statusText);
                        }
                    }
                );
            });
        },
        savePricelist({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('PricelistRequest' );
                    pricelistsService.insertPricelist(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('PricelistSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('PricelistFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('PricelistRequest' );
                    pricelistsService.updatePricelist(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('PricelistSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('PricelistFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        }, 
        getPricelistMember({ commit },id )
        {
            commit('getPricelistMemberRequest' );
            return new Promise((resolve, reject) => {

                pricelistsService.getPricelistMember(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getPricelistMemberSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getPricelistMemberFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        deletePricelistMember({ commit }, id)
        {
            commit('PricelistMemberRequest' );
            return new Promise((resolve, reject) => {
                
                pricelistsService.deletePricelistMember(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('PricelistMemberSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('PricelistMemberFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        getPrices({ commit },data)
        {
            commit('getPricesRequest' );
            return new Promise((resolve, reject) => {
                
                pricelistsService.getPrices(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getPricesSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getPricesFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },     
        savePricelistMember({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('PricelistMemberRequest' );
                    pricelistsService.insertPricelistMember(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('PricelistMemberSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('PricelistMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('PricelistMemberRequest' );
                    pricelistsService.updatePricelistMember(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('PricelistMemberSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('PricelistMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        }           
    },
    mutations: {
        getPricesRequest(state)
        {
            state.valid_pricelists = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getPricesSuccess(state, data)
        {
            state.valid_pricelists = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getPricesFailure(state)
        {
            state.valid_pricelists = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getPricelistsRequest(state)
        {
            state.pricelists = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getPricelistsSuccess(state, data)
        {
            state.pricelists = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getPricelistsFailure(state)
        {
            state.pricelists = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        PricelistRequest(state)
        {
            state.pricelists.status = { loading: true,ready: false };
        },
        PricelistSuccess(state)
        {
            state.pricelists.status = { loading: false,ready: true };
        },
        PricelistFailure(state)
        {
            state.pricelists.status = { loading: false,ready: false };
        },
        getPricelistMemberRequest(state)
        {
            state.pricelistMember = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getPricelistMemberSuccess(state, data)
        {
            state.pricelistMember = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getPricelistMemberFailure(state)
        {
            state.pricelistMember = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        PricelistMemberRequest(state)
        {
            state.pricelistMember.status = { loading: true,ready: false };
        },
        PricelistMemberSuccess(state)
        {
            state.pricelistMember.status = { loading: false,ready: true };
        },
        PricelistMemeberFailure(state)
        {
            state.pricelistMember.status = { loading: false,ready: false };
        },
    }
}
