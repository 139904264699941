
import { bankaccountService } from '../services';
import baseMethods from './base';

const initialState = { bankaccountListUser: {data: null,status:{ loading: false,ready: false }}};

function SetBankAccountVal(state,id,prop,val)
{
    if(state.bankaccountListUser.data != null)
    {
        for (let i = 0; i < state.bankaccountListUser.data.length; i++)
        {
            if(state.bankaccountListUser.data[i].id == id)
            {
                state.bankaccountListUser.data[i][prop] = val;
                break;
            }
        }
    }
}

export const bankaccount = {
    namespaced: true,
    state: initialState,
    actions: {
        getBankAccountListOfGuid({ commit }, data )
        {
            commit('getBankAccountListOfGuidRequest' );
            return new Promise((resolve, reject) => {
                
                bankaccountService.getBankAccountListOfGuid(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getBankAccountListOfGuidSucces',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getBankAccountListOfGuidFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })

            
        },
        getBankAccountListForCompanyAndUser({ commit }, data )
        {
            commit('getBankAccountListOfGuidRequest' );
            return new Promise((resolve, reject) => {
                
                bankaccountService.getBankAccountListForCompanyAndUser(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getBankAccountListOfGuidSucces',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getBankAccountListOfGuidFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })

            
        },
        deleteBankAccount({ commit }, id)
        {
            commit('deleteBankAccountRequest' );
            return new Promise((resolve, reject) => {
                
                bankaccountService.deleteBankAccount(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteBankAccountSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteBankAccountFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveBankAccount({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertBankAccountRequest' );
                    bankaccountService.insertBankAccount(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertBankAccountSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertBankAccountFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateBankAccountRequest' );
                    bankaccountService.updateBankAccount(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateBankAccountSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateBankAccountFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        updateBankAccountListName({ commit }, data)
        {
            commit('updateBankAccountListName',data);
        },
        updateBankAccountListBankName({ commit }, data)
        {
            commit('updateBankAccountListBankName',data);
        },
        updateBankAccountListIban({ commit }, data)
        {
            commit('updateBankAccountListIban',data);
        },
        updateBankAccountListBic({ commit }, data)
        {
            commit('updateBankAccountListBic',data);
        },
        updateBankAccountListNameOwner({ commit }, data)
        {
            commit('updateBankAccountListNameOwner',data);
        },
        updateBankAccountListAccountType({ commit }, data)
        {
            commit('updateBankAccountListAccountType',data);
        },        
        clearBankAccountList({ commit })
        {
          commit('clearBankAccountList');
        },               
    },
    mutations: {
        deleteBankAccountRequest(state)
        {
            state.bankaccountListUser.status = { loading: true,ready: false };
        },
        deleteBankAccountSuccess(state)
        {
            state.bankaccountListUser.status = { loading: false,ready: true };
        },
        deleteBankAccountFailure(state)
        {
            state.bankaccountListUser.status = { loading: false,ready: false };
        },                
        getBankAccountListOfGuidRequest(state)
        {
            state.bankaccountListUser = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getBankAccountListOfGuidSucces(state, data)
        {
            state.bankaccountListUser = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getBankAccountListOfGuidFailure(state)
        {
            state.bankaccountListUser = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        updateBankAccountRequest(state)
        {
            state.bankaccountListUser = {
                status:{ loading: true,ready: false }
            };
        },
        updateBankAccountSuccess(state)
        {
            state.bankaccountListUser = {
                status:{ loading: false,ready: true }
            };
        },
        updateBankAccountFailure(state)
        {
            state.bankaccountListUser = {
                status:{ loading: false,ready: false }
            };
        },
        insertBankAccountRequest(state)
        {
            state.bankaccountListUser = {
                status:{ loading: true,ready: false }
            };
        },
        insertBankAccountSuccess(state)
        {
            state.bankaccountListUser = {
                status:{ loading: false,ready: true }
            };
        },
        insertBankAccountFailure(state)
        {
            state.bankaccountListUser = {
                status:{ loading: false,ready: false }
            };
        },
        updateBankAccountListName(state,data)
        {
            SetBankAccountVal(state,data.id,'name',data.value);
        },
        updateBankAccountListBankName(state,data)
        {
            SetBankAccountVal(state,data.id,'bank_name',data.value);
        },
        updateBankAccountListIban(state,data)
        {
            SetBankAccountVal(state,data.id,'iban',data.value);
        },
        updateBankAccountListBic(state,data)
        {
            SetBankAccountVal(state,data.id,'bic',data.value);
        },
        updateBankAccountListAccountType(state,data)
        {
            SetBankAccountVal(state,data.id,'accountType',data.value);
        },  
        updateBankAccountListNameOwner(state,data)
        {
            SetBankAccountVal(state,data.id,'name_owner',data.value);
        },
        clearBankAccountList(state)
        {
            state.bankaccountListUser.data = null;
        }
    }
}
