const LocalStorageService = (function()
{
    var _service;
    function _getService()
    {
        if(!_service) {
          _service = this;
          return _service
      }
      return _service
    }
    function _setToken(tokenObj)
    {
      localStorage.setItem('authToken', tokenObj.authToken);
      localStorage.setItem('refreshToken', tokenObj.refreshToken);
      localStorage.setItem('resetPassword',tokenObj.resetPwd);
    }
    function _getAuthToken()
    {
      return localStorage.getItem('authToken');
    }
    function _getRefreshToken()
    {
      return localStorage.getItem('refreshToken');
    }
    function _clearToken()
    {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('resetPassword');
    }
    function _getResetPwd()
    {
      return localStorage.getItem('resetPassword');
    } 
    function _clrResetPwd()
    {
      return localStorage.setItem('resetPassword',false);
    }    
   return {
      getService : _getService,
      setToken : _setToken,
      getAuthToken : _getAuthToken,
      getRefreshToken : _getRefreshToken,
      clearToken : _clearToken,
      getResetPwd : _getResetPwd,
      clrResetPwd : _clrResetPwd
    }
})();
   
export default LocalStorageService;