import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import LocalStorageService from '../services/localstorage.service';

const localStorageService = LocalStorageService.getService();

export default {
  install (Vue) {
    // use a new Vue instance as the interface for Vue components to receive/send SignalR events
    // this way every component can listen to events or send new events using this.$questionHub
    const sharedHub = new Vue()
    Vue.prototype.$sharedHub = sharedHub

    // Provide methods to connect/disconnect from the SignalR hub
    let connection = null
    let startedPromise = null
    let manuallyClosed = false

    Vue.prototype.startSignalR = () => {
      
      connection = new HubConnectionBuilder()  
        .withUrl(`${Vue.prototype.$appConfig.hubBaseUrl}/sharedhub`, 
        {
          accessTokenFactory: () =>{
            return localStorageService.getAuthToken();
          } 
        })//, transport: HttpTransportType.WebSockets,skipNegotiation: true})
        .configureLogging(LogLevel.Critical)
        .build()
      // Forward hub events through the event, so we can listen for them in the Vue components
      connection.on('BannerChanged', () => {
        sharedHub.$emit('bannerchanged')
      });
      connection.on('DocumentReady', () => {
        sharedHub.$emit('documentready')
      });      
      connection.on('RefreshMessages', () => {
        sharedHub.$emit('refreshmessages')
      });
      connection.on('SignalRConnected', () => {
        sharedHub.$emit('signalrconnected')
      });
      connection.on('UserTest', () => {
        sharedHub.$emit('UserTest')
      });
      // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
      // Docs recommend listening onclose and handling it there.
      // This is the simplest of the strategies
      function start () {
        startedPromise = connection.start()
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
            //this.console.error('Failed to connect with hub', err)
            return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 10000))
          })
        return startedPromise
      }
      connection.onclose(() => {
        if (!manuallyClosed) start()
      })

      // Start everything
      manuallyClosed = false
      start()
    }
    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return

      manuallyClosed = true
      return startedPromise
        .then(() => connection.stop())
        .then(() => { startedPromise = null })
    }
  }
}