import { userService } from '../services';
import { router } from '../router';
import { i18n } from '../helper/i18nHelper';

const authtokens = JSON.parse(localStorage.getItem('authtokens'));
const initialState = authtokens
    ? { status: { loggedIn: true,logInFail:false }, authtokens,changingPassword:false, changeFail:false }
    : { status: {}, authtokens: null,changingPassword:false, changeFail:false  };

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ dispatch, commit }, { username, password, otp }) {
            commit('loginRequest', { username });

            return new Promise((resolve, reject) => {

                userService.login(username, password,otp).then(
                    authtokens => {
                        if(authtokens.success == true)
                        {
                            commit('loginSuccess', authtokens);
                            dispatch('alert/clear', { root: true },{ root: true });
                            dispatch('users/getUserMinData', { root: true },{ root: true });
                            
                            resolve(authtokens);
                        }
                        else
                        {
                            commit('loginFailure');
                            reject(authtokens);
                        }
                    }
                );

            });
        },
        hello(){
            userService.hello();
        },
        logout({ commit,dispatch }) {
            userService.logout();
            dispatch('maincontainer/setSignalRConnectionStatus', false,{root: true},{root: true}); 
            commit('logout');
            router.push('/login');
        },
        changePassword({commit, dispatch},{password_old, password_new})
        {
            commit('changingPassword');
            userService.changePassword(password_old, password_new)
            .then(
                response => {
                    
                    if(response.status == 200)
                    {
                        commit('changeSuccess');                
                        if(response.data == true)
                        {
                            dispatch('alertqueue/addSuccess', {'titel':i18n.t('COM.SUCCESS.TITEL'),'text':i18n.t('AUTH.NEWPWD.SUCCESS')},{ root: true }); 
                            router.push('/');
                        }
                        else
                        {
                            commit('changeFail');
                            dispatch('alertqueue/addWarning', {'titel':i18n.t('COM.WARNING.TITEL'),'text':i18n.t('AUTH.NEWPWD.FAIL')},{ root: true }); 
                        }
                    }
                    else
                    {
                        commit('changeFail');
                        dispatch('alertqueue/addError', {'titel':i18n.t('COM.ERROR.TITEL'),'text':i18n.t('AUTH.NEWPWD.ERROR')+response.statusText},{ root: true });
                    }
                }
            );            
        }
    },
    mutations: {
        loginRequest(state, authtokens) {
            state.status = { loggingIn: true };
            state.authtokens = authtokens;
        },
        loginSuccess(state, authtokens) {
            state.status = { loggedIn: true };
            state.authtokens = authtokens;
        },
        loginFailure(state) {
            state.status = {logInFail: true};
            state.authtokens = null;
        },
        logout(state) {
            state.status = {};
            state.authtokens = null;
        },
        changingPassword(state)
        {
            state.changingPassword=true;
            state.changeFail=false;
        },
        changeSuccess(state)
        {
            state.changingPassword=false;
            state.changeFail=false;
        },
        changeFail(state)
        {
            state.changingPassword=false;
            state.changeFail=true;
        }

    }
}
