
import { debitorcontactService } from '../services';
import baseMethods from './base';

const initialState = {  debitorcontacts: {data: null,status:{ loading: false,ready: false }},
                        debitorcontactmembers: {data: null,status:{ loading: false,ready: false }},
                        debitorcontact: {data: null,status:{ loading: false,ready: false }}
                     };

export const debitorcontact = {
    namespaced: true,
    state: initialState,
    actions: {
        getMyDebitorContacts({ commit } )
        {
            commit('getMyDebitorContactsRequest' );
            return new Promise((resolve, reject) => {

                debitorcontactService.getMyDebitorContacts().then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getMyDebitorContactsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getMyDebitorContactsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getDebitorContact({ commit }, id )
        {
            commit('getDebitorContactRequest' );
            return new Promise((resolve, reject) => {

                debitorcontactService.getDebitorContact(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDebitorContactSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDebitorContactFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        // eslint-disable-next-line no-unused-vars
        getDebitorContactId({ commit }, data )
        {
            return new Promise((resolve, reject) => {

                debitorcontactService.getDebitorContactId(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        

        getDebitorContactMember({ commit }, id )
        {
            commit('getDebitorContactMemberRequest' );
            return new Promise((resolve, reject) => {

                debitorcontactService.getDebitorContactMember(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDebitorContactMemberSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDebitorContactMemberFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveDebitorContact({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.debitorcontact.id <= 0)
                {
                    commit('insertDebitorContactRequest' );
                    debitorcontactService.insertDebitorContact(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertDebitorContactSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertDebitorContactFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                
            })
        },
        saveDebitorContactMember({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertDebitorContactMemberRequest' );
                    debitorcontactService.insertDebitorContactMember(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertDebitorContactMemberSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertDebitorContactMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateDebitorContactMemberRequest' );
                    debitorcontactService.updateDebitorContactMember(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateDebitorContactMemberSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateDebitorContactMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                
            })
        },
        deleteDebitorContactMember({ commit }, id)
        {
            return new Promise((resolve, reject) => {
                
                commit('deleteDebitorContactMemberRequest' );
                debitorcontactService.deleteDebitorContactMember(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteDebitorContactMemberSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteDebitorContactMemberFailure');
                            reject(response.statusText);
                        }
                    }
                );
            })
        }
    },
    mutations: {
        getMyDebitorContactsRequest(state)
        {
            state.debitorcontacts = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getMyDebitorContactsSuccess(state, data)
        {
            state.debitorcontacts = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getMyDebitorContactsFailure(state)
        {
            state.debitorcontacts = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getDebitorContactRequest(state)
        {
            state.debitorcontact = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDebitorContactSuccess(state, data)
        {
            state.debitorcontact = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDebitorContactFailure(state)
        {
            state.debitorcontact = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        insertDebitorContactRequest(state)
        {
            state.debitorcontacts.status = { loading: true,ready: false };
        },
        insertDebitorContactSuccess(state)
        {
            state.debitorcontacts.status = { loading: false,ready: true };
        },
        insertDebitorContactFailure(state)
        {
            state.debitorcontacts.status = { loading: false,ready: false };
        },
        getDebitorContactMemberRequest(state)
        {
            state.debitorcontactmembers = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDebitorContactMemberSuccess(state, data)
        {
            state.debitorcontactmembers = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDebitorContactMemberFailure(state)
        {
            state.debitorcontactmembers = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        insertDebitorContactMemberRequest(state)
        {
            state.debitorcontactmembers.status = { loading: true,ready: false };
        },
        insertDebitorContactMemberSuccess(state)
        {
            state.debitorcontactmembers.status = { loading: false,ready: true };
        },
        insertDebitorContactMemberFailure(state)
        {
            state.debitorcontactmembers.status = { loading: false,ready: false };
        }, 
        updateDebitorContactMemberRequest(state)
        {
            state.debitorcontactmembers.status = { loading: true,ready: false };
        },
        updateDebitorContactMemberSuccess(state)
        {
            state.debitorcontactmembers.status = { loading: false,ready: true };
        },
        updateDebitorContactMemberFailure(state)
        {
            state.debitorcontactmembers.status = { loading: false,ready: false };
        },
        deleteDebitorContactMemberRequest(state)
        {
            state.debitorcontactmembers.status = { loading: true,ready: false };
        },
        deleteDebitorContactMemberSuccess(state)
        {
            state.debitorcontactmembers.status = { loading: false,ready: true };
        },
        deleteDebitorContactMemberFailure(state)
        {
            state.debitorcontactmembers.status = { loading: false,ready: false };
        },      
    }
}
