
import { motifsService } from '../services';
import baseMethods from './base';

const initialState = {  motifs: {data: null,status:{ loading: false,ready: false }},
                        motifcategories: {data: null,status:{ loading: false,ready: false }},
                        joomlatags: {data: null,status:{ loading: false,ready: false }}};

export const motif = {
    namespaced: true,
    state: initialState,
    actions: {
        getMotifs({ commit,dispatch } )
        {
            commit('getMotifsRequest' );
            return new Promise((resolve, reject) => {

                motifsService.getMotifs().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getMotifsSuccess',response.data);
                            dispatch('maincontainer/setLoadingStatus', {type:"motifs",loaded:true},{root: true},{root: true});

                            response.data.forEach(motif => {
                                
                                motifsService.getPrevDataForMotif(motif.id).then(
                                    resp => {
                                        commit('getMotifsPrevDataSuccess',{id:motif.id,data:resp.data});
                                    });
                            });
                                    
                                    
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getMotifsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getMotifCategories({ commit,dispatch } )
        {
            commit('getMotifCategoriesRequest' );
            return new Promise((resolve, reject) => {

                motifsService.getMotifCategories().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getMotifCategoriesSuccess',response.data);
                            dispatch('maincontainer/setLoadingStatus', {type:"motifcategories",loaded:true},{root: true},{root: true}); 
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getMotifCategoriesFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        getJoomlaTags({ commit } )
        {
            commit('getJoomlaTagsRequest' );
            return new Promise((resolve, reject) => {

                motifsService.getJoomlaTags().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getJoomlaTagsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getJoomlaTagsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        updateJoomlaMotifArticle({ commit }, id)
        {
            commit('updateJoomlaMotifArticleRequest' );
            return new Promise((resolve, reject) => {
                
                motifsService.updateJoomlaMotifArticle(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('updateJoomlaMotifArticleSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('updateJoomlaMotifArticleFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        // eslint-disable-next-line no-unused-vars
        getMotifsFromWebsite({ commit })
        {
            return new Promise((resolve, reject) => {
                
                motifsService.getMotifsFromWebsite().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        

        deleteMotif({ commit }, id)
        {
            commit('deleteMotifRequest' );
            return new Promise((resolve, reject) => {
                
                motifsService.deleteMotif(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteMotifSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteMotifFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        deleteMotifCategory({ commit }, id)
        {
            commit('deleteMotifCategoryRequest' );
            return new Promise((resolve, reject) => {
                
                motifsService.deleteMotifCategory(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteMotifCategorySuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteMotifCategoryFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },     
        saveMotif({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertMotifRequest' );
                    motifsService.insertMotif(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertMotifSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertMotifFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateMotifRequest' );
                    motifsService.updateMotif(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateMotifSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateMotifFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
             
        saveMotifCategory({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertMotifCategoryRequest' );
                    motifsService.insertMotifCategory(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertMotifCategorySuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertMotifCategoryFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateMotifCategoryRequest' );
                    motifsService.updateMotifCategory(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateMotifCategorySuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateMotifCategoryFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
    },
    mutations: {
        getMotifsRequest(state)
        {
            state.motifs = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getMotifsSuccess(state, data)
        {
            state.motifs = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getMotifsPrevDataSuccess(state, data)
        {
            for(var i = 0; i < state.motifs.data.length; i++)
            {
                if(state.motifs.data[i].id == data.id)
                {
                    state.motifs.data[i].img_preview_data = data.data;
                    break;
                }
            }
        },
        getMotifsFailure(state)
        {
            state.motifs = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getMotifCategoriesRequest(state)
        {
            state.motifcategories = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getMotifCategoriesSuccess(state, data)
        {
            state.motifcategories = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getMotifCategoriesFailure(state)
        {
            state.motifcategories = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },

        getJoomlaTagsRequest(state)
        {
            state.joomlatags = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getJoomlaTagsSuccess(state, data)
        {
            state.joomlatags = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getJoomlaTagsFailure(state)
        {
            state.joomlatags = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },

        updateJoomlaMotifArticleRequest(state)
        {
            state.motifs.status = { loading: true,ready: false };
        },
        updateJoomlaMotifArticleSuccess(state)
        {
            state.motifs.status = { loading: false,ready: true };
        },
        updateJoomlaMotifArticleFailure(state)
        {
            state.motifs.status = { loading: false,ready: false };
        },
        deleteMotifRequest(state)
        {
            state.motifs.status = { loading: true,ready: false };
        },
        deleteMotifSuccess(state,id)
        {
            state.motifs.status = { loading: false,ready: true };
            for(var i =0; i < state.motifs.data.length;i++)
            {
                if(state.motifs.data[i].id == id)
                {
                    state.motifs.data.splice(i,1);
                }
            }

        },
        deleteMotifFailure(state)
        {
            state.motifs.status = { loading: false,ready: false };
        },
        deleteMotifCategoryRequest(state)
        {
            state.motifcategories.status = { loading: true,ready: false };
        },
        deleteMotifCategorySuccess(state)
        {
            state.motifcategories.status = { loading: false,ready: true };
        },
        deleteMotifCategoryFailure(state)
        {
            state.motifcategories.status = { loading: false,ready: false };
        },
        insertMotifRequest(state)
        {
            state.motifs.status = { loading: true,ready: false };
        },
        insertMotifSuccess(state)
        {
            state.motifs.status = { loading: false,ready: true };
        },
        insertMotifFailure(state)
        {
            state.motifs.status = { loading: false,ready: false };
        },
        insertMotifCategoryRequest(state)
        {
            state.motifcategories.status = { loading: true,ready: false };
        },
        insertMotifCategorySuccess(state)
        {
            state.motifcategories.status = { loading: false,ready: true };
        },
        insertMotifCategoryFailure(state)
        {
            state.motifcategories.status = { loading: false,ready: false };
        },
        updateMotifRequest(state)
        {
            state.motifs.status = { loading: true,ready: false };
        },
        updateMotifSuccess(state,data)
        {
            state.motifs.status = { loading: false,ready: true };
            for(var i =0; i < state.motifs.data.length;i++)
            {
                if(state.motifs.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.motifs.data[i] = JSON.parse(tmp);
                }
            }
        },
        updateMotifFailure(state)
        {
            state.motifs.status = { loading: false,ready: false };
        },        
        updateMotifCategoryRequest(state)
        {
            state.motifcategories.status = { loading: true,ready: false };
        },
        updateMotifCategorySuccess(state)
        {
            state.motifcategories.status = { loading: false,ready: true };
        },
        updateMotifCategoryFailure(state)
        {
            state.motifcategories.status = { loading: false,ready: false };
        },
    }
}
