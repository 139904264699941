import {baseAxios} from '../helper/axiosHelper';

export const fixtextService = {
    getFixtexts,
    updateFixtext,
    insertFixtext,
    deleteFixtext
};

function getFixtexts() {

    return baseAxios.get('fixtext/Fixtexts/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateFixtext(data) {

    return baseAxios.put('fixtext/fixtext',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertFixtext(data) {

    return baseAxios.post('fixtext/fixtext',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteFixtext(id) {

    return baseAxios.delete('fixtext/fixtext',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}