
import { partnerdiscountService } from '../services';
import baseMethods from './base';

const initialState = {  partnerdiscounts: {data: null,status:{ loading: false,ready: false }}};

export const partnerdiscount = {
    namespaced: true,
    state: initialState,
    actions: {
        getPartnerdiscounts({ commit },data )
        {
            commit('getAllMyPartnerdiscountsRequest' );
            return new Promise((resolve, reject) => {

                partnerdiscountService.getPartnerdiscounts(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getAllMyPartnerdiscountsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getAllMyPartnerdiscountsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deletePartnerdiscount({ commit }, id)
        {
            commit('deletePartnerdiscountRequest' );
            return new Promise((resolve, reject) => {
                
                partnerdiscountService.deletePartnerdiscount(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deletePartnerdiscountSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deletePartnerdiscountFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },      
        savePartnerdiscount({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertPartnerdiscountRequest' );
                    partnerdiscountService.insertPartnerdiscount(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertPartnerdiscountSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertPartnerdiscountFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updatePartnerdiscountRequest' );
                    partnerdiscountService.updatePartnerdiscount(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updatePartnerdiscountSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updatePartnerdiscountFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
    },
    mutations: {
        getAllMyPartnerdiscountsRequest(state)
        {
            state.partnerdiscounts = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getAllMyPartnerdiscountsSuccess(state, data)
        {
            state.partnerdiscounts = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getAllMyPartnerdiscountsFailure(state)
        {
            state.partnerdiscounts = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deletePartnerdiscountRequest(state)
        {
            state.partnerdiscounts.status = { loading: true,ready: false };
        },
        deletePartnerdiscountSuccess(state,id)
        {
            state.partnerdiscounts.status = { loading: false,ready: true };
            for(var i =0; i < state.partnerdiscounts.data.length;i++)
            {
                if(state.partnerdiscounts.data[i].id == id)
                {
                    state.partnerdiscounts.data.splice(i,1);
                }
            }

        },
        deletePartnerdiscountFailure(state)
        {
            state.partnerdiscounts.status = { loading: false,ready: false };
        },
        insertPartnerdiscountRequest(state)
        {
            state.partnerdiscounts.status = { loading: true,ready: false };
        },
        insertPartnerdiscountSuccess(state)
        {
            state.partnerdiscounts.status = { loading: false,ready: true };
        },
        insertPartnerdiscountFailure(state)
        {
            state.partnerdiscounts.status = { loading: false,ready: false };
        },
        updatePartnerdiscountRequest(state)
        {
            state.partnerdiscounts.status = { loading: true,ready: false };
        },
        updatePartnerdiscountSuccess(state,data)
        {
            state.partnerdiscounts.status = { loading: false,ready: true };
            for(var i =0; i < state.partnerdiscounts.data.length;i++)
            {
                if(state.partnerdiscounts.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.partnerdiscounts.data[i] = JSON.parse(tmp);
                }
            }
        },
        updatePartnerdiscountFailure(state)
        {
            state.partnerdiscounts.status = { loading: false,ready: false };
        },        

    }
}
