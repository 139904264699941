import {baseAxios} from '../helper/axiosHelper';

export const debitorService = {
    getMyDebitors,
    insertDebitor,
    updateDebitor,
    deleteDebitor,
    deleteLieferAdr,
    getMyDebitorsMin,
    getDebitorAddr1,
    getDebitor,
    getDebitorByOrder,
    anonymDebitor,
    getDebitorForDocument,
    importDebitorsFromCsv
};

function getMyDebitors() {

    return baseAxios.get('debitors/my/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getMyDebitorsMin() {

    return baseAxios.get('debitors/my/min/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getDebitorForDocument(data) {

    return baseAxios.get('debitors/debitor/document/',{params:{linked_fk:data.linked_fk,type: data.type}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertDebitor(data) {

    return baseAxios.post('debitors/debitor/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateDebitor(data) {

    return baseAxios.put('debitors/debitor/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteDebitor(id) {

    return baseAxios.delete('debitors/debitor/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function anonymDebitor(id) {

    return baseAxios.delete('debitors/debitor/anonym/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteLieferAdr(id){
    return baseAxios.delete('debitors/liefAdr/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getDebitorAddr1(id) {

    return baseAxios.get('debitors/address1/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getDebitor(id) {

    return baseAxios.get('debitors/debitor/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getDebitorByOrder(id) {

    return baseAxios.get('debitors/debitor/order/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function importDebitorsFromCsv(data) {

    return baseAxios.get('debitors/debitor/import/',{params:{partnernr:data.partnernr, numbergroup: data.numbergroup, take:data.take, skip:data.skip}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

