import {baseAxios} from '../helper/axiosHelper';

export const partnerdiscountService = {
    insertPartnerdiscount,
    getPartnerdiscounts,
    updatePartnerdiscount,
    deletePartnerdiscount
};

function insertPartnerdiscount(data) {

    return baseAxios.post('partnerdiscounts/partnerdiscount',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updatePartnerdiscount(data) {

    return baseAxios.put('partnerdiscounts/partnerdiscount',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deletePartnerdiscount(id) {

    return baseAxios.delete('partnerdiscounts/partnerdiscount',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getPartnerdiscounts() {

    return baseAxios.get('partnerdiscounts/partnerdiscount/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
