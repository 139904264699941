import {baseAxios} from '../helper/axiosHelper';

export const deliveryconditionService = {
    insertDeliveryCondition,
    getDeliveryConditions,
    updateDeliveryCondition,
    deleteDeliveryCondition
};

function insertDeliveryCondition(d) {

    return baseAxios.post('deliveryconditions/deliverycondition',d)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateDeliveryCondition(d) {

    return baseAxios.put('deliveryconditions/deliverycondition',d)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteDeliveryCondition(id) {

    return baseAxios.delete('deliveryconditions/deliverycondition',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getDeliveryConditions() {

    return baseAxios.get('deliveryconditions/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
