import {baseAxios} from '../helper/axiosHelper';

export const pricelistsService = {
    getPricelists,
    updatePricelist,
    insertPricelist,
    deletePricelist,
    updatePricelistMember,
    insertPricelistMember,
    deletePricelistMember,
    getPricelistMember,
    getPrices,
    copyPricelist
};

function getPricelists() {

    return baseAxios.get('pricelists/pricelists/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updatePricelist(data) {

    return baseAxios.put('pricelists/Pricelist/',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertPricelist(data) {

    return baseAxios.post('pricelists/Pricelist/',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function copyPricelist(data) {

    return baseAxios.post('pricelists/Pricelist/duplicate/',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deletePricelist(id) {

    return baseAxios.delete('pricelists/Pricelist/',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updatePricelistMember(data) {

    return baseAxios.put('pricelists/member/',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertPricelistMember(data) {

    return baseAxios.post('pricelists/member/',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deletePricelistMember(id) {

    return baseAxios.delete('pricelists/member/',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getPricelistMember(id) {

    return baseAxios.get('pricelists/member/',{params: {pricelist_fk: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getPrices(data) {

    return baseAxios.get('pricelists/prices/',{params: {country_fk: data.country_fk, currency_fk: data.currency_fk, lastYear:data.lastYear}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}