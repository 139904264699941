
import { hubService } from '../services';
import baseMethods from './base';

const initialState = {  test: {data: null,status:{ loading: false,ready: false }}};

export const testcall = {
    namespaced: true,
    state: initialState,
    actions: {
        // eslint-disable-next-line no-unused-vars
        testHub({ commit },data )
        {
            return new Promise((resolve, reject) => {

                hubService.testHub(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        testHubUser({ commit } )
        {
            return new Promise((resolve, reject) => {

                hubService.testHubUser().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
    },
    mutations: {

    }
}
