
import { productcategoryService } from '../services';
import baseMethods from './base';

const initialState = {  productcategories: {data: null,status:{ loading: false,ready: false }}};

export const productcategory = {
    namespaced: true,
    state: initialState,
    actions: {
        getProductcategories({ commit } )
        {
            commit('getProductcategoriesRequest' );
            return new Promise((resolve, reject) => {

                productcategoryService.getProductcategories().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getProductcategoriesSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getProductcategoriesFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteProductcategory({ commit }, id)
        {
            commit('deleteProductcategoryRequest' );
            return new Promise((resolve, reject) => {
                
                productcategoryService.deleteProductcategory(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteProductcategorySuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteProductcategoryFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteProductcategoryMember({ commit }, id)
        {
            commit('deleteProductcategoryMemberRequest' );
            return new Promise((resolve, reject) => {
                
                productcategoryService.deleteProductcategoryMember(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteProductcategoryMemberSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteProductcategoryMemberFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },        
        saveProductcategory({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertProductcategoryRequest' );
                    productcategoryService.insertProductcategory(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertProductcategorySuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertProductcategoryFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateProductcategoryRequest' );
                    productcategoryService.updateProductcategory(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateProductcategorySuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateProductcategoryFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },

        // eslint-disable-next-line no-unused-vars
        saveMembersForProductcategory({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                productcategoryService.insertProductcategoryMembers(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        saveProductcategoryMember({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertProductcategoryMemberRequest' );
                    productcategoryService.insertProductcategoryMember(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertProductcategoryMemberSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertProductcategoryMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateProductcategoryMemberRequest' );
                    productcategoryService.updateProductcategoryMember(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateProductcategoryMemberSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateProductcategoryMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        }
    },
    mutations: {
        getProductcategoriesRequest(state)
        {
            state.productcategories = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getProductcategoriesSuccess(state, data)
        {
            state.productcategories = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getProductcategoriesFailure(state)
        {
            state.productcategories = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteProductcategoryRequest(state)
        {
            state.productcategories.status = { loading: true,ready: false };
        },
        deleteProductcategorySuccess(state)
        {
            state.productcategories.status = { loading: false,ready: true };
        },
        deleteProductcategoryFailure(state)
        {
            state.productcategories.status = { loading: false,ready: false };
        },
        updateProductcategoryRequest(state)
        {
            state.productcategories.status ={ loading: true,ready: false };
        },
        updateProductcategorySuccess(state)
        {
            state.productcategories.status ={ loading: false,ready: true };
        },
        updateProductcategoryFailure(state)
        {
            state.productcategories.status ={ loading: false,ready: false };
        },
        insertProductcategoryRequest(state)
        {
            state.productcategories.status ={ loading: true,ready: false };
        },
        insertProductcategorySuccess(state)
        {
            state.productcategories.status ={ loading: false,ready: true };
        },
        insertProductcategoryFailure(state)
        {
            state.productcategories.status ={ loading: false,ready: false };
        },
        deleteProductcategoryMemberRequest(state)
        {
            state.productcategories.status = { loading: true,ready: false };
        },
        deleteProductcategoryMemberSuccess(state,id)
        {
            state.productcategories.status = { loading: false,ready: true };

            for(var i=0; i < state.productcategories.data.length;i++)
            {
                for(var j=0; j < state.productcategories.data[i].member.length; j++)
                {
                    if(state.productcategories.data[i].member[j].id == id)
                    {
                        state.productcategories.data[i].member[j].list.splice(j, 1); 
                        break;
                    }
                }
            }
        },
        deleteProductcategoryMemberFailure(state)
        {
            state.productcategories.status = { loading: false,ready: false };
        },
        updateProductcategoryMemberRequest(state)
        {
            state.productcategories.status = { loading: true,ready: false };
        },
        updateProductcategoryMemberSuccess(state,data)
        {
            state.productcategories.status = { loading: false,ready: true };

            for(var i=0; i < state.productcategories.data.length;i++)
            {
                for(var j=0; j < state.productcategories.data[i].member.length; j++)
                {
                    if(state.productcategories.data[i].member[j].id == data.id)
                    {
                        state.productcategories.data[i].member[j].orderNr = data.orderNr;
                        break;
                    }
                }
            }
        },
        updateProductcategoryMemberFailure(state)
        {
            state.productcategories.status = { loading: false,ready: false };
        },
        insertProductcategoryMemberRequest(state)
        {
            state.productcategories.status = { loading: true,ready: false };
        },
        insertProductcategoryMemberSuccess(state,data)
        {
            state.productcategories.status = { loading: false,ready: true };

            for(var i=0; i < state.productcategories.data.length;i++)
            {
                if(state.productcategories.data[i].id == data.productcategory_fk)
                {
                    state.productcategories.data[i].member.push(data);
                    break;
                }
            }
        },
        insertProductcategoryMemberFailure(state)
        {
            state.productcategories.status = { loading: false,ready: false };
        }
    }
}
