const config = {
    // Siehe .env Files im Projekt Ordner
    apiBaseUrl: parse(process.env.VUE_APP_API_BASE_URL, 'not_set'),
    hubBaseUrl: parse(process.env.VUE_APP_HUB_BASE_URL, 'not_set'),
    testActive: parse(process.env.VUE_APP_TEST_ENV, false),
    signalRDisabled: parse(process.env.VUE_APP_SIGNALR_OFF, false),
    version: parse(process.env.VUE_APP_VERSION, 'v0.0.0'),
    features: {
        example: parse(process.env.VUE_APP_FEATURE_EXAMPLE, false),
      }
  }
  
  export {
    config
  }

  export default {
    install (Vue) {
      Vue.appConfig = config
      Vue.prototype.$appConfig = config
      Vue.prototype.$feature = feature
    }
  }

  function feature (name) {
    return config.features[name]
  }

  function parse (value, fallback) {
    if (typeof value === 'undefined') {
      return fallback
    }  
    switch (typeof fallback) 
    {
        case 'boolean' :
            return !!JSON.parse(value)
        case 'number' :
            return JSON.parse(value)    
        default :
            return value
    }
  }