import {baseAxios} from '../helper/axiosHelper';

export const debitorobjectService = {
    getDebitorObjects,
    getDebitorObjectsMin,
    insertDebitorObject,
    deleteDebitorObject,
    updateDebitorObject,
    deleteLieferAdr,
    getDebitorObject,
    insertDebitorObjectRoom,
    updateDebitorObjectRoom,
    deleteDebitorObjectRoom,
    getDebitorObjectRooms,
    getDebitorObjectAttachments,
    updateDebitorObjectAttachment,
    deleteDebitorObjectAttachment,
    downloadPdfAttachment
};

function getDebitorObjects(id) {

    return baseAxios.get('debitorobjects/all/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getDebitorObject(id) {

    return baseAxios.get('debitorobjects/object/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getDebitorObjectsMin(id) {

    return baseAxios.get('debitorobjects/all/min/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertDebitorObject(data) {

    return baseAxios.post('debitorobjects/object/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateDebitorObject(data) {

    return baseAxios.put('debitorobjects/object/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteDebitorObject(id) {

    return baseAxios.delete('debitorobjects/object/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteLieferAdr(id) {

    return baseAxios.delete('debitorobjects/liefAdr/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertDebitorObjectRoom(data) {

    return baseAxios.post('debitorobjects/room/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateDebitorObjectRoom(data) {

    return baseAxios.put('debitorobjects/room/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteDebitorObjectRoom(id) {

    return baseAxios.delete('debitorobjects/room/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getDebitorObjectRooms(id) {

    return baseAxios.get('debitorobjects/rooms/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getDebitorObjectAttachments(id) {

    return baseAxios.get('debitorobjects/object/attachments/',{params:{object_fk:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateDebitorObjectAttachment(data) {

    return baseAxios.put('debitorobjects/object/attachment/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteDebitorObjectAttachment(id) {

    return baseAxios.delete('debitorobjects/object/attachment/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function downloadPdfAttachment(id) {

    // `responseType` indicates the type of data that the server will respond with
    // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
    //   browser only: 'blob'
    //responseType: 'json', // default
    
        return baseAxios.get('debitorobjects/object/attachment/pdf/',{params:{id:id},responseType: 'text'})
        .then((response) =>
        {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    }

