
const initialState = { 
                        userAdmin: false,
                        companyAdmin:false
                     };
// hier wird gemerkt ob der benutzer daten eingetragen hat, aber noch nicht gespeichert wurde... 
// Dann wird der Dlg aufgeschalten "Sie haben ungespeicherte Daten, woll Sie wirklich die Seite verlassen?"
export const unsavedData = {
    namespaced: true,
    state: initialState,
    actions: {
        setUserAdmin({ commit },data )
        {
            commit('setUserAdmin',data );
        },
        setCompanyAdmin({ commit },data )
        {
            commit('setCompanyAdmin',data );
        }
        
    },
    mutations: {
        setUserAdmin(state,data)
        {
            state.userAdmin = data;  
        },
        setCompanyAdmin(state,data)
        {
            state.companyAdmin = data;  
        }
    }
}
