import {baseAxios} from '../helper/axiosHelper';

export const countryService = {
    getCountries,
    updateCountry,
    insertCountry,
    deleteCountry,
    getMwStForCountry,
    updateMwst,
    insertMwst,
    deleteMwst,
    getMwstAll
};

function getCountries() {

    return baseAxios.get('countries/Countries/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getMwStForCountry(country_fk) {

    return baseAxios.get('countries/Mwst',{params:{country_fk:country_fk}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getMwstAll() {

    return baseAxios.get('countries/Mwst/all/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateCountry(data) {

    return baseAxios.put('countries/Country',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateMwst(data) {

    return baseAxios.put('countries/Mwst',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertCountry(data) {

    return baseAxios.post('countries/Country',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertMwst(data) {

    return baseAxios.post('countries/Mwst',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteCountry(id) {

    return baseAxios.delete('countries/Country',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteMwst(id) {

    return baseAxios.delete('countries/Mwst',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}