import {baseAxios} from '../helper/axiosHelper';

export const timerecService = {
    insertTimeRec,
    getTimeRecs,
    updateTimeRec,
    deleteTimeRec,
    getTimeModels,
    insertTimeModel,
    updateTimeModel,
    deleteTimeModel,
    insertTimeRecStd,
    getTimeRecsStd,
    updateTimeRecStd,
    deleteTimeRecStd,
    insertTimeRecsFromStd,
    addPause,
    getAllMyHoursByYear
};

function insertTimeRec(data) {

    return baseAxios.post('TimeRec/TimeRec',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateTimeRec(data) {

    return baseAxios.put('TimeRec/TimeRec',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function addPause(data) {

    return baseAxios.put('TimeRec/TimeRec/pause',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteTimeRec(id) {

    return baseAxios.delete('TimeRec/TimeRec',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getAllMyHoursByYear() {

    return baseAxios.get('TimeRec/TimeRec/my/hours')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getTimeRecs(year) {

    return baseAxios.get('TimeRec/TimeRec/my',{params: {year: year}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getTimeModels() {

    return baseAxios.get('TimeRec/TimeModels')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertTimeModel(data) {

    return baseAxios.post('TimeRec/TimeModel',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateTimeModel(data) {

    return baseAxios.put('TimeRec/TimeModel',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteTimeModel(id) {

    return baseAxios.delete('TimeRec/TimeModel',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertTimeRecStd(data) {

    return baseAxios.post('TimeRec/TimeRecStd',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateTimeRecStd(data) {

    return baseAxios.put('TimeRec/TimeRecStd',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteTimeRecStd(id) {

    return baseAxios.delete('TimeRec/TimeRecStd',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getTimeRecsStd(guid) {

    return baseAxios.get('TimeRec/TimeRecStd/my',{params: {userguid: guid}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertTimeRecsFromStd(data) {

    return baseAxios.post('TimeRec/TimeRec/std',{text:data})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
