
import { debitorService } from '../services';
import baseMethods from './base';

const initialState = {  debitors: {data: null,status:{ loading: false,ready: false }},
                        debdropdown: {data: null,status:{ loading: false,ready: false }},
                        singleDebitor: {data: null,status:{ loading: false,ready: false }}
                     };

export const debitor = {
    namespaced: true,
    state: initialState,
    actions: {
        getMyDebitors({ commit } )
        {
            commit('getMyDebitorsRequest' );
            return new Promise((resolve, reject) => {

                debitorService.getMyDebitors().then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getMyDebitorsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getMyDebitorsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getDebitor({ commit }, id)
        {
            commit('getDebitorRequest' );
            return new Promise((resolve, reject) => {

                debitorService.getDebitor(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDebitorSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDebitorFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        getDebitorByOrder({ commit }, id)
        {
            commit('getDebitorRequest' );
            return new Promise((resolve, reject) => {

                debitorService.getDebitorByOrder(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDebitorSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDebitorFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        // eslint-disable-next-line no-unused-vars
        getDebitorForDocument({ commit }, data)
        {
            return new Promise((resolve, reject) => {

                debitorService.getDebitorForDocument(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },      
        getMyDebitorsMin({ commit,dispatch } )
        {
            commit('getMyDebitorsMinRequest' );
            return new Promise((resolve, reject) => {

                debitorService.getMyDebitorsMin().then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getMyDebitorsMinSuccess',response.data);
                            dispatch('maincontainer/setLoadingStatus', {type:"debitor",loaded:true},{root: true},{root: true}); 
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getMyDebitorsMinFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveDebitor({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.debitor.id <= 0)
                {
                    commit('insertDebitorRequest' );
                    debitorService.insertDebitor(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertDebitorSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertDebitorFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateDebitorRequest' );
                    debitorService.updateDebitor(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateDebitorSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateDebitorFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        deleteDebitor({ commit },id )
        {
            commit('deleteDebitorRequest' );
            return new Promise((resolve, reject) => {

                debitorService.deleteDebitor(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteDebitorSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteDebitorFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        anonymDebitor({ commit },id )
        {
            commit('anonymDebitorRequest' );
            return new Promise((resolve, reject) => {

                debitorService.anonymDebitor(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('anonymDebitorSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('anonymDebitorFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        
        deleteLieferAdr({ commit },id ){
            commit('deleteLieferAdrRequest' );
            return new Promise((resolve, reject) => {

                debitorService.deleteLieferAdr(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteLieferAdrSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteLieferAdrFailure');
                            reject(response.statusText);
                        }
                    }
                );

            }) 
        },
        // eslint-disable-next-line no-unused-vars
        getDebitorAddr1({ commit },id ){

            return new Promise((resolve, reject) => {

                debitorService.getDebitorAddr1(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        importDebitorsFromCsv({ commit }, data)
        {
            return new Promise((resolve, reject) => {

                debitorService.importDebitorsFromCsv(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
    },
    mutations: {
        getMyDebitorsRequest(state)
        {
            state.debitors = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getMyDebitorsSuccess(state, data)
        {
            state.debitors = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getMyDebitorsFailure(state)
        {
            state.debitors = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getMyDebitorsMinRequest(state)
        {
            state.debdropdown = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getMyDebitorsMinSuccess(state, data)
        {
            state.debdropdown = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getMyDebitorsMinFailure(state)
        {
            state.debdropdown = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getDebitorRequest(state)
        {
            state.singleDebitor = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDebitorSuccess(state, data)
        {
            state.singleDebitor = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDebitorFailure(state)
        {
            state.singleDebitor = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        insertDebitorRequest(state)
        {
            state.debitors.status = { loading: true,ready: false };
        },
        insertDebitorSuccess(state)
        {
            state.debitors.status = { loading: false,ready: true };
        },
        insertDebitorFailure(state)
        {
            state.debitors.status = { loading: false,ready: false };
        },
        updateDebitorRequest(state)
        {
            state.debitors.status = { loading: true,ready: false };
        },
        updateDebitorSuccess(state, data)
        {
            for(var i = 0; i < state.debitors.data.length; i++)
            {
                if(state.debitors.data.id == data.id)
                {
                    state.debitors.data[i] = data;
                    break;
                }
            }
            state.debitors.status = { loading: false,ready: true };
        },
        updateDebitorFailure(state)
        {
            state.debitors.status = { loading: false,ready: false };
        },
        deleteDebitorRequest(state)
        {
            state.debitors.status = { loading: true,ready: false };
        },
        deleteDebitorSuccess(state)
        {
            state.debitors.status = { loading: false,ready: true };
        },
        deleteDebitorFailure(state)
        {
            state.debitors.status = { loading: false,ready: false };
        },
        anonymDebitorRequest(state)
        {
            state.debitors.status = { loading: true,ready: false };
        },
        anonymDebitorSuccess(state)
        {
            state.debitors.status = { loading: false,ready: true };
        },
        anonymDebitorFailure(state)
        {
            state.debitors.status = { loading: false,ready: false };
        },
        deleteLieferAdrRequest(state)
        {
            state.debitors.status = { loading: true,ready: false };
        },
        deleteLieferAdrSuccess(state)
        {
            state.debitors.status = { loading: false,ready: true };
        },
        deleteLieferAdrFailure(state)
        {
            state.debitors.status = { loading: false,ready: false };
        }
    }
}
