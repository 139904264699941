
import { dashboarddataService } from '../services';
import baseMethods from './base';

const initialState = {  lastChangedOffers: {data: null,status:{ loading: false,ready: false }},
                        lastChangedPartnerOrder: {data: null,status:{ loading: false,ready: false }},
                        lastChangedOrders: {data: null,status:{ loading: false,ready: false }},
                        lastChangedContacts: {data: null,status:{ loading: false,ready: false }},
                        twoYearIncome: {data: null,status:{ loading: false,ready: false }}
                    };

export const dashboarddata = {
    namespaced: true,
    state: initialState,
    actions: {
        getLastChangedOffers({ commit } )
        {
            commit('getLastChangedOffersRequest' );
            return new Promise((resolve, reject) => {

                dashboarddataService.getLastChangedOffers().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getLastChangedOffersSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getLastChangedOffersFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getLastChangedPartnerOrder({ commit } )
        {
            commit('getLastChangedPartnerOrderRequest' );
            return new Promise((resolve, reject) => {

                dashboarddataService.getLastChangedPartnerOrder().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getLastChangedPartnerOrderSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getLastChangedPartnerOrderFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        
        getLastChangedOrders({ commit } )
        {
            commit('getLastChangedOrdersRequest' );
            return new Promise((resolve, reject) => {

                dashboarddataService.getLastChangedOrders().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getLastChangedOrdersSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getLastChangedOrdersFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getLastChangedContacts({ commit } )
        {
            commit('getLastChangedContactsRequest' );
            return new Promise((resolve, reject) => {

                dashboarddataService.getLastChangedContacts().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getLastChangedContactsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getLastChangedContactsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getChartData2YearIncome({ commit } )
        {
            commit('getChartData2YearIncomeRequest' );
            return new Promise((resolve, reject) => {

                dashboarddataService.getChartData2YearIncome().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getChartData2YearIncomeSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getChartData2YearIncomeFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
    },
    mutations: {
        getLastChangedOffersRequest(state)
        {
            state.lastChangedOffers = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getLastChangedOffersSuccess(state, data)
        {
            state.lastChangedOffers = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getLastChangedOffersFailure(state)
        {
            state.lastChangedOffers = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },  
        getLastChangedPartnerOrderRequest(state)
        {
            state.lastChangedPartnerOrder = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getLastChangedPartnerOrderSuccess(state, data)
        {
            state.lastChangedPartnerOrder = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getLastChangedPartnerOrderFailure(state)
        {
            state.lastChangedPartnerOrder = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },


        
        getLastChangedContactsRequest(state)
        {
            state.lastChangedContacts = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getLastChangedContactsSuccess(state, data)
        {
            state.lastChangedContacts = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getLastChangedContactsFailure(state)
        {
            state.lastChangedContacts = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },  
        getLastChangedOrdersRequest(state)
        {
            state.lastChangedOrders = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getLastChangedOrdersSuccess(state, data)
        {
            state.lastChangedOrders = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getLastChangedOrdersFailure(state)
        {
            state.lastChangedOrders = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getChartData2YearIncomeRequest(state)
        {
            state.twoYearIncome = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getChartData2YearIncomeSuccess(state, data)
        {
            state.twoYearIncome = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getChartData2YearIncomeFailure(state)
        {
            state.twoYearIncome = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
    }
}
