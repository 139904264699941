import {baseAxios} from '../helper/axiosHelper';

export const specialdiscountService = {
    insertSpecialDiscount,
    getSpecialDiscounts,
    updateSpecialDiscount,
    deleteSpecialDiscount
};

function insertSpecialDiscount(data) {

    return baseAxios.post('specialdiscount/specialdiscount',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateSpecialDiscount(data) {

    return baseAxios.put('specialdiscount/specialdiscount',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteSpecialDiscount(id) {

    return baseAxios.delete('specialdiscount/specialdiscount',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getSpecialDiscounts() {

    return baseAxios.get('specialdiscount/specialdiscount/all')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
