export default {
    IsSuccess(response)
    {
        var success = false;
        if(typeof(response.status) != 'undefined')
        {
            if(response.status == 200)
            {
                success = true;
            }
        }
        return success;
    }
}