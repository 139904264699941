import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export const allLanguageTexts = {
    de: {
        "test": 'hello world'
    }
  }

export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: allLanguageTexts,
  silentTranslationWarn: true
});

export default i18n;