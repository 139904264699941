import {baseAxios} from '../helper/axiosHelper';

export const companyService = {
    getCompanyList,
    updateCompany,
    insertCompany,
    deleteCompany,
    getCompany,
    getCompanyMember,
    updateCompanyMember,
    deleteCompanyMember,
    getCompaniesOrderPossible
};

function getCompanyList() {

    return baseAxios.get('company/company/all' )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getCompaniesOrderPossible() {

    return baseAxios.get('company/company/orderpossible' )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getCompanyMember(id) {

    return baseAxios.get('company/member',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateCompanyMember(data) {

    return baseAxios.put('company/member',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteCompanyMember(data) {

    return baseAxios.delete('company/member',{params: {guid: data.guid,company_fk:data.company_fk}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getCompany(id) {

    return baseAxios.get('company/company',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateCompany(data) {

    return baseAxios.put('company/company',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertCompany(data) {

    return baseAxios.post('company/company',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteCompany(id) {

    return baseAxios.delete('company/company',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}