
import { productgroupService } from '../services';
import baseMethods from './base';

const initialState = {  productgroups: {data: null,status:{ loading: false,ready: false }}};

export const productgroup = {
    namespaced: true,
    state: initialState,
    actions: {
        getProductgroups({ commit } )
        {
            commit('getProductgroupsRequest' );
            return new Promise((resolve, reject) => {

                productgroupService.getProductgroups().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getProductgroupsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getProductgroupsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteProductgroup({ commit }, id)
        {
            commit('deleteProductgroupRequest' );
            return new Promise((resolve, reject) => {
                
                productgroupService.deleteProductgroup(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteProductgroupSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteProductgroupFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveProductgroup({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertProductgroupRequest' );
                    productgroupService.insertProductgroup(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertProductgroupSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertProductgroupFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateProductgroupRequest' );
                    productgroupService.updateProductgroup(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateProductgroupSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateProductgroupFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        }
    },
    mutations: {
        getProductgroupsRequest(state)
        {
            state.productgroups = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getProductgroupsSuccess(state, data)
        {
            state.productgroups = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getProductgroupsFailure(state)
        {
            state.productgroups = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteProductgroupRequest(state)
        {
            state.productgroups.status = { loading: true,ready: false };
        },
        deleteProductgroupSuccess(state)
        {
            state.productgroups.status = { loading: false,ready: true };
        },
        deleteProductgroupFailure(state)
        {
            state.productgroups.status = { loading: false,ready: false };
        },
        updateProductgroupRequest(state)
        {
            state.productgroups.status ={ loading: true,ready: false };
        },
        updateProductgroupSuccess(state)
        {
            state.productgroups.status ={ loading: false,ready: true };
        },
        updateProductgroupFailure(state)
        {
            state.productgroups.status ={ loading: false,ready: false };
        },
        insertProductgroupRequest(state)
        {
            state.productgroups.status ={ loading: true,ready: false };
        },
        insertProductgroupSuccess(state)
        {
            state.productgroups.status ={ loading: false,ready: true };
        },
        insertProductgroupFailure(state)
        {
            state.productgroups.status ={ loading: false,ready: false };
        }
    }
}
