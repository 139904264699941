<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import LocalStorageService from './services/localstorage.service';

const localStorageService = LocalStorageService.getService();

export default {
  name: 'app',
  components: {
  },
  data: function (){
      return {
      pollingintervall:5000
      };
  },
  computed:{
    numberOfLoadedMessages(){
      var retVal = 0;
      if(this.$store.state.messages.data != null)
      {
        retVal = this.$store.state.messages.data.length;
      }
      return retVal;
    }
  },
  watch:{
    numberOfLoadedMessages(value_new,value_old){
      if(value_new > value_old)
      {
        // Nachrichten sind mehr geworden
        this.$store.dispatch('alertqueue/addInfo', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TRANSLATIONS.SUCCESS.UPDATE_TRANSLATIONS')});
      }
    }
  },
  methods: {

    setPollingIntervall(intervall_min, intervall_sec)
    {
      this.pollingintervall = intervall_min * 1000 * 60 + intervall_sec * 1000;

      if(this.pollingintervall == 0 && this.$appConfig.signalRDisabled)
      {
        this.$store.dispatch('alertqueue/addInfo', {'titel':"POLLING",'text':"Polling wurde mit 30 Sekunden aktiviert."});

        this.pollingintervall = 30000;
        
        this.$store.dispatch('mainsettings/getMainSettings', { root: true },{ root: true })
        .then(response => {

          if(response != null)
          {
            var mainsettings = response[0];
            mainsettings.polling_intervall_min = 0;
            mainsettings.polling_intervall_sec = 30;

            this.$store.dispatch('mainsettings/updateMainSettings',  mainsettings);
          }
        
        });

        
      }
    },
    getPollingIntervall(){

      if(this.$store.state.mainsettings.mainsettings.data != null)
      {
        var settings = this.$store.state.mainsettings.mainsettings.data[0];
        this.setPollingIntervall(settings.polling_intervall_min, settings.polling_intervall_sec);
      }
    },
    checkIntervall(){
        this.getPollingIntervall();
        this.$store.dispatch('polling/getPublicBanners', { root: true },{ root: true }); 
        const loggedIn = localStorageService.getAuthToken();
        if(loggedIn)
        {
          // Aktionen pollen für die man eingeloggt sein muss
          this.$store.dispatch('polling/getPrivateBanners', { root: true },{ root: true });
          // Nachrichten abfragen

          if(this.$store.state.messages.lastUpdate == null)
          {
            // Alle abfragen
            this.$store.dispatch('messages/getMessages', { root: true },{ root: true }); 
          }
          else
          {
            // Nur die ungelesenen seit der letzten Abfrage
            this.$store.dispatch('messages/getUnreadMessages', this.$store.state.messages.lastUpdate ); 
          }
        }
        
        if(this.pollingintervall > 0)
        {
          this.$store.dispatch('alertqueue/addInfo', {'titel':"POLLING",'text':"Intervall: "+this.pollingintervall});
          setTimeout(this.checkIntervall,this.pollingintervall);
        }

    }
  },
  mounted(){
    // polling
    this.$store.dispatch('mainsettings/getMainSettings', { root: true },{ root: true }).then(resp =>{
      this.setPollingIntervall(resp[0].polling_intervall_min, resp[0].polling_intervall_sec);
      this.checkIntervall();
    })
    
  }
}
</script>