import Vue from 'vue'
import Vuesax from 'vuesax'
import App from './App.vue'

import configPlugin from './config';
Vue.use(configPlugin);

import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';

import { abilitiesPlugin } from '@casl/vue';// Zugriffsberechtigungen
import {ability} from './helper/abilityHelper';

import baseAxios from './helper/axiosHelper';
Vue.prototype.$http = baseAxios;

import { Can } from '@casl/vue';
Vue.component('Can', Can);

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import {i18n} from './helper/i18nHelper';

// Vuex Store
import {store} from './store/';

// SignalR
import SharedHub from './hub/sharedHub';
Vue.use(SharedHub);

// Theme Configurations
import 'prismjs'
import 'prismjs/themes/prism.css'
import VsPrism from './components/prism/VsPrism.vue';
Vue.component(VsPrism.name, VsPrism);

// Vue Router
import {router} from './router'

// DateTime Picker
import { Datetime } from 'vue-datetime';
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

Vue.component('datetime', Datetime);


Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: '#be1f37',
    }
  }
});

Vue.use(abilitiesPlugin,ability);

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
import '@/assets/scss/style.scss'