
import { documentService } from '../services';
import baseMethods from './base';

const initialState = {  documents: {data: null,status:{ loading: false,ready: false }},
                        documentqueue: {data: null,status:{ loading: false,ready: false }}};

export const document = {
    namespaced: true,
    state: initialState,
    actions: {
        getDocuments({ commit } )
        {
            commit('getDocumentsRequest' );
            return new Promise((resolve, reject) => {

                documentService.getDocuments().then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDocumentsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDocumentsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getDocumentQueue({ commit } )
        {
            commit('getDocumentQueueRequest' );
            return new Promise((resolve, reject) => {

                documentService.getDocumentQueue().then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDocumentQueueSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDocumentQueueFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        updateDocumentQueueEntry({ commit },data )
        {
            commit('updateDocumentQueueEntryRequest' );
            return new Promise((resolve, reject) => {

                documentService.updateDocumentQueueEntry(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('updateDocumentQueueEntrySuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('updateDocumentQueueEntryFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        getDocumentStatus({ commit },data )
        {
            return new Promise((resolve, reject) => {

                documentService.getDocumentStatus(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteDocument({ commit },id )
        {
            commit('deleteDocumentRequest' );
            return new Promise((resolve, reject) => {

                documentService.deleteDocument(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteDocumentSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteDocumentFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteDocumentQueueEntry({ commit },id )
        {
            commit('deleteDocumentQueueEntryRequest' );
            return new Promise((resolve, reject) => {

                documentService.deleteDocumentQueueEntry(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteDocumentQueueEntrySuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteDocumentQueueEntryFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        downloadDocument({ commit },id )
        {
            return new Promise((resolve, reject) => {

                documentService.downloadDocument(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        downloadDocumentByLinkedFk({ commit }, data)
        {
            return new Promise((resolve, reject) => {

                documentService.downloadDocumentByLinkedFk(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },        
        // eslint-disable-next-line no-unused-vars
        createDocument({ commit },data)
        {
            return new Promise((resolve, reject) => {

                documentService.createDocument(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        }         
    },
    mutations: {
        getDocumentsRequest(state)
        {
            state.documents = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDocumentsSuccess(state, data)
        {
            state.documents = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDocumentsFailure(state)
        {
            state.documents = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteDocumentRequest(state)
        {
            state.documents.status = { loading: true,ready: false };
        },
        deleteDocumentSuccess(state)
        {
            state.documents.status = { loading: false,ready: true };
        },
        deleteDocumentFailure(state)
        {
            state.documents.status = { loading: false,ready: false };
        },
        getDocumentQueueRequest(state)
        {
            state.documentqueue = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDocumentQueueSuccess(state, data)
        {
            state.documentqueue = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDocumentQueueFailure(state)
        {
            state.documentqueue = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        updateDocumentQueueEntryRequest(state)
        {
            state.documentqueue.status = { loading: true,ready: false };
        },
        updateDocumentQueueEntrySuccess(state,data)
        {
            state.documentqueue.status = { loading: false,ready: true };
            for(var i=0; i < state.documentqueue.data.length; i++)
            {
                if(state.documentqueue.data[i].id == data.id)
                {
                    state.documentqueue.data[i].status = data.status;
                    break;
                }
            }
        },
        updateDocumentQueueEntryFailure(state)
        {
            state.documentqueue.status = { loading: false,ready: false };
        },
        deleteDocumentQueueEntryRequest(state)
        {
            state.documentqueue.status = { loading: true,ready: false };
        },
        deleteDocumentQueueEntrySuccess(state,id)
        {
            state.documentqueue.status = { loading: false,ready: true };
            for(var i=0; i < state.documentqueue.data.length; i++)
            {
                if(state.documentqueue.data[i].id == id)
                {
                    state.documentqueue.data.splice(i,1);
                    break;
                }
            }
        },
        deleteDocumentQueueEntryFailure(state)
        {
            state.documentqueue.status = { loading: false,ready: false };
        },
    }
}
