
import { deliveryconditionService } from '../services';
import baseMethods from './base';

const initialState = {  deliveryconditions: {data: null,status:{ loading: false,ready: false }}};

export const deliverycondition = {
    namespaced: true,
    state: initialState,
    actions: {
        getDeliveryConditions({ commit } )
        {
            commit('getDeliveryConditionsRequest' );
            return new Promise((resolve, reject) => {

                deliveryconditionService.getDeliveryConditions().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getDeliveryConditionsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getDeliveryConditionsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteDeliveryCondition({ commit }, id)
        {
            commit('deleteDeliveryConditionRequest' );
            return new Promise((resolve, reject) => {
                
                deliveryconditionService.deleteDeliveryCondition(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteDeliveryConditionSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteDeliveryConditionFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },       
        saveDeliveryCondition({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertDeliveryConditionRequest' );
                    deliveryconditionService.insertDeliveryCondition(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertDeliveryConditionSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertDeliveryConditionFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateDeliveryConditionRequest' );
                    deliveryconditionService.updateDeliveryCondition(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateDeliveryConditionSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateDeliveryConditionFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
    },
    mutations: {
        getDeliveryConditionsRequest(state)
        {
            state.deliveryconditions = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getDeliveryConditionsSuccess(state, data)
        {
            state.deliveryconditions = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getDeliveryConditionsFailure(state)
        {
            state.deliveryconditions = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteDeliveryConditionRequest(state)
        {
            state.deliveryconditions.status = { loading: true,ready: false };
        },
        deleteDeliveryConditionSuccess(state,id)
        {
            state.deliveryconditions.status = { loading: false,ready: true };
            for(var i =0; i < state.deliveryconditions.data.length;i++)
            {
                if(state.deliveryconditions.data[i].id == id)
                {
                    state.deliveryconditions.data.splice(i,1);
                }
            }

        },
        deleteDeliveryConditionFailure(state)
        {
            state.deliveryconditions.status = { loading: false,ready: false };
        },
        insertDeliveryConditionRequest(state)
        {
            state.deliveryconditions.status = { loading: true,ready: false };
        },
        insertDeliveryConditionSuccess(state)
        {
            state.deliveryconditions.status = { loading: false,ready: true };
        },
        insertDeliveryConditionFailure(state)
        {
            state.deliveryconditions.status = { loading: false,ready: false };
        },
        updateDeliveryConditionRequest(state)
        {
            state.deliveryconditions.status = { loading: true,ready: false };
        },
        updateDeliveryConditionSuccess(state,data)
        {
            state.deliveryconditions.status = { loading: false,ready: true };
            for(var i =0; i < state.deliveryconditions.data.length;i++)
            {
                if(state.deliveryconditions.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.deliveryconditions.data[i] = JSON.parse(tmp);
                }
            }
        },
        updateDeliveryConditionFailure(state)
        {
            state.deliveryconditions.status = { loading: false,ready: false };
        },        

    }
}
