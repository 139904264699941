import {baseAxios} from '../helper/axiosHelper';

export const groupService = {
    getGroups,
    newGroup,
    getGroupMember,
    removeGroupMember,
    saveGroup,
    deleteGroup,
    getPossibleMember,
    updateGroupMember
};

function getGroups() {

    return baseAxios.get('group/Groups')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getPossibleMember() {

    return baseAxios.get('group/PossibleMember')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function newGroup(data) {

    return baseAxios.post('group/Group',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteGroup(data) {

    return baseAxios.delete('group/Group',{params:{id:data}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function saveGroup(data) {

    return baseAxios.put('group/Group',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateGroupMember(data) {

    return baseAxios.put('group/GroupMember',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getGroupMember(data){
    
    return baseAxios.get('group/GroupMember',{params:{group_fk:data}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });    
}

function removeGroupMember(data){
    
    return baseAxios.delete('group/GroupMember',{params:{id:data}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });    
}