
import { currenciesService } from '../services';
import baseMethods from './base';

const initialState = {  currencies: {data: null,status:{ loading: false,ready: false }}};

export const currencies = {
    namespaced: true,
    state: initialState,
    actions: {
        getCurrencies({ commit,dispatch } )
        {
            commit('getCurrenciesRequest' );
            return new Promise((resolve, reject) => {

                currenciesService.getCurrencies().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getCurrenciesSuccess',response.data);
                            dispatch('maincontainer/setLoadingStatus', {type:"currency",loaded:true},{root: true},{root: true}); 
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getCurrenciesFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteCurrency({ commit }, id)
        {
            commit('deleteCurrencyRequest' );
            return new Promise((resolve, reject) => {
                
                currenciesService.deleteCurrency(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteCurrencySuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteCurrencyFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveCurrency({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertCurrencyRequest' );
                    currenciesService.insertCurrency(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertCurrencySuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertCurrencyFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateCurrencyRequest' );
                    currenciesService.updateCurrency(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateCurrencySuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateCurrencyFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        }
    },
    mutations: {
        getCurrenciesRequest(state)
        {
            state.currencies = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getCurrenciesSuccess(state, data)
        {
            state.currencies = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getCurrenciesFailure(state)
        {
            state.currencies = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteCurrencyRequest(state)
        {
            state.currencies.status = { loading: true,ready: false };
        },
        deleteCurrencySuccess(state)
        {
            state.currencies.status = { loading: false,ready: true };
        },
        deleteCurrencyFailure(state)
        {
            state.currencies.status = { loading: false,ready: false };
        },
        updateCurrencyRequest(state)
        {
            state.currencies.status ={ loading: true,ready: false };
        },
        updateCurrencySuccess(state)
        {
            state.currencies.status ={ loading: false,ready: true };
        },
        updateCurrencyFailure(state)
        {
            state.currencies.status ={ loading: false,ready: false };
        },
        insertCurrencyRequest(state)
        {
            state.currencies.status ={ loading: true,ready: false };
        },
        insertCurrencySuccess(state)
        {
            state.currencies.status ={ loading: false,ready: true };
        },
        insertCurrencyFailure(state)
        {
            state.currencies.status ={ loading: false,ready: false };
        }
    }
}
