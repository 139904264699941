
import { companygroupService } from '../services';
import baseMethods from './base';

const initialState = { companygroups: {data:[],status:{ loading: false,ready: false }}
                    };

export const companygroup = {
    namespaced: true,
    state: initialState,
    actions: {
        getCompanyGroups({ commit } )
        {
            commit('getCompanyGroupsRequest' );
            return new Promise((resolve, reject) => {
                companygroupService.getCompanyGroups().then(

                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getCompanyGroupsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getCompanyGroupsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        // eslint-disable-next-line no-unused-vars
        getPossibleMember({ commit },group_fk )
        {
            return new Promise((resolve, reject) => {
                companygroupService.getPossibleMember(group_fk).then(

                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },  
        
        deleteCompanyGroup({ commit }, id)
        {
            commit('deleteCompanyGroupRequest' );
            return new Promise((resolve, reject) => {
                
                companygroupService.deleteCompanyGroup(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteCompanyGroupSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteCompanyGroupFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        updateCompanyGroupMember({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                companygroupService.updateCompanyGroupMember(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },   
        saveCompanyGroup({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertCompanyGroupRequest' );
                    companygroupService.insertCompanyGroup(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertCompanyGroupSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertCompanyGroupFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateCompanyGroupRequest' );
                    companygroupService.updateCompanyGroup(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateCompanyGroupSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateCompanyGroupFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        }
    },
    mutations: {
        getCompanyGroupsRequest(state)
        {
            state.companygroups = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getCompanyGroupsSuccess(state, data)
        {
            state.companygroups = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getCompanyGroupsFailure(state)
        {
            state.companygroups = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        insertCompanyGroupRequest(state)
        {
            state.companygroups.status = { loading: true,ready: false };
        },
        insertCompanyGroupSuccess(state)
        {
            state.companygroups.status = { loading: false,ready: true };
        },
        insertCompanyGroupFailure(state)
        {
            state.companygroups.status = { loading: false,ready: false };
        },
        updateCompanyGroupRequest(state)
        {
            state.companygroups.status = { loading: true,ready: false };
        },
        updateCompanyGroupSuccess(state)
        {
            state.companygroups.status = { loading: false,ready: true };
        },
        updateCompanyGroupFailure(state)
        {
            state.companygroups.status = { loading: false,ready: false };
        },
        deleteCompanyGroupRequest(state)
        {
            state.companygroups.status = { loading: true,ready: false };
        },
        deleteCompanyGroupSuccess(state)
        {
            state.companygroups.status = { loading: false,ready: true };
        },
        deleteCompanyGroupFailure(state)
        {
            state.companygroups.status = { loading: false,ready: false };
        }
        
    }
}
