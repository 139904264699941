
import { paymentconditionService } from '../services';
import baseMethods from './base';

const initialState = {  paymentconditions: {data: null,status:{ loading: false,ready: false }}};

export const paymentcondition = {
    namespaced: true,
    state: initialState,
    actions: {
        getPaymentConditions({ commit } )
        {
            commit('getPaymentConditionsRequest' );
            return new Promise((resolve, reject) => {

                paymentconditionService.getPaymentConditions().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getPaymentConditionsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getPaymentConditionsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deletePaymentCondition({ commit }, id)
        {
            commit('deletePaymentConditionRequest' );
            return new Promise((resolve, reject) => {
                
                paymentconditionService.deletePaymentCondition(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deletePaymentConditionSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deletePaymentConditionFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },       
        savePaymentCondition({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertPaymentConditionRequest' );
                    paymentconditionService.insertPaymentCondition(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertPaymentConditionSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertPaymentConditionFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updatePaymentConditionRequest' );
                    paymentconditionService.updatePaymentCondition(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updatePaymentConditionSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updatePaymentConditionFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
    },
    mutations: {
        getPaymentConditionsRequest(state)
        {
            state.paymentconditions = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getPaymentConditionsSuccess(state, data)
        {
            state.paymentconditions = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getPaymentConditionsFailure(state)
        {
            state.paymentconditions = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deletePaymentConditionRequest(state)
        {
            state.paymentconditions.status = { loading: true,ready: false };
        },
        deletePaymentConditionSuccess(state,id)
        {
            state.paymentconditions.status = { loading: false,ready: true };
            for(var i =0; i < state.paymentconditions.data.length;i++)
            {
                if(state.paymentconditions.data[i].id == id)
                {
                    state.paymentconditions.data.splice(i,1);
                }
            }

        },
        deletePaymentConditionFailure(state)
        {
            state.paymentconditions.status = { loading: false,ready: false };
        },
        insertPaymentConditionRequest(state)
        {
            state.paymentconditions.status = { loading: true,ready: false };
        },
        insertPaymentConditionSuccess(state)
        {
            state.paymentconditions.status = { loading: false,ready: true };
        },
        insertPaymentConditionFailure(state)
        {
            state.paymentconditions.status = { loading: false,ready: false };
        },
        updatePaymentConditionRequest(state)
        {
            state.paymentconditions.status = { loading: true,ready: false };
        },
        updatePaymentConditionSuccess(state,data)
        {
            state.paymentconditions.status = { loading: false,ready: true };
            for(var i =0; i < state.paymentconditions.data.length;i++)
            {
                if(state.paymentconditions.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.paymentconditions.data[i] = JSON.parse(tmp);
                }
            }
        },
        updatePaymentConditionFailure(state)
        {
            state.paymentconditions.status = { loading: false,ready: false };
        },        

    }
}
