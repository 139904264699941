
import { productvariantService } from '../services';
import baseMethods from './base';

const initialState = {  productvariants: {data: null,status:{ loading: false,ready: false }},
                        productvariantsWithHeadquater: {data: null,status:{ loading: false,ready: false }},
                        productvariantgroups: {data: null,status:{ loading: false,ready: false }},
                        productvariantgroupsWithHeadquater: {data: null,status:{ loading: false,ready: false }}
                    };

export const productvariant = {
    namespaced: true,
    state: initialState,
    actions: {
        getProductvariants({ commit } )
        {
            commit('getProductvariantsRequest' );
            return new Promise((resolve, reject) => {

                productvariantService.getProductvariants().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getProductvariantsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getProductvariantsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getProductvariantGroups({ commit } )
        {
            commit('getProductvariantGroupsRequest' );
            return new Promise((resolve, reject) => {

                productvariantService.getProductvariantGroups().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getProductvariantGroupsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getProductvariantGroupsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteProductvariant({ commit }, id)
        {
            commit('deleteProductvariantRequest' );
            return new Promise((resolve, reject) => {
                
                productvariantService.deleteProductvariant(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteProductvariantSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteProductvariantFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteProductvariantGroup({ commit }, id)
        {
            commit('deleteProductvariantGroupRequest' );
            return new Promise((resolve, reject) => {
                
                productvariantService.deleteProductvariantGroup(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteProductvariantGroupSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteProductvariantGroupFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        saveProductvariant({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertProductvariantRequest' );
                    productvariantService.insertProductvariant(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertProductvariantSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertProductvariantFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateProductvariantRequest' );
                    productvariantService.updateProductvariant(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateProductvariantSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateProductvariantFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        saveProductvariantGroup({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertProductvariantGroupRequest' );
                    productvariantService.insertProductvariantGroup(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertProductvariantGroupSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertProductvariantGroupFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateProductvariantRequest' );
                    productvariantService.updateProductvariantGroup(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateProductvariantGroupSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateProductvariantGroupFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        // eslint-disable-next-line no-unused-vars
        saveMembersForProductvariantGroup({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                productvariantService.insertProductvariantGroupMembers(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        
    },
    mutations: {
        getProductvariantsRequest(state)
        {
            state.productvariants = {
                data: null,
                status:{ loading: true,ready: false }
            };

            state.productvariantsWithHeadquater = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getProductvariantsSuccess(state, data)
        {
            state.productvariants = {
                data: data.variants,
                status:{ loading: false,ready: true }
            };

            state.productvariantsWithHeadquater = {
                data: data.variantsAll,
                status:{ loading: false,ready: true }
            };
        },
        getProductvariantsFailure(state)
        {
            state.productvariants = {
                data: null,
                status:{ loading: false,ready: false }
            };

            state.productvariantsWithHeadquater = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getProductvariantGroupsRequest(state)
        {
            state.productvariantgroups = {
                data: null,
                status:{ loading: true,ready: false }
            };

            state.productvariantgroupsWithHeadquater = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getProductvariantGroupsSuccess(state, data)
        {
            state.productvariantgroups = {
                data: data.groups,
                status:{ loading: false,ready: true }
            };

            state.productvariantgroupsWithHeadquater = {
                data: data.groupsAll,
                status:{ loading: false,ready: true }
            };
        },
        getProductvariantGroupsFailure(state)
        {
            state.productvariantgroups = {
                data: null,
                status:{ loading: false,ready: false }
            };

            state.productvariantgroupsWithHeadquater = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteProductvariantRequest(state)
        {
            state.productvariants.status = { loading: true,ready: false };
        },
        deleteProductvariantSuccess(state)
        {
            state.productvariants.status = { loading: false,ready: true };
        },
        deleteProductvariantFailure(state)
        {
            state.productvariants.status = { loading: false,ready: false };
        },
        deleteProductvariantGroupRequest(state)
        {
            state.productvariantgroups.status = { loading: true,ready: false };
        },
        deleteProductvariantGroupSuccess(state)
        {
            state.productvariantgroups.status = { loading: false,ready: true };
        },
        deleteProductvariantGroupFailure(state)
        {
            state.productvariantgroups.status = { loading: false,ready: false };
        },
        updateProductvariantRequest(state)
        {
            state.productvariants.status ={ loading: true,ready: false };
        },
        updateProductvariantSuccess(state)
        {
            state.productvariants.status ={ loading: false,ready: true };
        },
        updateProductvariantFailure(state)
        {
            state.productvariants.status ={ loading: false,ready: false };
        },
        insertProductvariantRequest(state)
        {
            state.productvariants.status ={ loading: true,ready: false };
        },
        insertProductvariantSuccess(state)
        {
            state.productvariants.status ={ loading: false,ready: true };
        },
        insertProductvariantFailure(state)
        {
            state.productvariants.status ={ loading: false,ready: false };
        },
        updateProductvariantGroupRequest(state)
        {
            state.productvariantgroups.status ={ loading: true,ready: false };
        },
        updateProductvariantGroupSuccess(state)
        {
            state.productvariantgroups.status ={ loading: false,ready: true };
        },
        updateProductvariantGroupFailure(state)
        {
            state.productvariantgroups.status ={ loading: false,ready: false };
        },
        insertProductvariantGroupRequest(state)
        {
            state.productvariantgroups.status ={ loading: true,ready: false };
        },
        insertProductvariantGroupSuccess(state)
        {
            state.productvariantgroups.status ={ loading: false,ready: true };
        },
        insertProductvariantGroupFailure(state)
        {
            state.productvariantgroups.status ={ loading: false,ready: false };
        }
        
    }
}
