import {baseAxios} from '../helper/axiosHelper';

export const addressService = {
    getAddrListOfUser,
    getAddressListForCompanyAndUser,
    updateAddr,
    insertAddr,
    deleteAddr
};

function getAddrListOfUser(data) {

    return baseAxios.get('address/Addresses',{params: {id: data.id,type:data.ownertype,ForTable:data.forTable}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getAddressListForCompanyAndUser(id,forTable) {

    return baseAxios.get('address/Addresses/compuser/',{params: {id: id,ForTable:forTable}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateAddr(data) {

    return baseAxios.put('address/Address',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertAddr(data) {

    return baseAxios.post('address/Address',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteAddr(id) {

    return baseAxios.delete('address/Address',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}