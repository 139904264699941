export const alertqueue = {
    namespaced: true,
    state: {
        errors: [],
        warnings: [],
        success: [],
        infos:[],
        showLoader: [],
        hideLoader: []
    },
    actions: {
        addError({ commit }, item) {
            commit('addError', item);
        },
        removeError({commit}){
            commit('removeError');
        },
        addWarning({ commit }, item) {
            commit('addWarning', item);
        },
        removeWarning({commit}){
            commit('removeWarning');
        },
        addSuccess({ commit }, item) {
            commit('addSuccess', item);
        },
        removeSuccess({commit}){
            commit('removeSuccess');
        },
        addInfo({ commit }, item) {
            commit('addInfo', item);
        },
        removeInfo({commit}){
            commit('removeInfo');
        },
        showLoader({commit}, item){

            if(typeof(item.scale) == 'undefined')
            {
                // Wenn scale nicht angegeben -> default Wert setzen
                var tmp = {'id':item.id,'scale':0.6};
                commit('showLoader',tmp);
            }
            else
            {
                var tmp2 = {'id':item.id,'scale':item.scale};
                commit('showLoader',tmp2);
            }
            
        },
        removeLoaderFromShow({commit}){
            commit('removeLoaderFromShow');
        },
        hideLoader({commit}, item){
            commit('hideLoader',item);
        },
        removeLoaderFromHide({commit}){
            commit('removeLoaderFromHide');
        }
    },
    mutations: {
        addError(state, item) {
            var icon = typeof(item.icon)!='undefined' ? item.icon:"error";
            var error = {'titel':item.titel,'text':item.text,'icon':icon};
            state.errors.push(error);
        },
        removeError(state) {
            state.errors.shift();
        },
        addWarning(state, item) {
            var icon = typeof(item.icon)!='undefined' ? item.icon:"warning";
            var warning = {'titel':item.titel,'text':item.text,'icon':icon};
            state.warnings.push(warning);
        },
        removeWarning(state) {
            state.warnings.shift();
        },
        addSuccess(state, item) {
            var icon = typeof(item.icon)!='undefined' ? item.icon:"done";
            var success = {'titel':item.titel,'text':item.text,'icon':icon};
            state.success.push(success);
        },
        removeSuccess(state) {
            state.success.shift();
        },
        addInfo(state, item) {
            var icon = typeof(item.icon)!='undefined' ? item.icon:"priority_high";
            var info = {'titel':item.titel,'text':item.text,'icon':icon};
            state.infos.push(info);
        },
        removeInfo(state) {
            state.infos.shift();
        },        
        showLoader(state, item) {
            var loader = {'id':item.id,'scale':item.scale};
            state.showLoader.push(loader);
        },
        removeLoaderFromShow(state) {
            state.showLoader.shift();
        },
        hideLoader(state, item) {
            var loader = {'id':item.id};
            state.hideLoader.push(loader);
        },
        removeLoaderFromHide(state) {
            state.hideLoader.shift();
        }
    }
}
