
import { bannerService } from '../services';
import baseMethods from './base';

const initialState = { banners: {data: [],status:{ loading: false,ready: false }} };

export const banner = {
    namespaced: true,
    state: initialState,
    actions: {
        getBanner({ commit } )
        {
            commit('getBannerRequest' );
            return new Promise((resolve, reject) => {

                bannerService.getBanner().then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getBannerSucces',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getBannerFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteBanner({ commit },id )
        {
            commit('deleteBannerRequest' );
            return new Promise((resolve, reject) => {

                bannerService.deleteBanner(id).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteBannerSucces',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteBannerFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        updateBanner({ commit },data )
        {
            commit('updateBannerRequest' );
            return new Promise((resolve, reject) => {

                bannerService.updateBanner(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            if(response.data == true)
                            {
                                commit('updateBannerSuccess',data);
                            }
                            else
                            {
                                commit('updateBannerFailure');
                            }
                            resolve(response.data);
                        }
                        else
                        {
                            commit('updateBannerFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        insertBanner({ commit },data )
        {
            commit('insertBannerRequest' );
            return new Promise((resolve, reject) => {

                bannerService.insertBanner(data).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('insertBannerSucces');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('insertBannerFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }
    },
    mutations: {
        getBannerRequest(state)
        {
            state.banners = {
                data: [],
                status:{ loading: true,ready: false }
            };
        },
        getBannerSucces(state, data)
        {
            state.banners = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getBannerFailure(state)
        {
            state.banners = {
                data: [],
                status:{ loading: false,ready: false }
            };
        },
        insertBannerRequest(state)
        {
            state.banners.status ={ loading: true,ready: false };
        },
        insertBannerSucces(state)
        {
            state.banners.status ={ loading: false,ready: true };
        },
        insertBannerFailure(state)
        {
            state.banners.status ={ loading: false,ready: false };
        },                
        updateBannerRequest(state)
        {
            state.banners.status = { loading: true,ready: false };
        },
        updateBannerSuccess(state, data)
        {
            state.banners.status = { loading: false,ready: true };
            for(var i = 0; i < state.banners.data.length; i++)
            {
                if(state.banners.data[i].id==data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.banners.data[i] = JSON.parse(tmp);
                    break;
                }
            }
        },
        updateBannerFailure(state)
        {
            state.banners.status = { loading: false,ready: false };
        },
        deleteBannerRequest(state)
        {
            state.banners = {
                data: state.banners.data,
                status:{ loading: true,ready: false }
            };
        },
        deleteBannerSucces(state, id)
        {
            for(var i=0; i < state.banners.data.length; i++)
            {
                if(state.banners.data[i].id == id)
                {
                    state.banners.data.splice(i,1);
                    break;
                }
            }

            state.banners.status = { loading: false,ready: true };
        },
        deleteBannerFailure(state)
        {
            state.banners.status = { loading: false,ready: true };
        }
    }
}
