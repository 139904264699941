import {baseAxios} from '../helper/axiosHelper';

export const documentService = {
    getDocuments,
    createDocument,
    downloadDocument,
    deleteDocument,
    getDocumentStatus,
    getDocumentQueue,
    updateDocumentQueueEntry,
    deleteDocumentQueueEntry,
    downloadDocumentByLinkedFk
};

function getDocuments() {

    return baseAxios.get('documents/my/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getDocumentQueue() {

    return baseAxios.get('documents/queue/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getDocumentStatus(data) {

    return baseAxios.get('documents/document/status/',{params:{linked_fk:data.linked_fk,type:data.type}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function createDocument(data) {

    return baseAxios.post('documents/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateDocumentQueueEntry(data) {

    return baseAxios.put('documents/queue/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function downloadDocument(id) {

// `responseType` indicates the type of data that the server will respond with
// options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
//   browser only: 'blob'
//responseType: 'json', // default

    return baseAxios.get('documents/pdf/',{params:{id:id},responseType: 'text'})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function downloadDocumentByLinkedFk(data) {

    // `responseType` indicates the type of data that the server will respond with
    // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
    //   browser only: 'blob'
    //responseType: 'json', // default

        return baseAxios.get('documents/pdf/linked/',{params:{linkedFk:data.linkedFk, type:data.type},responseType: 'text'})
        .then((response) =>
        {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    }


function deleteDocument(id) {

    return baseAxios.delete('documents/pdf/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteDocumentQueueEntry(id) {

    return baseAxios.delete('documents/queue/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}